define('desktop-frontend/services/trackers/takeover', ['exports', 'rp-common/services/trackers/takeover'], function (exports, _takeover) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _takeover.default;
    }
  });
});