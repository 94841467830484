define('desktop-frontend/components/header-nav/component', ['exports', 'desktop-frontend/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var alias = Ember.computed.alias;
  exports.default = Component.extend({
    tagName: 'nav',
    classNames: ['header-nav'],
    paths: _environment.default.paths,

    listProfile: alias('currentSession.listProfile')
  });
});