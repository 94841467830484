define('desktop-frontend/components/search-picker/multiple-results/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var get = Ember.get;
  exports.default = Component.extend({
    tagName: 'ul',
    classNames: ['multiple-selects'],
    results: null,
    selectedResults: null,
    placeholder: '',

    actions: {
      deselectItem: function deselectItem(data) {
        get(this, 'selectedResults').removeObject(data);
      }
    }
  });
});