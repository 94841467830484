define('desktop-frontend/components/feed-suggested-groups/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  exports.default = Component.extend({
    classNames: ['feed-suggested-groups'],
    feedItem: null
  });
});