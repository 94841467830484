define('desktop-frontend/services/confetti-machine', ['exports', 'rp-common/services/confetti-machine'], function (exports, _confettiMachine) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _confettiMachine.default;
    }
  });
});