define('desktop-frontend/components/list-feed-status-update/component', ['exports', 'rp-common/utils/group-route-resolver', 'ember-awesome-macros', 'desktop-frontend/mixins/nocklab-validations'], function (exports, _groupRouteResolver, _emberAwesomeMacros, _nocklabValidations) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var service = Ember.inject.service;
  var readOnly = Ember.computed.readOnly;
  var alias = Ember.computed.alias;
  var gt = Ember.computed.gt;
  var Component = Ember.Component;
  var computed = Ember.computed;


  var NUM_MENTIONS = 2;

  exports.default = Component.extend(_nocklabValidations.default, {
    feedItem: null,

    showMoreMentions: false,
    showReason: false,
    reasonProfile: null,
    listFeed: null,

    store: service(),
    pageContext: service(),
    currentSession: service(),

    listStatusUpdate: readOnly('feedItem.list_status_update'),
    listFeedItem: alias('listStatusUpdate'),
    listProfile: alias('listStatusUpdate.list_profile'),
    fullStatusUpdate: readOnly('feedItem.list_status_update.full_status_update'),
    fullQrc: alias('fullStatusUpdate'),
    moreMentions: gt('fullStatusUpdate.mentions.length', NUM_MENTIONS),
    dontShowMoreMentions: (0, _emberAwesomeMacros.not)('showMoreMentions'),
    showOtherMentions: (0, _emberAwesomeMacros.and)('moreMentions', 'dontShowMoreMentions'),
    linkData: readOnly('fullStatusUpdate.link_data'),
    listGroup: readOnly('pageContext.listGroup'),
    notOwnPost: (0, _emberAwesomeMacros.neq)('feedItem.reason', (0, _emberAwesomeMacros.raw)('own_post')),
    useXIconMenu: (0, _emberAwesomeMacros.and)('currentSession.isSignedIn', 'notOwnPost', (0, _emberAwesomeMacros.not)('fullStatusUpdate.isLoading')),

    statusUpdateUrl: computed('pageContext.onGroupPage', 'listGroup.{groupable_type,slug}', 'listStatusUpdate.slug', function () {
      if (this.get('pageContext.onGroupPage') && this.get('listGroup.slug')) {
        return '/' + (0, _groupRouteResolver.groupType)(this.get('listGroup.groupable_type')) + '/' + this.get('listGroup.slug') + '/status-updates/' + this.get('listStatusUpdate.slug');
      }

      return '/status-updates/' + this.get('listStatusUpdate.slug');
    }),

    otherMentions: computed('fullStatusUpdate.mentions.length', function () {
      return this.get('fullStatusUpdate.mentions.length') - NUM_MENTIONS;
    }),

    statusMentions: computed('fullStatusUpdate.mentions.[]', 'showMoreMentions', function () {
      return this.get('fullStatusUpdate.mentions').slice(0, this.get('showMoreMentions') ? this.get('fullStatusUpdate.mentions.length') : NUM_MENTIONS);
    }),

    showLinkPreview: computed('fullStatusUpdate.{link_data,pictures.length}', function () {
      return this.get('fullStatusUpdate.link_data') && !this.get('fullStatusUpdate.pictures.length');
    }),

    actions: {
      afterPostResponse: function afterPostResponse(responseId) {
        var _this = this;

        this.set('fullStatusUpdate.has_responded', true);
        this.get('store').find('list-response', responseId).then(function (response) {
          _this.get('feedItem.responses').unshiftObject(response);
        });
      },
      goToResponse: function goToResponse(listResponse) {
        this.attrs.goToLink('status-updates.show.comments', this.get('listStatusUpdate.slug'), listResponse.get('id'));
      },
      goToProfile: function goToProfile() {
        this.attrs.goToLink('profile.index', this.get('listProfile.slug'));
      },
      toggleShowMoreMentions: function toggleShowMoreMentions() {
        this.toggleProperty('showMoreMentions');
      }
    },

    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);
      this.checkIfInterventionMember();
    }
  });
});