define('desktop-frontend/components/header-notifications/notification-list/component', ['exports', 'desktop-frontend/mixins/components/ember-infinity'], function (exports, _emberInfinity) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var service = Ember.inject.service;
  var get = Ember.get;
  var computed = Ember.computed;
  exports.default = Component.extend(_emberInfinity.default, {
    classNames: ['notification-container'],
    i18n: service(),
    environment: service(),
    controlCenter: service(),
    unreadCount: 0,

    // eslint-disable-next-line ember/avoid-leaking-state-in-ember-objects
    notifications: [], // Ember infinity makes this hacky.
    modelName: 'notification',
    modelPath: 'notifications',
    perPage: 10,

    isRaiseAGlass: computed(function () {
      return get(this, 'environment').getConfig('raiseAGlass_3322');
    }),

    willDestroyElement: function willDestroyElement() {
      get(this, 'controlCenter').update();
      this._super.apply(this, arguments);
    }
  });
});