define('desktop-frontend/components/rp-tag/component', ['exports', 'rp-common/components/rp-tag/component', 'rp-common/services/trackers/aggregator'], function (exports, _component, _aggregator) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var get = Ember.get;
  var computed = Ember.computed;
  exports.default = _component.default.extend({
    itemType: _aggregator.FEED_CARD_TAG,

    tagUrl: computed('tag.groupableLink', function () {
      return get(this, 'tag.groupableLink');
    })
  });
});