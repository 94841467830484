define('desktop-frontend/components/photo-feed-item/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var or = Ember.computed.or;
  var alias = Ember.computed.alias;
  var get = Ember.get;
  var computed = Ember.computed;
  var service = Ember.inject.service;
  exports.default = Component.extend({
    classNames: ['photo-feed-item'],
    classNameBindings: ['fadeIn'],

    listFeedItem: null,
    albumOpen: false,

    router: service(),
    pageContext: service(),

    isValidFeedItem: or('listFeedItem.is_question', 'listFeedItem.is_status_update', 'listFeedItem.is_shared_link'),
    fadeIn: alias('listFeedItem.fadeIn'),
    fullModel: alias('listFeedItem.listModel.full_model'),
    listModel: alias('listFeedItem.listModel'),
    author: alias('listFeedItem.listModel.list_profile'),
    loading: or('listFeedItem.isLoading', 'fullModel.isLoading'),
    listGroup: alias('pageContext.listGroup'),
    photoPostURL: computed('listGroup.groupableLink', 'listModel.slug', 'listFeedItem.{is_question,is_shared_link,is_status_update}', function () {
      if (get(this, 'listFeedItem.is_question')) {
        return this.get('listGroup.groupableLink') + '/answers/' + this.get('listModel.slug');
      } else if (get(this, 'listFeedItem.is_shared_link')) {
        return this.get('listGroup.groupableLink') + '/shared-links/' + this.get('listModel.slug');
      }
      return this.get('listGroup.groupableLink') + '/status-updates/' + this.get('listModel.slug');
    })
  });
});