define("desktop-frontend/initializers/autoresize", ["exports", "ember-autoresize/ext/text-field", "ember-autoresize/ext/text-area"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    name: "autoresize",
    initialize: function initialize() {}
  };
});