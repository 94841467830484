define('desktop-frontend/components/list-feed-external-sponsored-post/component', ['exports', 'desktop-frontend/components/list-feed-sponsored-post/component'], function (exports, _component) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var get = Ember.get;
  var readOnly = Ember.computed.readOnly;
  var service = Ember.inject.service;
  exports.default = _component.default.extend({
    classNames: ['list-feed-external-sponsored-post'],

    sponsoredPostTracker: service('trackers/sponsored-post'),

    sponsoredPostCtaText: readOnly('listQuestion.sponsored_post_cta'),

    actions: {
      goToExternalUrl: function goToExternalUrl() {
        this.send('trackExternalUrlClick');
        window.open(get(this, 'listQuestion.sponsored_post_url'), '_blank');
      },
      trackExternalUrlClick: function trackExternalUrlClick() {
        get(this, 'sponsoredPostTracker').trackClick(get(this, 'listQuestion'));
      }
    }
  });
});