define('desktop-frontend/components/rss-module/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var service = Ember.inject.service;
  var Component = Ember.Component;
  var EmberObject = Ember.Object;
  var set = Ember.set;
  var get = Ember.get;
  var run = Ember.run;
  exports.default = Component.extend({
    classNames: ['rss-module', 'container-card'],
    logo: null,
    rssSrc: null,
    rssData: null,

    apiService: service(),

    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);
      run.scheduleOnce('afterRender', function () {
        var rssSrc = get(_this, 'rssSrc');
        return get(_this, 'apiService').getV2Request(rssSrc).then(function (result) {
          var emberified = result[rssSrc].map(function (o) {
            return EmberObject.create(o);
          });
          set(_this, 'rssData', emberified);
        });
      });
    }
  });
});