define('desktop-frontend/utility/email-validator', ['exports', 'desktop-frontend/utility/api-helper'], function (exports, _apiHelper) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    emailRegexp: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,

    isValid: function isValid(email) {
      return this.emailRegexp.test(email);
    },
    isUnique: function isUnique(email) {
      return _apiHelper.default.getV2Request('reg_checker?email=' + email).then(function (response) {
        if (!response.status) {
          return false;
        }
        return response.status !== 'registered';
      });
    },
    isInvalid: function isInvalid(email) {
      return !this.isValid(email);
    }
  };
});