define('desktop-frontend/mixins/status-update-validator', ['exports', 'ember-cp-validations'], function (exports, _emberCpValidations) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Mixin = Ember.Mixin;
  var gt = Ember.computed.gt;


  var StatusUpdateValidations = (0, _emberCpValidations.buildValidations)({
    'statusUpdate.body': {
      validators: [(0, _emberCpValidations.validator)('presence', {
        presence: true,
        ignoreBlank: true,
        disabled: gt('model.pictures.length', 0)
      })]
    }
  });

  exports.default = Mixin.create(StatusUpdateValidations, {});
});