define('desktop-frontend/components/connect-button/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var computed = Ember.computed;
  exports.default = Component.extend({
    classNames: ['connect-button'],
    provider: null,
    connected: false,
    type: 'quarter',
    label: 'Remember to set a label',

    buttonClass: computed('provider', function () {
      return this.get('provider') + '-button';
    }),

    click: function click() {
      this.get('connect')(this.get('provider'));
    }
  });
});