define('desktop-frontend/command-post/index/route', ['exports', 'ember-infinity/mixins/route', 'rp-common/mixins/infinite-scroll-analytics', 'desktop-frontend/mixins/reset-scroll-route', 'rp-common/services/trackers/qrc', 'rp-common/mixins/discussion-tracking-route'], function (exports, _route, _infiniteScrollAnalytics, _resetScrollRoute, _qrc, _discussionTrackingRoute) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var $ = Ember.$;
  var Route = Ember.Route;
  exports.default = Route.extend(_route.default, _infiniteScrollAnalytics.default, _resetScrollRoute.default, _discussionTrackingRoute.DiscussionTrackingMixin, {
    queryParams: {
      o: {
        refreshModel: true
      },
      type: {
        refreshModel: true
      },
      q: {
        refreshModel: true
      }
    },

    impressionLocation: _qrc.COMMAND_POST_FEED,

    model: function model(params) {
      params = $.extend(params, {
        perPage: 10,
        startingPage: 1
      });

      return this.infinityModel('list-command-post-feed', params);
    },
    resetController: function resetController(controller, isExiting) {
      if (isExiting) {
        controller.set('q', '');
        controller.set('type', '');
        controller.set('o', 'newdiscussions');
      }
    }
  });
});