define('desktop-frontend/components/meta-group-search/component', ['exports', 'desktop-frontend/components/question-search/component', 'desktop-frontend/components/question-search/template'], function (exports, _component, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var service = Ember.inject.service;
  var set = Ember.set;
  var get = Ember.get;
  var computed = Ember.computed;
  exports.default = _component.default.extend({
    layout: _template.default,
    metagroup: 'education',
    groupId: null,
    resultTitleField: 'title',
    resultType: 'discussions',

    questionFetcher: service('question-fetchers/meta-group'),
    i18n: service(),

    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);
      set(this, 'questionFetcher.additionalParams', {
        group_id: get(this, 'groupId')
      });
    },


    placeholder: computed('metagroup', function () {
      return get(this, 'i18n').t('metaGroups.' + get(this, 'metagroup') + '.questionSearch.placeholder');
    }),
    resultsHeading: computed('metagroup', function () {
      return get(this, 'i18n').t('metaGroups.' + get(this, 'metagroup') + '.questionSearch.resultsHeading');
    }),
    noResultsMessage: computed('metagroup', function () {
      return get(this, 'i18n').t('metaGroups.' + get(this, 'metagroup') + '.questionSearch.noResults');
    }),
    buttonText: computed(function () {
      return get(this, 'i18n').t('metaGroups.' + get(this, 'metagroup') + '.questionSearch.submit');
    })
  });
});