define('desktop-frontend/page-context/service', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var notEmpty = Ember.computed.notEmpty;
  var Service = Ember.Service;
  var service = Ember.inject.service;
  var _set = Ember.set;
  var _get = Ember.get;
  var computed = Ember.computed;
  exports.default = Service.extend({
    groupId: null,
    // TODO: This can be switched to routing once we remove islands
    onHomePage: false,

    store: service(),

    listGroup: computed('groupId', {
      get: function get() {
        if (_get(this, 'groupId')) {
          return _get(this, 'store').find('list-group', _get(this, 'groupId'));
        } else {
          return null;
        }
      },
      set: function set(group) {
        _set(this, 'groupId', group.id);
      }
    }),

    onGroupPage: notEmpty('groupId')
  });
});