define('desktop-frontend/models/endorsement', ['exports', 'rp-common/models/endorsement'], function (exports, _endorsement) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _endorsement.default;
    }
  });
});