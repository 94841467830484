define('desktop-frontend/components/copyable-input/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var service = Ember.inject.service;
  var set = Ember.set;
  var get = Ember.get;
  var computed = Ember.computed;
  exports.default = Component.extend({
    classNames: ['copyable-input'],
    text: null,
    inputId: 'copyTarget',

    copied: false,

    i18n: service(),

    buttonText: computed('copied', function () {
      return get(this, 'i18n').t(get(this, 'copied') ? 'general.copied' : 'general.copy');
    }),

    inputIdSelector: computed('inputId', function () {
      return '#' + get(this, 'inputId');
    }),

    actions: {
      onSuccess: function onSuccess() {
        set(this, 'copied', true);
      }
    }
  });
});