define('desktop-frontend/components/question-search-result/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  exports.default = Component.extend({
    classNames: ['question-search-result'],
    question: null,
    query: null
  });
});