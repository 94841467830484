define('desktop-frontend/utility/notification-helper', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var isEqual = Ember.isEqual;


  var SUBTITLES = {
    'endorsement': 'endorsed you.',
    'cr': 'is now a contact.',
    'profile_view': 'viewed your profile.',
    'new_poke': 'raised a glass to you!',
    'new_salute': 'salutes you!'
  };

  var QR_HELPER_TEXT = {
    'received_comment': 'commented on your response.',
    'mentioned_in_post': 'mentioned you in a post.',
    'received_response': 'responded to your question.',
    'have_QR_voted_up': 'voted you up.',
    'have_QR_voted_up_over_1000': 'voted you up.',
    'have_QR_voted_up_over_2000': 'voted you up.',
    'have_QR_voted_down': 'voted you down.',
    'invited_to_group': 'invited you to join a private group.',
    'joined_private_group': 'You joined a private group.'
  };

  var NEW_DISCUSSION_TYPE = 'new_group_discussion';

  exports.default = {
    getSubtitle: function getSubtitle(generalType) {
      return SUBTITLES[generalType] ? SUBTITLES[generalType] : '';
    },
    getQRHelperText: function getQRHelperText(type, groupsText) {
      if (isEqual(type, NEW_DISCUSSION_TYPE)) {
        return 'started a discussion in <strong>' + groupsText + '</strong>.';
      } else {
        return QR_HELPER_TEXT[type] ? QR_HELPER_TEXT[type] : '';
      }
    },
    getBadgeHelperText: function getBadgeHelperText(badgeType) {
      return 'earned the <span class="badge-text">' + badgeType + ' badge</span>.';
    }
  };
});