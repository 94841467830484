define('desktop-frontend/mixins/social', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var service = Ember.inject.service;
  var Mixin = Ember.Mixin;
  exports.default = Mixin.create({
    contactChecker: service('invitation-contact-checker')
  });
});