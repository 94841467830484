define('desktop-frontend/components/header-notifications/new-profile/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var get = Ember.get;
  var computed = Ember.computed;
  exports.default = Component.extend({
    // We want single-notification to be the wrapping element so we inherit styles
    tagName: '',

    linkToProfile: computed('note.list_profile.id', function () {
      return '/profiles/' + get(this, 'note.list_profile.id');
    })
  });
});