define('desktop-frontend/logout/route', ['exports', 'desktop-frontend/config/environment', 'ember-cli-js-cookie'], function (exports, _environment, _emberCliJsCookie) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var get = Ember.get;
  var Route = Ember.Route;
  var service = Ember.inject.service;
  exports.default = Route.extend({
    localStorage: service(),
    currentUser: service(),

    beforeModel: function beforeModel() {
      var _this = this;

      if (!get(this, 'currentUser.isSignedIn')) {
        this.transitionTo('login');
        return;
      }

      this.store.findRecord('session', 'current').then(function (session) {
        get(_this, 'currentUser').logout();
        var whitelist = _this.get('localStorage.whitelist');
        whitelist.forEach(function (key) {
          get(_this, 'localStorage').removeItem(key);
        });

        _emberCliJsCookie.default.remove(_environment.default.APP.SESSION_KEY, {
          secure: _environment.default.environment === 'production'
        });

        // Some users will still have this in LS after we move to cookies
        // TODO - remove this in a few months (as of 4-17-2017)
        get(_this, 'localStorage').removeItem(_environment.default.APP.SESSION_KEY);

        _environment.default.APP.SESSION_TOKEN = null;
        _this.transitionTo('login').finally(function () {
          session.destroyRecord().then(function () {
            window.location.reload();
          });
        });
      });
    }
  });
});