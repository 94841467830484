define('desktop-frontend/mixins/row-item', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Mixin = Ember.Mixin;
  var computed = Ember.computed;
  var get = Ember.get;
  exports.default = Mixin.create({
    classNameBindings: ['isFirst', 'isLast'],

    columnCount: 0,
    index: 0,

    isFirst: computed('index', 'columnCount', function () {
      return 0 === (get(this, 'index') + get(this, 'columnCount')) % get(this, 'columnCount');
    }),

    isLast: computed('index', 'columnCount', function () {
      return 0 === (get(this, 'index') + 1 + get(this, 'columnCount')) % get(this, 'columnCount');
    })
  });
});