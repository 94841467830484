define('desktop-frontend/components/feed-reason-participant/component', ['exports', 'rp-common/components/feed-reason-participant/component'], function (exports, _component) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var get = Ember.get;
  var computed = Ember.computed;
  exports.default = _component.default.extend({
    attributeBindings: ['href'],

    href: computed('participant.slug', function () {
      return '/profiles/' + get(this, 'participant.slug');
    })
  });
});