define('desktop-frontend/format-count/helper', ['exports', 'rp-common/helpers/format-count'], function (exports, _formatCount) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _formatCount.default;
    }
  });
  Object.defineProperty(exports, 'formatCount', {
    enumerable: true,
    get: function () {
      return _formatCount.formatCount;
    }
  });
});