define('desktop-frontend/preview-search/service', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Service = Ember.Service;
  var service = Ember.inject.service;
  var get = Ember.get;
  var DEFAULT_OPTS = exports.DEFAULT_OPTS = {
    search_type: 'all',
    keyword: ''
  };

  exports.default = Service.extend({
    store: service(),

    search: function search(term) {
      var opts = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

      opts = Object.assign({}, DEFAULT_OPTS, opts);

      return get(this, 'store').query('list-search-preview', opts).then(function (results) {
        return get(results, 'firstObject');
      });
    }
  });
});