define('desktop-frontend/application/route', ['exports', 'desktop-frontend/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var service = Ember.inject.service;
  var set = Ember.set;
  var get = Ember.get;
  var RSVP = Ember.RSVP;
  var Route = Ember.Route;
  var $ = Ember.$;
  exports.default = Route.extend({
    localStorage: service(),
    permission: service(),
    controlCenter: service(),
    timezoneTracking: service(),
    sessionTracking: service(),
    headerMenu: service(),

    model: function model() {
      get(this, 'controlCenter').update();
      var notifications = this.store.peekAll('notification');
      var contactRequests = this.store.peekAll('contact-request');
      var conversations = this.store.peekAll('list-conversation');

      var session = this.store.findRecord('session', 'current').catch(function (jqXHR) {
        // Request aborted, do not reset
        if (jqXHR && jqXHR.status === 0) {
          return;
        }
      });

      return RSVP.hash({
        session: session,
        notifications: notifications,
        contactRequests: contactRequests,
        conversations: conversations
      });
    },
    afterModel: function afterModel(model) {
      this._super.apply(this, arguments);
      var session = get(model, 'session');

      if (session) {
        this.setUpSession(session);
      }

      this.trackMember();
    },
    setUpSession: function setUpSession(session) {
      set(this, 'currentSession.session', session);
      get(this, 'timezoneTracking').track();
      get(this, 'permission').loadRequirements();

      this.setUpBugsnag(session);

      if (get(session, 'social_provider')) {
        this.sendLoginEvent(get(session, 'social_provider'));
      }
    },
    trackMember: function trackMember() {
      var loggedOutUser = this.store.peekRecord('public-session', 'current');
      var signedIn = get(this, 'currentSession.isSignedIn');

      if (!signedIn && !loggedOutUser) {
        return;
      }

      get(this, 'sessionTracking').trackMember(signedIn);
    },
    error: function error(err, transition) {
      if (_environment.default.environment !== 'production') {
        /* eslint-disable no-console */
        console.error(err.stack);
        /* eslint-enable no-console */
      } else if (window.Bugsnag) {
        var listProfile = this.get('currentSession.listProfile');
        if (listProfile) {
          Bugsnag.metaData.profileId = listProfile.get('id');
        }
        Bugsnag.notifyException(err, transition);
      }
    },
    setUpBugsnag: function setUpBugsnag(session) {
      if (!window.Bugsnag) {
        return;
      }

      var listProfile = session.get('listProfile');

      if (listProfile.get('id')) {
        window.Bugsnag.user.profileId = listProfile.get('id');
      }

      $(window).on('beforeunload', function () {
        window.Bugsnag.metaData.unloading = true;
      });
    },


    actions: {
      goToLink: function goToLink() {
        this.transitionToRoute.apply(this, arguments);
      },
      willTransition: function willTransition() {
        get(this, 'headerMenu').hideAll();
        get(this, 'controlCenter').update();
        get(this, 'timezoneTracking').track();
      }
    }
  });
});