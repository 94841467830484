define('desktop-frontend/components/list-feed-sponsored-post/component', ['exports', 'rp-common/components/link-preview/component', 'desktop-frontend/components/list-feed-question/component'], function (exports, _component, _component2) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var readOnly = Ember.computed.readOnly;
  var get = Ember.get;
  var computed = Ember.computed;
  var service = Ember.inject.service;
  exports.default = _component2.default.extend({
    sponsoredPostTracker: service('trackers/sponsored-post'),

    externalUrl: readOnly('listQuestion.sponsored_post_url'),
    linkData: readOnly('fullQuestion.link_data'),

    bannerCode: readOnly('listQuestion.sponsored_post_banner_code'),

    linkDataIsVideo: computed('linkData.url', function () {
      var url = get(this, 'linkData.url');
      return url && (_component.YOUTUBE_REGEX.test(url) || _component.VIMEO_REGEX.test(url));
    }),
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      if (this.get('bannerCode')) {
        this.$('.discussion-banner-code').html(this.get('bannerCode'));
        this.$('[data-revive-zoneid]').get(0).style = 'display: block !important';
      }
    }
  });
});