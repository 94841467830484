define('desktop-frontend/components/feed-featured-company-expert/component', ['exports', 'rp-common/components/feed-featured-company-expert/component'], function (exports, _component) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed;
  var readOnly = computed.readOnly;
  exports.default = _component.default.extend({
    companyLink: readOnly('feedItem.featured_company_expert.list_group.url'),
    profileLink: readOnly('feedItem.featured_company_expert.list_profile.profileLink')
  });
});