define('desktop-frontend/utility/intersect-any', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  exports.default = function (key1, key2) {
    return computed(key1 + '.[]', key2 + '.[]', function () {
      var val1 = get(this, key1);
      var val2 = get(this, key2);
      if (!val1 || !val2) {
        return false;
      }
      return val1.any(function (val) {
        return val2.includes(val);
      });
    });
  };

  var computed = Ember.computed;
  var get = Ember.get;
});