define('desktop-frontend/resource-center/index/controller', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var service = Ember.inject.service;
  var Controller = Ember.Controller;
  var set = Ember.set;
  var get = Ember.get;
  var computed = Ember.computed;
  var alias = computed.alias;

  var THIRD_COLUMN_RESOURCE_CENTERS = ['finance'];

  var SORT_OPTIONS = [{ label: 'Date Published', value: 'published_date' }, { label: 'Trending Now', value: 'trending' }, { label: 'Most Popular', value: 'popularity' }, { label: 'Recent Activity', value: 'recent' }];

  exports.default = Controller.extend({
    queryParams: ['o', 'q'],
    o: 'recent',
    q: '',

    currentUser: service(),

    tag: alias('model.tag'),
    logoLink: alias('model.tag.logo_link'),
    group: alias('model.tag.group'),
    discussions: alias('model.discussions'),
    resources: alias('model.tag.resource_links'),
    testimonials: alias('model.tag.testimonials'),
    rss: alias('model.rss'),
    profile: alias('model.profile'),
    sortOrder: alias('o'),
    searchTerm: alias('q'),
    sortOptions: SORT_OPTIONS,

    showThirdColumn: computed('model.tag.id', function () {
      var resourceCenterName = get(this, 'model.tag.id');
      return resourceCenterName && THIRD_COLUMN_RESOURCE_CENTERS.includes(resourceCenterName);
    }),

    actions: {
      changeSort: function changeSort(sort) {
        set(this, 'sortOrder', sort);
      },
      onSearch: function onSearch(term) {
        set(this, 'searchTerm', term);
      },
      goToLink: function goToLink() {}
    }
  });
});