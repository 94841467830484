define('desktop-frontend/adapters/survey', ['exports', 'desktop-frontend/adapters/application'], function (exports, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var A = Ember.A;
  exports.default = _application.default.extend({
    vote: function vote(surveyOptionId) {
      var urlParts = A([this.urlForUpdateRecord(null, 'surveys'), 'vote']);
      var data = {
        survey_option_id: surveyOptionId
      };
      return this.ajax(urlParts.join('/'), 'POST', { data: data });
    }
  });
});