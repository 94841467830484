define('desktop-frontend/contacts/route', ['exports', 'desktop-frontend/mixins/reset-scroll-route'], function (exports, _resetScrollRoute) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Route = Ember.Route;
  var get = Ember.get;
  var service = Ember.inject.service;
  var RSVP = Ember.RSVP;
  exports.default = Route.extend(_resetScrollRoute.default, {
    currentUser: service(),

    beforeModel: function beforeModel() {
      if (!get(this, 'currentUser.isSignedIn')) {
        // We should use loginThenReturn like mobile does once the rails desktop login page is Emberified.
        this.transitionTo('home');
      }
    },
    model: function model() {
      var profile = get(this, 'currentSession.profile');
      return RSVP.hash({
        profile: profile
      });
    }
  });
});