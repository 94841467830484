define('desktop-frontend/components/profile-feeds/component', ['exports', 'rp-common/services/trackers/qrc', 'desktop-frontend/components/custom-list-feed/component', 'ember-awesome-macros'], function (exports, _qrc, _component, _emberAwesomeMacros) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var get = Ember.get;
  var set = Ember.set;
  var computed = Ember.computed;


  var TYPE_ALL = 'all';

  exports.default = _component.default.extend({
    classNames: ['ember-islands', 'profile-feeds'],
    modelName: 'list-profile-feed',
    profileId: null,
    profile: null,
    location: _qrc.PROFILE_POSTS_FEED,
    impressionLocation: _qrc.PROFILE_POSTS_FEED,

    showNoRecentActivityMessage: (0, _emberAwesomeMacros.and)((0, _emberAwesomeMacros.not)('feeds.length'), 'feeds.reachedInfinity', (0, _emberAwesomeMacros.not)('newFeeds.length')),
    hasMoreItems: (0, _emberAwesomeMacros.not)('feeds.reachedInfinity'),

    isOwnProfileFeed: (0, _emberAwesomeMacros.equal)('profile.id', 'currentSession.profile.id'),

    ownProfilePlaceholder: 'statusUpdate.wizard.placeholders.update',
    defaultPlaceholder: 'profileFeed.newProfileDiscussion.placeholders.update',
    placeholderKey: (0, _emberAwesomeMacros.conditional)('isOwnProfileFeed', 'ownProfilePlaceholder', 'defaultPlaceholder'),

    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);
      get(this, 'store').find('profile', get(this, 'profileId')).then(function (fullProfile) {
        set(_this, 'profile', fullProfile);
      });
      set(this, 'infinityOptions', { profile_id: get(this, 'profileId'), type: TYPE_ALL });
    },


    unfilteredFeeds: computed(function () {
      return get(this, 'store').peekAll('list-profile-feed');
    }),

    actions: {
      infinityLoad: function infinityLoad() {
        if (get(this, '_currentPage') > 0 && this.$().is(':hidden')) {
          return;
        }

        this._super.apply(this, arguments);
      }
    }
  });
});