define('desktop-frontend/components/group-discussions/component', ['exports', 'rp-common/services/trackers/qrc', 'desktop-frontend/components/custom-list-feed/component', 'ember-awesome-macros'], function (exports, _qrc, _component, _emberAwesomeMacros) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var service = Ember.inject.service;
  var alias = Ember.computed.alias;
  var get = Ember.get;
  var set = Ember.set;
  var computed = Ember.computed;
  exports.default = _component.default.extend({
    classNames: ['group-discussions', 'ember-islands'],
    modelName: 'list-discussion',
    groupId: alias('pageContext.groupId'),
    newFeeds: null,
    sortOrder: 'published_date',
    location: _qrc.GROUP_DISCUSSIONS_FEED,
    impressionLocation: _qrc.GROUP_DISCUSSIONS_FEED,

    pageContext: service(),
    unloader: service(),

    group: alias('pageContext.listGroup'),
    taggable: (0, _emberAwesomeMacros.and)('group.taggable', (0, _emberAwesomeMacros.or)('group.taggable_for_unverified_users', 'currentSession.listProfile.verified')),
    showNewGroupDiscussion: (0, _emberAwesomeMacros.and)('currentSession.isSignedIn', 'taggable'),

    infinityOptions: computed('groupId', 'sortOrder', function () {
      return {
        group_id: get(this, 'groupId'),
        o: get(this, 'sortOrder')
      };
    }),

    // Having this be a computed property is  helpful because peekAll produces a live array.
    unfilteredFeeds: computed('groupId', 'sortOrder', function () {
      return get(this, 'store').peekAll('list-discussion');
    }),

    tag: computed('groupId', function () {
      var group_id = get(this, 'groupId');
      return get(this, 'store').createRecord('tag', {
        group_id: group_id,
        sub_name: '',
        taggable: true
      });
    }),

    init: function init() {
      this._super.apply(this, arguments);
      set(this, 'newFeeds', []);
    },


    actions: {
      ok: function ok() {
        set(this, 'ui.showNativeAlert', false);
      },
      changeSort: function changeSort(newSort) {
        get(this, 'newFeeds').clear();
        if (get(this, 'islands')) {
          get(this, 'unloader').unloadRecordsWithRelationships(get(this, 'store').peekAll('list-discussion'));
          set(this, '_currentPage', 0);
          set(this, 'feeds.reachedInfinity', false);
          set(this, 'sortOrder', newSort);
        } else {
          get(this, 'changeSort')(newSort);
        }
      }
    }
  });
});