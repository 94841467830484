define('desktop-frontend/components/google-ad/component', ['exports', 'rp-common/components/google-ad/component', 'desktop-frontend/config/environment'], function (exports, _component, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  var isArray = Ember.isArray;
  var computed = Ember.computed;
  var get = Ember.get;
  var alias = Ember.computed.alias;
  exports.default = _component.default.extend({
    currentProfile: alias('currentSession.profile'),
    currentUser: alias('currentSession'),
    classNames: ['external-ad-wrapper'],
    attributeBindings: ['userId:data-user-id'],

    ignoreAds: computed(function () {
      return _environment.default.environment === 'development' || _environment.default.environment === 'test';
    }),

    firstAdSize: computed('adSlotSize', function () {
      var firstSlotSize = get(this, 'adSlotSize.firstObject');

      if (isArray(firstSlotSize)) {
        return firstSlotSize;
      }

      return get(this, 'adSlotSize');
    }),

    userId: computed('currentUser', function () {
      return this.get('currentUser.user.id');
    }),

    initAdSlot: function initAdSlot() {
      var _this = this;

      if (get(this, 'ignoreAds')) {
        return;
      }

      if (get(this, 'currentProfile')) {
        var init = this._super;
        var args = arguments;
        get(this, 'currentProfile').then(function () {
          return init.call.apply(init, [_this].concat(_toConsumableArray(args)));
        });
      } else {
        this._super.apply(this, arguments);
      }
    }
  });
});