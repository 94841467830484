define('desktop-frontend/models/temp-profile-photo', ['exports', 'rp-common/models/temp-profile-photo'], function (exports, _tempProfilePhoto) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _tempProfilePhoto.default;
    }
  });
});