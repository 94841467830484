define('desktop-frontend/components/qrc-body-text/component', ['exports', 'rp-common/components/qrc-body-text/component'], function (exports, _component) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _component.default.extend({
    _tagProfiles: function _tagProfiles(htmlBody) {
      var match = void 0;
      var store = this.get('store');

      /* eslint-disable no-cond-assign */
      while (match = _component.TAGGED_RE.exec(htmlBody)) {
        if (match.length !== 3) {
          continue;
        }

        var profile = store.peekRecord('profile', parseInt(match[1], 10));

        if (profile) {
          var name = this.linkify(profile.get('rank') + ' ' + profile.get('full_name'), {}, {}, '/profiles/' + profile.get('id'));

          htmlBody = htmlBody.replace(match[0], name);
        } else {
          var profileId = parseInt(match[1], 10);
          htmlBody = htmlBody.replace(match[0], this.linkify(match[2], {}, {}, '/profiles/' + profileId));
        }
      }
      /* eslint-enable no-cond-assign */

      return htmlBody;
    }
  });
});