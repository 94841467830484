define('desktop-frontend/components/contact-request-item/component', ['exports', 'ember-i18n'], function (exports, _emberI18n) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var equal = Ember.computed.equal;
  var set = Ember.set;
  var get = Ember.get;
  var computed = Ember.computed;


  var CONTACT_REQUEST_REASONS = ['friend', 'seeking_for_advice', 'offering_support', 'other'];

  exports.default = Component.extend({
    tagName: 'li',
    classNames: ['list-item', 'contact-request-item'],
    contactRequest: null,

    unkownErrorText: (0, _emberI18n.translationMacro)('general.errors.unknown'),
    isOther: equal('firstReasonKey', 'other'),

    firstReasonKey: computed('contactRequest.{friend,seeking_for_advice,offering_support,other}', function () {
      var request = get(this, 'contactRequest');
      return CONTACT_REQUEST_REASONS.find(function (item) {
        return get(request, item);
      });
    }),

    firstReasonTranslation: computed('firstReasonKey', function () {
      if (get(this, 'firstReasonKey')) {
        return 'contacts.pendingRequests.reasons.' + get(this, 'firstReasonKey');
      } else {
        return 'contacts.pendingRequests.reasons.per_your_recommendation'; // default, for join links and when the key is undefined
      }
    }),

    actions: {
      accept: function accept(contactRequest) {
        var _this = this;

        contactRequest.accept().then(function () {
          get(_this, 'controlCenter').decrementProperty('pendingContactRequests');
          if (get(_this, 'noRequests')) {
            _this.sendAction('done');
          }
        }).catch(function (err) {
          _this.logError(err);
          set(_this, 'requestError', get(_this, 'unknownErrorText'));
        });
      },
      ignore: function ignore(contactRequest) {
        var _this2 = this;

        contactRequest.ignore().then(function () {
          get(_this2, 'controlCenter').decrementProperty('pendingContactRequests');
          if (get(_this2, 'noRequests')) {
            _this2.sendAction('done');
          }
        }).catch(function (err) {
          _this2.logError(err);
          set(_this2, 'requestError', get(_this2, 'unknownErrorText'));
        });
      }
    }
  });
});