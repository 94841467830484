define('desktop-frontend/services/trackers/aggregator', ['exports', 'rp-common/services/trackers/aggregator'], function (exports, _aggregator) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _aggregator.default;
    }
  });
});