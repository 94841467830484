define('desktop-frontend/components/header-search/component', ['exports', 'ember-concurrency'], function (exports, _emberConcurrency) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.ICON_MAP = exports.RESULT_LIMIT = undefined;
  var service = Ember.inject.service;
  var Component = Ember.Component;
  var run = Ember.run;
  var EmberObject = Ember.Object;
  var set = Ember.set;
  var get = Ember.get;
  var computed = Ember.computed;
  var and = Ember.computed.and;
  var empty = Ember.computed.empty;
  var gt = Ember.computed.gt;


  var MIN_TERM_LENGTH = 2;
  var RESULT_SELECTOR = '.header-search-base-result';

  var RESULT_LIMIT = exports.RESULT_LIMIT = 12;

  var ICON_MAP = exports.ICON_MAP = {
    'all': 'hamburger',
    'profiles': 'avatar',
    'answers': 'discussion-bubbles',
    'topics': 'single-discussion-bubble',
    'groups': 'group-avatars',
    'units': 'circle-star',
    'companies': 'briefcase'
  };

  var GROUP_MAP = {
    'Unit': 'units',
    'Tag': 'topics'
  };

  var RESULT_SECTIONS = [{
    key: 'profiles',
    title: 'People'
  }, {
    key: 'answers',
    title: 'Answers'
  }, {
    key: 'topics',
    title: 'Topics'
  }, {
    key: 'groups',
    title: 'Groups'
  }, {
    key: 'units',
    title: 'Units'
  }, {
    key: 'companies',
    title: 'Companies'
  }];

  var UP_ARROW = 38;
  var DOWN_ARROW = 40;
  var ESCAPE_KEY = 27;
  var RETURN_KEY = 13;

  exports.default = Component.extend({
    classNames: ['header-search'],
    classNameBindings: ['currentSession.isSignedIn::public-search', 'showCategories', 'hasResults'],

    query: '',
    results: null,
    resultSections: RESULT_SECTIONS,
    searchHasFocus: false,
    selectedItem: null,

    i18n: service(),
    previewSearch: service(),

    resultsShowing: and('searchHasFocus', 'results', 'query'),
    queryEmpty: empty('query', ''),
    showCategories: and('searchHasFocus', 'queryEmpty', 'currentSession.isSignedIn'),
    hasResults: gt('resultsCount', 0),

    activeMenu: computed('currentSession.isSignedIn', function () {
      return get(this, 'currentSession.isSignedIn') ? 'all' : 'answers';
    }),

    menuSelectorSvg: computed('activeMenu', function () {
      return 'assets/images/' + ICON_MAP[get(this, 'activeMenu')] + '.svg';
    }),

    searchPlaceholder: computed('currentSession.isSignedIn', 'activeMenu', function () {
      if (!get(this, 'currentSession.isSignedIn')) {
        return get(this, 'i18n').t('header.search.placeholders.discussions');
      }

      return get(this, 'i18n').t('header.search.placeholders.' + get(this, 'activeMenu'));
    }),

    searchMoreUrl: computed('query', 'activeMenu', function () {
      return '/search?keyword=' + get(this, 'query') + '&search_type=' + get(this, 'activeMenu');
    }),

    groupResults: function groupResults(result) {
      var grouped = EmberObject.create();
      grouped.setProperties({ profiles: result.get('list_profile_search_results'), answers: result.get('list_questions') });
      grouped.setProperties(result.get('list_group_search_results').reduce(function (hsh, group) {
        var groupKey = GROUP_MAP[group.get('list_group.groupable_type')];

        if (group.get('list_group.groupable_type_mapped') === 'Organization') {
          groupKey = 'companies';
        }
        if (!groupKey) {
          groupKey = 'groups';
        }

        hsh[groupKey].push(group);

        return hsh;
      }, { units: [], companies: [], topics: [], groups: [] }));

      var keys = Object.keys(grouped);
      var categoriesWithResultsCount = 0;
      var resultsCount = keys.reduce(function (acc, key) {
        var length = get(grouped[key], 'length');
        if (Number.isInteger(length) && length > 0) {
          categoriesWithResultsCount++;
          return length + acc;
        }
        return acc;
      }, 0);
      set(this, 'resultsCount', resultsCount);
      var numberToOmit = resultsCount + categoriesWithResultsCount - RESULT_LIMIT;
      for (var i = 0; i < numberToOmit; i++) {
        var longestList = keys.reduce(function (acc, key) {
          var length = get(grouped[key], 'length');
          length = Number.isInteger(length) ? length : 0;
          if (length > acc.length) {
            return { key: key, length: length };
          }
          return acc;
        }, { key: '', length: 0 });
        grouped[longestList.key].popObject();
      }

      set(this, 'results', grouped);
    },
    search: function search() {
      var _this = this;

      var term = get(this, 'query');
      var activeMenu = get(this, 'activeMenu');

      if (term === get(this, '_lastQuery') && activeMenu === get(this, '_lastActiveMenu') || term.length <= MIN_TERM_LENGTH) {
        return;
      }

      set(this, '_lastQuery', term);
      set(this, '_lastActiveMenu', activeMenu);

      get(this, 'previewSearch').search(term, { search_type: activeMenu, keyword: term }).then(function (results) {
        return _this.groupResults(results);
      });
    },
    navigateResults: function navigateResults(direction) {
      var currentSelected = get(this, 'selectedItem');
      var nextSelected = null;
      if (currentSelected === null) {
        set(this, 'selectedItem', 0);
        this.highlightSelectedSearchResult();
        return;
      }

      if (direction === 'down') {
        if (currentSelected === -1) {
          // At the last item, nowhere else to go.
          return;
        }
        nextSelected = currentSelected + 1;
      } else if (direction === 'up') {
        if (currentSelected === 0) {
          return; // At the top
        } else if (currentSelected === -1) {
          // "More Results" is highlighted.
          // Move back to the last selected item.
          nextSelected = this.$(RESULT_SELECTOR).length - 1;
        } else {
          nextSelected = currentSelected - 1;
        }
      } else {
        throw direction + ' is not a valid direction.';
      }
      set(this, 'selectedItem', nextSelected);
      this.highlightSelectedSearchResult();
    },
    highlightSelectedSearchResult: function highlightSelectedSearchResult() {
      var selectedItem = get(this, 'selectedItem');
      var $resultItems = this.$(RESULT_SELECTOR);
      var $moreResults = this.$('.more-results');
      $resultItems.removeClass('selected');
      if (selectedItem > $resultItems.length - 1) {
        // End of results, switch to more results item
        set(this, 'selectedItem', -1);
        $moreResults.addClass('selected');
      } else if (selectedItem === -1) {
        // Already on more results item.
        return;
      } else {
        $moreResults.removeClass('selected');
        $resultItems.eq(selectedItem).addClass('selected');
      }
    },


    hideResults: (0, _emberConcurrency.task)(_emberConcurrency.drop, regeneratorRuntime.mark(function _callee() {
      return regeneratorRuntime.wrap(function _callee$(_context) {
        while (1) {
          switch (_context.prev = _context.next) {
            case 0:
              _context.next = 2;
              return (0, _emberConcurrency.timeout)(500);

            case 2:
              set(this, 'searchHasFocus', false);
              set(this, 'selectedItem', null);

            case 4:
            case 'end':
              return _context.stop();
          }
        }
      }, _callee, this);
    })),

    actions: {
      goToSearch: function goToSearch() {
        var selectedItem = get(this, 'selectedItem');
        if (selectedItem !== null) {
          var $mainLink = this.$('.results a.selected').get(0);
          if ($mainLink) {
            window.location.href = $mainLink.href;
          }
        } else {
          window.location.href = '/search?keyword=' + encodeURIComponent(get(this, 'query')) + '&search_type=' + encodeURIComponent(get(this, 'activeMenu'));
        }
      },
      showResults: function showResults() {
        set(this, 'searchHasFocus', true);
      },
      handleNavigationKeys: function handleNavigationKeys(key, evt) {
        switch (evt.which) {
          case UP_ARROW:
            this.navigateResults('up');
            evt.preventDefault();
            return false;
          case DOWN_ARROW:
            this.navigateResults('down');
            evt.preventDefault();
            return false;
          case RETURN_KEY:
            this.send('goToSearch');
            return;
          case ESCAPE_KEY:
            get(this, 'hideResults').perform();
            return false;
          default:
            set(this, 'selectedItem', null);
        }
      },
      updateResults: function updateResults() {
        run.throttle(this, this.search, 750);
        run.debounce(this, this.search, 500);
      }
    }
  });
});