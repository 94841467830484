define('desktop-frontend/components/list-feed-bottom-bar/component', ['exports', 'rp-common/components/list-feed-bottom-bar/component'], function (exports, _component) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _component.default.extend({
    showShareButtons: false,
    classNameBindings: ['showShareButtons'],

    actions: {
      toggleInlineShareButtons: function toggleInlineShareButtons() {
        this.toggleProperty('showShareButtons');
      }
    }
  });
});