define('desktop-frontend/server-errors/service', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var EmberObject = Ember.Object;
  var Service = Ember.Service;
  exports.default = Service.extend({
    errors: EmberObject.create(),

    setFieldError: function setFieldError(name, value) {
      this.get('errors').set(name, value);
    },
    errorFor: function errorFor(fieldName) {
      return this.get('errors').get(fieldName);
    }
  });
});