define('desktop-frontend/components/trackable-link/component', ['exports', 'ember-concurrency', 'rp-common/utils/utils-base', 'rp-common/services/trackers/aggregator'], function (exports, _emberConcurrency, _utilsBase, _aggregator) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.CLOSURE_TRACKER = exports.GENERIC_TRACKER = exports.JOB_TRACKER_EXTERNAL = exports.JOB_TRACKER = exports.QRC_TRACKER = undefined;
  var Component = Ember.Component;
  var get = Ember.get;
  var service = Ember.inject.service;
  var QRC_TRACKER = exports.QRC_TRACKER = _aggregator.QRC;
  var JOB_TRACKER = exports.JOB_TRACKER = _aggregator.JOB;
  var JOB_TRACKER_EXTERNAL = exports.JOB_TRACKER_EXTERNAL = _aggregator.JOB_APPLICATION_EXTERNAL;
  var GENERIC_TRACKER = exports.GENERIC_TRACKER = 'generic';
  var CLOSURE_TRACKER = exports.CLOSURE_TRACKER = 'closure';

  exports.default = Component.extend({
    tagName: 'a',
    attributeBindings: ['href', 'style'],
    href: null,
    style: null,
    tracker: QRC_TRACKER,
    item: null,
    itemType: null,
    location: null,
    position: null,
    extraEvent: null,

    qrcTracker: service('trackers/qrc'),
    jobTracker: service('trackers/job'),
    genericTracker: service('trackers/generic'),

    trackClick: (0, _emberConcurrency.task)(_emberConcurrency.drop, regeneratorRuntime.mark(function _callee() {
      var item, itemLocation, position;
      return regeneratorRuntime.wrap(function _callee$(_context) {
        while (1) {
          switch (_context.prev = _context.next) {
            case 0:
              item = get(this, 'item');
              itemLocation = get(this, 'location');
              position = get(this, 'position');
              _context.t0 = get(this, 'tracker');
              _context.next = _context.t0 === QRC_TRACKER ? 6 : _context.t0 === JOB_TRACKER ? 9 : _context.t0 === JOB_TRACKER_EXTERNAL ? 12 : _context.t0 === GENERIC_TRACKER ? 15 : _context.t0 === CLOSURE_TRACKER ? 18 : 21;
              break;

            case 6:
              _context.next = 8;
              return get(this, 'qrcTracker').trackClick(item, itemLocation, position, true);

            case 8:
              return _context.abrupt('break', 23);

            case 9:
              _context.next = 11;
              return get(this, 'jobTracker').trackClick(item, itemLocation, position, true);

            case 11:
              return _context.abrupt('break', 23);

            case 12:
              _context.next = 14;
              return get(this, 'jobTracker').trackApplication(item, itemLocation, position, true);

            case 14:
              return _context.abrupt('break', 23);

            case 15:
              _context.next = 17;
              return get(this, 'genericTracker').trackClick(get(this, 'itemType'), item, itemLocation, position);

            case 17:
              return _context.abrupt('break', 23);

            case 18:
              _context.next = 20;
              return get(this, 'closureTracker').apply(this, []);

            case 20:
              return _context.abrupt('break', 23);

            case 21:
              (false && !(false) && Ember.assert('Invalid tracker', false));
              return _context.abrupt('return');

            case 23:
            case 'end':
              return _context.stop();
          }
        }
      }, _callee, this);
    })),

    click: function click(evt) {
      var _this = this;

      evt.preventDefault();
      if (get(this, 'extraEvent')) {
        get(this, 'extraEvent')();
      }
      get(this, 'trackClick').perform().then(function () {
        return _utilsBase.default.browserRedirect(get(_this, 'href'));
      });
    }
  });
});