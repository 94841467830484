define('desktop-frontend/mixins/nocklab-validations', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Mixin = Ember.Mixin;
  var computed = Ember.computed;
  var Promise = Ember.RSVP.Promise;
  var hash = Ember.RSVP.hash;
  var service = Ember.inject.service;
  exports.default = Mixin.create({
    nocklabsIntervention: service('nocklabs-intervention'),
    peerSupportCompleted: true,
    outreachCompleted: true,
    connected: false,

    author: computed('feedItem', 'listFeedItem', function () {
      var _this = this;

      if (!this.get('feedItem')) {
        return;
      }

      return new Promise(function (resolve) {
        _this.get('listFeedItem').then(function (listFeedItem) {
          listFeedItem.get('list_profile').then(function (listProfile) {
            resolve(listProfile);
          });
        });
      });
    }),

    isHighRisk: computed('feedItem', 'fullQrc', function () {
      var _this2 = this;

      if (!this.get('feedItem')) {
        return;
      }

      return new Promise(function (resolve) {
        _this2.get('listFeedItem').then(function () {
          _this2.get('fullQrc').then(function (fullQrc) {
            return resolve(fullQrc.get('high_risk'));
          });
        });
      });
    }),

    checkPeerSupportCompletion: function checkPeerSupportCompletion(author) {
      var _this3 = this;

      var authorId = author.get('id');
      var currentUserId = this.get('currentSession.profile.id');
      var listFeedItemId = this.get('listFeedItem.id');
      if (currentUserId !== authorId) {
        this.get('nocklabsIntervention').peerSupportCompleted(authorId, listFeedItemId).then(function (completed) {
          _this3.set('peerSupportCompleted', completed);
        });
      }
    },
    checkOutreachCompletion: function checkOutreachCompletion(author) {
      var _this4 = this;

      var authorId = author.get('id');
      var currentUserId = this.get('currentSession.profile.id');
      this.get('fullQrc').then(function (fullQrc) {
        var qrcId = fullQrc.get('id');
        if (currentUserId === authorId) {
          _this4.get('nocklabsIntervention').outreachCompleted(qrcId).then(function (completed) {
            _this4.set('outreachCompleted', completed);
          });
        }
      });
    },
    checkIfInterventionMember: function checkIfInterventionMember() {
      var _this5 = this;

      if (!this.get('currentSession.isSignedIn')) {
        return;
      }

      this.get('isHighRisk').then(function (highRisk) {
        if (!highRisk) {
          return;
        }

        var author = _this5.get('author');
        var currentUserId = _this5.get('currentSession.profile.id');
        var profile = _this5.get('store').findRecord('list-profile', currentUserId);

        hash({
          author: author,
          profile: profile
        }).then(function (_ref) {
          var author = _ref.author,
              profile = _ref.profile;

          if (!profile.get('is_intervention_group_member')) {
            return;
          }
          _this5.checkPeerSupportCompletion(author);
          _this5.checkOutreachCompletion(author);
        });
      });
    }
  });
});