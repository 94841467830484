define('desktop-frontend/components/outreach-banner/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var readOnly = Ember.computed.readOnly;
  var service = Ember.inject.service;
  exports.default = Component.extend({
    classNames: ['outreach-banner'],
    nocklabsIntervention: service('nocklabs-intervention'),
    currentUser: service(),
    qrcContent: null,
    fullContent: null,
    listProfile: readOnly('qrcContent.list_profile'),
    highRisk: readOnly('fullContent.high_risk'),
    showBanner: false,
    showConfirmModal: false,
    showQuestionsModal: false,
    participated: true,
    stepCount: 1,
    outreachCompleted: true,

    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);
      this.showStigmaBanner();
    },
    showStigmaBanner: function showStigmaBanner() {
      var isHighRisk = this.get('highRisk');
      var isInterventionGroupMember = this.get('listProfile.is_intervention_group_member');
      var completed = this.get('outreachCompleted');
      var showBanner = isInterventionGroupMember && isHighRisk && !completed;
      this.set('showBanner', showBanner);
      if (showBanner) {
        window.nlDataLayer.push({
          'event': 'ProfessionalOutreach_Viewed',
          'banner_type': 'professional_outreach',
          'qrc_id': this.get('qrcContent.id')
        });
      }
    },
    trackOpened: function trackOpened() {
      window.nlDataLayer.push({
        event: 'ProfessionalOutreach_Opened',
        banner_type: 'professional_outreach',
        qrc_id: this.get('qrcContent.id'),
        time: new Date().getTime()
      });
    },
    trackCompleted: function trackCompleted() {
      window.nlDataLayer.push({
        event: 'ProfessionalOutreach_Closed',
        banner_type: 'professional_outreach',
        qrc_id: this.get('qrcContent.id'),
        time: new Date().getTime()
      });
    },

    actions: {
      closeBanner: function closeBanner() {
        this.toggleProperty('showBanner');
      },
      moveStep: function moveStep(step) {
        this.set('stepCount', step);
        if (step === 2) {
          this.trackOpened();
        } else {
          this.trackCompleted();
        }
      },
      closeQuestionsModal: function closeQuestionsModal() {
        this.toggleProperty('showQuestionsModal');
      },
      openModal: function openModal() {
        this.toggleProperty('showQuestionsModal');
        this.toggleProperty('showBanner');
        this.trackCompleted();
      },
      closeConfirmModal: function closeConfirmModal() {
        this.toggleProperty('showConfirmModal');
      },
      completeBanner: function completeBanner() {
        var contentId = this.get('qrcContent.id');
        this.get('nocklabsIntervention').outreachAnswer(contentId);
        this.toggleProperty('showBanner');
        this.trackCompleted();
      },
      completeQuestionsModal: function completeQuestionsModal() {
        this.toggleProperty('showConfirmModal');
        this.toggleProperty('showQuestionsModal');
      }
    }
  });
});