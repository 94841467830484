define('desktop-frontend/components/photos-feed/component', ['exports', 'desktop-frontend/components/custom-list-feed/component', 'rp-common/services/trackers/qrc', 'ember-concurrency', 'ember-awesome-macros', 'rp-common/models/list-feed-content'], function (exports, _component, _qrc, _emberConcurrency, _emberAwesomeMacros, _listFeedContent) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var service = Ember.inject.service;
  var alias = Ember.computed.alias;
  var min = Ember.computed.min;
  var collect = Ember.computed.collect;
  var get = Ember.get;
  var set = Ember.set;
  var computed = Ember.computed;


  var LOAD_DELAY_RAND_MS = 200;

  // Used in islands. Recommended way to manually test is to create a new route or replace home with
  // {{photos-feed groupId=<groupIdToTest>}}
  exports.default = _component.default.extend({
    classNames: ['ember-islands'],
    modelName: 'list-photo-feed',
    location: _qrc.GROUP_PHOTOS_FEED,
    column1Height: 0,
    column2Height: 0,
    column3Height: 0,
    feedColumn1: null,
    feedColumn2: null,
    feedColumn3: null,
    perPage: 6,

    pageContext: service(),
    imageLoader: service(),

    group: alias('pageContext.listGroup'),
    groupId: alias('pageContext.groupId'),
    columns: collect('column1Height', 'column2Height', 'column3Height'),
    shortestColumnHeight: min('columns'),
    column1Shortest: (0, _emberAwesomeMacros.equal)('shortestColumnHeight', 'column1Height'),
    column2Shortest: (0, _emberAwesomeMacros.and)((0, _emberAwesomeMacros.equal)('shortestColumnHeight', 'column2Height'), (0, _emberAwesomeMacros.not)('column1Shortest')),
    column3Shortest: (0, _emberAwesomeMacros.and)((0, _emberAwesomeMacros.equal)('shortestColumnHeight', 'column3Height'), (0, _emberAwesomeMacros.not)('column1Shortest'), (0, _emberAwesomeMacros.not)('column2Shortest')),
    taggable: (0, _emberAwesomeMacros.and)('group.taggable', (0, _emberAwesomeMacros.or)('group.taggable_for_unverified_users', 'currentSession.listProfile.verified')),
    showUploadButton: (0, _emberAwesomeMacros.and)('currentSession.isSignedIn', 'taggable'),

    infinityOptions: computed('groupId', function () {
      return { group_id: get(this, 'groupId') };
    }),

    init: function init() {
      this._super.apply(this, arguments);
      set(this, 'feedColumn1', []);
      set(this, 'feedColumn2', []);
      set(this, 'feedColumn3', []);
    },
    infinityModelUpdated: function infinityModelUpdated(records) {
      this._super.apply(this, arguments);

      var listFeedItems = records.toArray();
      for (var i = 0; i < listFeedItems.length; i++) {
        get(this, 'loadColumnsTask').perform(listFeedItems[i], i === 0);
      }
    },


    loadColumnsTask: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function _callee(listFeedItem, isNoDelay) {
      var listModel, fullModel, loadedImg;
      return regeneratorRuntime.wrap(function _callee$(_context) {
        while (1) {
          switch (_context.prev = _context.next) {
            case 0:
              _context.next = 2;
              return get(listFeedItem, 'list_feed_content.listModel');

            case 2:
              listModel = _context.sent;
              _context.next = 5;
              return get(listModel, 'full_model');

            case 5:
              fullModel = _context.sent;
              _context.next = 8;
              return get(this, 'loadFullModelPhoto').perform(fullModel);

            case 8:
              loadedImg = _context.sent;
              _context.next = 11;
              return (0, _emberConcurrency.timeout)(isNoDelay ? 0 : LOAD_DELAY_RAND_MS * Math.random());

            case 11:
              this.addLoadedImgToShortestColumn(loadedImg, listFeedItem);

            case 12:
            case 'end':
              return _context.stop();
          }
        }
      }, _callee, this);
    })),

    loadFullModelPhoto: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function _callee2(fullModel) {
      var pictures, picture, urlLarge;
      return regeneratorRuntime.wrap(function _callee2$(_context2) {
        while (1) {
          switch (_context2.prev = _context2.next) {
            case 0:
              _context2.next = 2;
              return get(fullModel, 'pictures');

            case 2:
              pictures = _context2.sent;
              _context2.next = 5;
              return get(pictures, 'firstObject');

            case 5:
              picture = _context2.sent;
              _context2.next = 8;
              return get(picture, 'url_large');

            case 8:
              urlLarge = _context2.sent;

              if (urlLarge) {
                _context2.next = 13;
                break;
              }

              _context2.next = 12;
              return get(picture, 'url');

            case 12:
              urlLarge = _context2.sent;

            case 13:
              _context2.next = 15;
              return get(this, 'imageLoader').loadInBackground(urlLarge);

            case 15:
              return _context2.abrupt('return', _context2.sent);

            case 16:
            case 'end':
              return _context2.stop();
          }
        }
      }, _callee2, this);
    })),

    addLoadedImgToShortestColumn: function addLoadedImgToShortestColumn(loadedImg, listFeedItem) {
      var atTop = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;

      var pushMethod = atTop ? 'unshiftObject' : 'pushObject';
      if (get(this, 'column1Shortest')) {
        set(this, 'column1Height', get(this, 'column1Height') + loadedImg.height / loadedImg.width);
        return get(this, 'feedColumn1')[pushMethod](listFeedItem);
      } else if (get(this, 'column2Shortest')) {
        set(this, 'column2Height', get(this, 'column2Height') + loadedImg.height / loadedImg.width);
        return get(this, 'feedColumn2')[pushMethod](listFeedItem);
      }
      set(this, 'column3Height', get(this, 'column3Height') + loadedImg.height / loadedImg.width);
      get(this, 'feedColumn3')[pushMethod](listFeedItem);
    },


    addStatusUpdateToFeedTask: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function _callee3(statusUpdate) {
      var store, listStatusUpdate, listPhotoFeed, fullModel, loadedImg;
      return regeneratorRuntime.wrap(function _callee3$(_context3) {
        while (1) {
          switch (_context3.prev = _context3.next) {
            case 0:
              store = get(this, 'store');
              _context3.next = 3;
              return store.findRecord('list-status-update', statusUpdate.get('id'));

            case 3:
              listStatusUpdate = _context3.sent;
              _context3.next = 6;
              return store.createRecord('list-photo-feed', {
                list_feed_content: store.createRecord('list-feed-status-update', {
                  list_status_update: listStatusUpdate,
                  reason: _listFeedContent.OWN_POST_REASON
                })
              });

            case 6:
              listPhotoFeed = _context3.sent;
              _context3.next = 9;
              return get(listStatusUpdate, 'full_model');

            case 9:
              fullModel = _context3.sent;
              _context3.next = 12;
              return get(this, 'loadFullModelPhoto').perform(fullModel);

            case 12:
              loadedImg = _context3.sent;

              set(listPhotoFeed, 'list_feed_content.fadeIn', true);
              this.addLoadedImgToShortestColumn(loadedImg, listPhotoFeed, true);

            case 15:
            case 'end':
              return _context3.stop();
          }
        }
      }, _callee3, this);
    })),

    // Required by parent class
    unfilteredFeeds: computed('groupId', function () {
      return get(this, 'store').peekAll('list-photo-feed');
    })
  });
});