define('desktop-frontend/components/command-post/search/component', ['exports', 'desktop-frontend/components/question-search/component', 'desktop-frontend/components/question-search/template'], function (exports, _component, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var service = Ember.inject.service;
  var get = Ember.get;
  var computed = Ember.computed;
  exports.default = _component.default.extend({
    layout: _template.default,
    i18n: service(),
    placeholder: computed(function () {
      return get(this, 'i18n').t('commandPost.questionSearch.placeholder');
    }),
    resultTitleField: 'title',
    resultType: 'command_posts',
    resultsHeading: computed(function () {
      return get(this, 'i18n').t('commandPost.questionSearch.resultsHeading');
    }),
    noResultsMessage: computed(function () {
      return get(this, 'i18n').t('commandPost.questionSearch.noResults');
    }),
    buttonText: computed(function () {
      return get(this, 'i18n').t('commandPost.questionSearch.submit');
    }),
    questionFetcher: service('question-fetchers/command-post')
  });
});