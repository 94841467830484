define('desktop-frontend/mixins/serializers/pictures', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Mixin = Ember.Mixin;
  var isArray = Ember.isArray;
  exports.default = Mixin.create({
    serializeHasMany: function serializeHasMany(snapshot, json, relationship) {
      this._super.apply(this, arguments);
      if (relationship.key === 'pictures' && isArray(snapshot.hasMany('pictures'))) {
        json.picture_ids = snapshot.hasMany('pictures').mapBy('id');
      }
    }
  });
});