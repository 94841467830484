define('desktop-frontend/components/header-contact-requests/contact-requests-popout/component', ['exports', 'desktop-frontend/mixins/components/ember-infinity'], function (exports, _emberInfinity) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var sort = Ember.computed.sort;
  var Component = Ember.Component;
  var service = Ember.inject.service;
  var set = Ember.set;
  var get = Ember.get;
  var computed = Ember.computed;
  exports.default = Component.extend(_emberInfinity.default, {
    classNames: ['contact-requests-popout'],
    contactRequests: null,
    sentMessage: '',
    modelName: 'contact-request',
    modelPath: 'contactRequests',
    perPage: 25,

    emailInvite: service(),
    ui: service(),
    invitationLink: service(),
    i18n: service(),

    contactRequestsSortingDesc: ['sent_at:desc'],
    sortedContactRequests: sort('contactRequests', 'contactRequestsSortingDesc'),

    showContactRequests: computed('contactRequests.{length,reachedInfinity}', function () {
      return get(this, 'contactRequests.length') || !get(this, 'contactRequests.reachedInfinity');
    }),

    profileInviteUrl: computed('currentSession.profile.slug', function () {
      return get(this, 'invitationLink').create(get(this, 'currentSession.profile'));
    }),

    facebookUrl: computed('profileInviteUrl', function () {
      return get(this, 'invitationLink').createFacebookDialogLink(get(this, 'profileInviteUrl') + '&redirect_uri=' + window.location.href);
    }),

    emailUrl: computed('profileInviteUrl', 'currentSession.listProfile.first_name', function () {
      var subjectName = get(this, 'currentSession.listProfile.first_name');
      var subject = get(this, 'i18n').t('contacts.invite.emailSubject', { name: subjectName });
      var body = get(this, 'i18n').t('contacts.invite.email');
      return get(this, 'invitationLink').createEmailLink('subject=' + subject + '&body=' + body + ' ' + get(this, 'profileInviteUrl'));
    }),

    actions: {
      infinityModelUpdated: function infinityModelUpdated(records) {
        this._super.apply(this, arguments);

        if (get(records, 'length') < get(this, 'perPage')) {
          set(this, 'contactRequests.reachedInfinity', true);
        }
      },
      openOAuthWindow: function openOAuthWindow(evt) {
        var href = evt.currentTarget.href;

        window.open(href, 'oauth_win', 'width=800,height=600');
        evt.preventDefault();
      }
    }
  });
});