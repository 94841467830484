define('desktop-frontend/components/profile-card-image/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var EmberObject = Ember.Object;
  var computed = Ember.computed;
  var $ = Ember.$;
  var Logger = Ember.Logger;


  var IMAGE_SIZE_SMALL = 45;
  var IMAGE_SIZE_FEED = 86;
  var IMAGE_SIZE_MEDIUM = 168;
  var IMAGE_SMALL = 'small';
  var IMAGE_MEDIUM = 'medium';
  var IMAGE_FEED = 'feed';

  exports.default = Component.extend({
    classNames: ['profile-card-image'],

    profile: null,

    // this method allows users to have full control over image size, while optimizing against available sizes.
    image: computed('profile.{image_medium,image_feed,image_small,temp_profile_photo}', 'size', function () {
      var imageMedium = this.get('profile.image_medium');
      var imageFeed = this.get('profile.image_feed');
      var imageSmall = this.get('profile.image_small');
      var tmpPhoto = this.get('profile.temp_profile_photo');
      var imageSize = this.get('size');
      var image = EmberObject.create({ size: imageSize });
      if (!$.isNumeric(imageSize)) {
        if (imageSize === IMAGE_MEDIUM) {
          image.set('size', IMAGE_SIZE_MEDIUM);
        } else if (imageSize === IMAGE_FEED) {
          image.set('size', IMAGE_SIZE_FEED);
        } else if (imageSize === IMAGE_SMALL) {
          image.set('size', IMAGE_SIZE_SMALL);
        } else {
          image.set('size', IMAGE_SIZE_SMALL);
          Logger.warn('Invalid image size "' + imageSize + '" set on Profile Card. Default (' + IMAGE_SIZE_SMALL + 'px) will be used.');
        }
      }

      if (this.get('useTempPhoto') && tmpPhoto) {
        image.set('url', tmpPhoto.get('url'));
      } else if (image.size > IMAGE_SIZE_FEED || !imageFeed && imageSize > IMAGE_SIZE_SMALL) {
        image.set('url', imageMedium);
      } else if (image.size > IMAGE_SIZE_SMALL) {
        image.set('url', imageFeed);
      } else {
        image.set('url', imageSmall);
      }
      return image;
    })
  });
});