define('desktop-frontend/components/job-banner-ad/component', ['exports', 'rp-common/services/trackers/job', 'ember-concurrency'], function (exports, _job, _emberConcurrency) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var set = Ember.set;
  var get = Ember.get;
  var not = Ember.computed.not;
  var readOnly = Ember.computed.readOnly;
  var alias = Ember.computed.alias;
  var service = Ember.inject.service;
  var Component = Ember.Component;
  exports.default = Component.extend({
    tagName: 'a',
    attributeBindings: ['href', 'style', 'target'],
    classNames: ['job-banner-ad'],
    classNameBindings: ['noJob:hidden'],
    job: null,
    location: _job.JOB_BANNER_AD,
    target: '_blank',

    popularJobs: service(),

    item: alias('job'),
    href: readOnly('job.jobUrl'),
    noJob: not('job'),

    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);
      if (!get(this, 'job')) {
        get(this, 'loadJobTask').perform();
      }
    },


    loadJobTask: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function _callee() {
      var jobs, job;
      return regeneratorRuntime.wrap(function _callee$(_context) {
        while (1) {
          switch (_context.prev = _context.next) {
            case 0:
              _context.next = 2;
              return get(this, 'popularJobs').next(1);

            case 2:
              jobs = _context.sent;
              job = get(jobs, 'firstObject');

              if (job) {
                set(this, 'job', job);
              }

            case 5:
            case 'end':
              return _context.stop();
          }
        }
      }, _callee, this);
    }))
  });
});