define('desktop-frontend/components/contacts-list/component', ['exports', 'ember-concurrency'], function (exports, _emberConcurrency) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var RSVP = Ember.RSVP;
  var computed = Ember.computed;
  var set = Ember.set;
  var get = Ember.get;
  var and = Ember.computed.and;
  var not = Ember.computed.not;
  var run = Ember.run;
  var htmlSafe = Ember.String.htmlSafe;


  var SLIDE_ANIMATION_TIME_MS = 400;

  exports.default = Component.extend({
    classNames: ['contacts-list-component'],
    pageSize: 0,
    loadingMoreContacts: false,
    canLoadMoreContacts: false,
    showAllProfilesLoading: false,
    collapsed: false,
    hasCollapsed: false,
    contacts: null,
    columnCount: 3,

    cannotLoadMoreContacts: not('canLoadMoreContacts'),
    hasOnePageOfContacts: and('cannotLoadMoreContacts', 'onePageLoaded'),

    onePageLoaded: computed('contacts.[]', 'pageSize', function () {
      return get(this, 'contacts.length') <= get(this, 'pageSize');
    }),

    contactsFirst: computed('contacts.[]', 'pageSize', function () {
      return get(this, 'contacts').slice(0, get(this, 'pageSize'));
    }),

    // Divide up contacts from pages 2..N into pages
    // so we can easily animate the entrance of new pages
    contactsRestPages: computed('contacts.[]', 'pageSize', function () {
      var pageSize = get(this, 'pageSize');
      var contactsRest = get(this, 'contacts').slice(pageSize);
      return contactsRest.length && contactsRest.reduce(function (pages, contact) {
        if (pages[pages.length - 1].length < pageSize) {
          pages[pages.length - 1].push(contact);
        } else {
          pages.push([contact]);
        }
        return pages;
      }, [[]]);
    }),

    toggleRest: function toggleRest() {
      var _this = this;

      var up = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : true;

      var deferred = RSVP.defer();
      var animateMethod = up ? 'slideUp' : 'slideDown';
      run.next(function () {
        _this.$('.contacts-list.rest')[animateMethod](SLIDE_ANIMATION_TIME_MS, function () {
          return run(function () {
            return deferred.resolve(true);
          });
        });
      });
      return deferred.promise;
    },


    // Keep display in sync with collapsed even after sort (slideToggle sets display:none)
    restDisplay: computed('collapsed', function () {
      return htmlSafe(get(this, 'collapsed') ? 'display: none;' : '');
    }),

    expandOrShrinkContacts: (0, _emberConcurrency.task)(_emberConcurrency.drop, regeneratorRuntime.mark(function _callee() {
      var _this2 = this;

      return regeneratorRuntime.wrap(function _callee$(_context) {
        while (1) {
          switch (_context.prev = _context.next) {
            case 0:
              if (!get(this, 'loadingMoreContacts')) {
                _context.next = 2;
                break;
              }

              return _context.abrupt('return');

            case 2:
              if (!get(this, 'canLoadMoreContacts')) {
                _context.next = 8;
                break;
              }

              this.sendAction('loadMoreContacts');
              _context.next = 6;
              return (0, _emberConcurrency.timeout)(SLIDE_ANIMATION_TIME_MS);

            case 6:
              _context.next = 11;
              break;

            case 8:
              _context.next = 10;
              return this.toggleRest(!get(this, 'collapsed'));

            case 10:
              run.next(function () {
                _this2.toggleProperty('collapsed');
                set(_this2, 'hasCollapsed', true);
              });

            case 11:
            case 'end':
              return _context.stop();
          }
        }
      }, _callee, this);
    }))
  });
});