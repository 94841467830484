define('desktop-frontend/transitions', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  exports.default = function () {
    this.transition(this.onInitialRender(), this.childOf('.wrapper'), this.use('toLeft'));

    this.transition(this.fromRoute('invitation.landing'), this.toRoute('invitation.social-connections'), this.use('toUp'), this.reverse('toDown'));

    this.transition(this.fromRoute('invitation.social-connections'), this.toRoute(['invitation.registration', 'invitation.military-info']), this.use('toUp'), this.reverse('toDown'));

    this.transition(this.fromRoute(['invitation.registration', 'invitation.social-connections']), this.toRoute('invitation.military-info'), this.use('toUp'), this.reverse('toDown'));

    this.transition(this.fromRoute('invitation.military-info'), this.toRoute('invitation.unit-info'), this.use('toUp'), this.reverse('toDown'));

    this.transition(this.fromRoute('invitation.unit-info'), this.toRoute('invitation.contact-list'), this.use('toUp'), this.reverse('toDown'));

    this.transition(this.fromRoute('invitation.contact-list'), this.toRoute('invitation.social-connections'), this.use('crossFade'));
  };
});