define('desktop-frontend/components/pending-contacts-modal/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var service = Ember.inject.service;
  var equal = Ember.computed.equal;
  var alias = Ember.computed.alias;
  var set = Ember.set;
  var get = Ember.get;
  exports.default = Component.extend({
    contactRequests: null,
    requestError: '',
    requestDirection: 'received',
    branchSpecialityTruncateLength: 85,

    requestCount: alias('controlCenter.pendingContactRequests'),

    noRequests: equal('requestCount', 0),
    incoming: equal('requestDirection', 'received'),
    outgoing: equal('requestDirection', 'sent'),

    controlCenter: service(),

    actions: {
      accept: function accept(contactRequest) {
        var _this = this;

        get(this, 'controlCenter').decrementProperty('pendingContactRequests');
        contactRequest.accept().then(function () {
          if (get(_this, 'noRequests')) {
            _this.sendAction('done');
          }
        }).catch(function (err) {
          _this.logError(err);
          get(_this, 'controlCenter').incrementProperty('pendingContactRequests');
          set(_this, 'requestError', 'Unable to Connect. Please try again.');
        });
      },
      ignore: function ignore(contactRequest) {
        var _this2 = this;

        get(this, 'controlCenter').decrementProperty('pendingContactRequests');
        contactRequest.ignore().then(function () {
          if (get(_this2, 'noRequests')) {
            _this2.sendAction('done');
          }
        }).catch(function (err) {
          _this2.logError(err);
          get(_this2, 'controlCenter').incrementProperty('pendingContactRequests');
          set(_this2, 'requestError', 'Unable to decline. Please try again.');
        });
      }
    }
  });
});