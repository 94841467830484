define('desktop-frontend/components/sequence-next-button/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  exports.default = Component.extend({
    classNames: ['rp-btn', 'nxt-up'],
    classNameBindings: ['active'],
    active: false,
    click: function click() {
      if (this.attrs.clickAction) {
        this.attrs.clickAction();
      }
      if (this.get('active')) {
        this.attrs.validatedClickAction();
      }
    }
  });
});