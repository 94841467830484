define('desktop-frontend/components/command-post-header/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var service = Ember.inject.service;
  var Component = Ember.Component;
  exports.default = Component.extend({
    classNames: ['command-post-header'],
    classNameBindings: ['compact'],
    timestamp: null,

    environment: service()
  });
});