define('desktop-frontend/components/takeovers/penfed-pre/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var get = Ember.get;
  var service = Ember.inject.service;


  var PENFED_URL = 'https://www.penfed.org/?WT.mc_id=W3281&adcode=399';

  exports.default = Component.extend({
    classNames: ['penfed-pre'],

    takeoverTracker: service('trackers/takeover'),

    actions: {
      goToPenFed: function goToPenFed(source) {
        get(this, 'takeoverTracker').trackClick('penfed', source);
        window.open(PENFED_URL, '_blank');
      }
    }
  });
});