define('desktop-frontend/components/new-profile-discussion/component', ['exports', 'desktop-frontend/components/new-custom-discussion/component', 'rp-common/utils/profile-tag-format'], function (exports, _component, _profileTagFormat) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var set = Ember.set;
  var get = Ember.get;
  var computed = Ember.computed;
  var htmlSafe = Ember.String.htmlSafe;
  exports.default = _component.default.extend({
    classNames: ['new-profile-discussion', 'container-card'],
    autotagProfile: null,
    placeholderKey: 'profileFeed.newProfileDiscussion.placeholders.update',

    placeholderName: computed('autotagProfile.fullNameAndRank', function () {
      return htmlSafe(get(this, 'autotagProfile.fullNameAndRank'));
    }),

    actions: {
      focusIn: function focusIn() {
        set(this, 'expanded', true);
      },
      postUpdate: function postUpdate() {
        var _this = this;

        if (!get(this, 'statusValid') || get(this, 'saving')) {
          return;
        }
        var store = get(this, 'store');
        var autotagFullProfile = get(this, 'autotagProfile');
        if (autotagFullProfile) {
          var currentBody = get(this, 'statusUpdate.body');
          var profileTag = (0, _profileTagFormat.default)(autotagFullProfile);
          set(this, 'statusUpdate.body', currentBody + ' ' + profileTag);
        }
        var statusUpdate = store.createRecord('status-update', get(this, 'statusUpdate'));
        set(this, 'statusUpdate.body', '');
        set(this, 'statusUpdate.pictures', []);
        set(this, 'saving', true);

        statusUpdate.save().then(function (statusUpdate) {
          _this.statusUpdateCreated(statusUpdate);
        }).catch(function () {
          _this.removeEncodedMentions();
        }).finally(function () {
          set(_this, 'saving', false);
        });
      }
    }
  });
});