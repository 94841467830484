define('desktop-frontend/components/company-admin-widget/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var service = Ember.inject.service;
  var get = Ember.get;
  var computed = Ember.computed;
  exports.default = Component.extend({
    classNames: ['company-admin-widget'],
    company: null,

    currentSession: service(),

    advertisingSolutionsLink: computed('company.url', function () {
      var companyUrl = get(this, 'company.url');
      return companyUrl + '/admin/sponsored_topic_ads';
    }),

    talentSolutionsLink: computed('company.url', function () {
      var companyUrl = get(this, 'company.url');
      return companyUrl + '/admin/jobs';
    })
  });
});