define('desktop-frontend/components/profile-completion-widget/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var run = Ember.run;
  var readOnly = Ember.computed.readOnly;
  var get = Ember.get;
  var computed = Ember.computed;
  var service = Ember.inject.service;


  var CIRCLE_FACTOR = 408;

  exports.default = Component.extend({
    classNames: ['profile-completion-widget'],

    currentSession: service(),

    profile: readOnly('currentSession.profile'),
    listProfile: readOnly('currentSession.session.list_profile'),

    completionStyle: computed('currentSession.session.completeness', function () {
      var pct = Math.min(get(this, 'currentSession.session.completeness'), 100) / 100;
      return 'stroke-dashoffset: ' + (CIRCLE_FACTOR - pct * CIRCLE_FACTOR) + ';';
    }),

    actions: {
      fireButtonEvent: function fireButtonEvent(evt) {
        var url = evt.currentTarget.href;
        run.later(function () {
          return location.href = url;
        }, 500);
        return false;
      }
    }
  });
});