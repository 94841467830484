define('desktop-frontend/components/outreach-banner/barrier-modal/component', ['exports', 'desktop-frontend/mixins/hide-show-single-action-button'], function (exports, _hideShowSingleActionButton) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var service = Ember.inject.service;
  exports.default = Component.extend(_hideShowSingleActionButton.default, {
    nocklabsIntervention: service('nocklabs-intervention'),
    hasOverlay: false,
    answeredList: [],
    showMore: false,
    stepCount: 1,
    qrcContent: null,
    trackCompleted: function trackCompleted() {
      window.nlDataLayer.push({
        event: 'ProfessionalOutreach_Closed',
        banner_type: 'professional_outreach',
        qrc_id: this.get('qrcContent.id'),
        time: new Date().getTime()
      });
    },

    actions: {
      closeModal: function closeModal() {
        this.sendAction('close');
      },
      next: function next() {
        this.questionCompleted();
        this.sendAction('complete');
      },
      toggleAnswerList: function toggleAnswerList(value) {
        var list = this.get('answeredList');
        if (list.includes(value)) {
          list.removeObject(value);
        } else {
          list.pushObject(value);
        }
      },
      showMoreInfo: function showMoreInfo() {
        this.toggleProperty('showMore');
      },
      moveNext: function moveNext(step) {
        this.set('stepCount', step);
      }
    },
    questionCompleted: function questionCompleted() {
      var answer = this.get('answeredList');
      var contentId = this.get('qrcContent.id');
      this.get('nocklabsIntervention').outreachAnswer(contentId, answer);
      this.trackCompleted();
    }
  });
});