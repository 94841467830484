define('desktop-frontend/components/survey-options/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var getOwner = Ember.getOwner;
  var service = Ember.inject.service;
  var Component = Ember.Component;
  var uniq = Ember.computed.uniq;
  var sort = Ember.computed.sort;
  var readOnly = Ember.computed.readOnly;
  var max = Ember.computed.max;
  var mapBy = Ember.computed.mapBy;
  var equal = Ember.computed.equal;
  var get = Ember.get;
  var computed = Ember.computed;
  var $ = Ember.$;
  exports.default = Component.extend({
    classNames: ['survey-results', 'survey-options-hbs'],
    survey: null,
    rallypoint_survey: null,
    surveyOptionSort: ['id'],
    surveyOptions: readOnly('survey.survey_options'),
    sortedSurveyOptions: sort('surveyOptions', 'surveyOptionSort'),
    votes: mapBy('surveyOptions', 'votes'),
    maxVotes: max('votes'),
    uniqVotes: uniq('votes'),
    noWinner: equal('uniqVotes.length', 1),

    totalVotes: computed('surveyOptions.@each.votes', function () {
      return get(this, 'surveyOptions').reduce(function (sum, so) {
        return get(so, 'votes') + sum;
      }, 0);
    }),
    routing: service(),
    currentUser: service(),
    actions: {
      voteOnSurveyOption: function voteOnSurveyOption(surveyOption) {
        if (get(this, 'currentUser.isSignedIn')) {
          var survey = this.get('survey');
          // The isChosen prop is not part of the api, but since votes cannot be recast,
          // being temporary will probably be fine.

          var adapter = getOwner(this).lookup('adapter:survey');
          adapter.vote(surveyOption.get('id')).then(function () {
            surveyOption.incrementProperty('votes');
            survey.setProperties({
              already_voted_on_survey: true,
              showResults: false
            });
            $('.rallypoint-survey-options').hide();
            $('.rallypoint-survey-response-container').show();
            setTimeout(function () {
              $('.list-feed-rallypoint-survey-widget').hide();
            }, 3000);
          }).catch(function (err) {
            surveyOption.decrementProperty('votes');
            survey.setProperties({
              already_voted_on_survey: false,
              showResults: false
            });
            throw err;
          });
        }
      }
    }
  });
});