define('desktop-frontend/services/trackers/mobile-page-view', ['exports', 'rp-common/services/trackers/mobile-page-view'], function (exports, _mobilePageView) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _mobilePageView.default;
    }
  });
});