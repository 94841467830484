define('desktop-frontend/components/vote-button-readonly/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var get = Ember.get;
  var computed = Ember.computed;
  exports.default = Component.extend({
    tagName: '',
    whiteIcons: false,

    upvoteSrc: computed('whiteIcons', function () {
      return 'assets/images/vote-button-readonly/upvote-readonly' + (get(this, 'whiteIcons') ? '-white' : '') + '.png';
    })
  });
});