define('desktop-frontend/models/list-profile', ['exports', 'rp-common/models/list-profile'], function (exports, _listProfile) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var get = Ember.get;
  var computed = Ember.computed;
  exports.default = _listProfile.default.extend({
    profileLink: computed('slug', function () {
      return '/profiles/' + get(this, 'slug');
    })
  });
});