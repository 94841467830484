define('desktop-frontend/models/unit', ['exports', 'rp-common/models/unit'], function (exports, _unit) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _unit.default;
    }
  });
});