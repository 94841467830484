define('desktop-frontend/components/add-unit/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var service = Ember.inject.service;
  var equal = Ember.computed.equal;
  var Component = Ember.Component;
  var get = Ember.get;
  var computed = Ember.computed;
  var alias = computed.alias;
  exports.default = Component.extend({
    experience: null,
    userType: null,
    store: service(),
    i18n: service(),

    current: alias('experience.current'),

    veteran: equal('userType', 'veteran'),
    civilian: equal('userType', 'civilian'),

    remote: computed('civilian', function () {
      return get(this, 'civilian') ? 'autocompletes/companies' : 'autocompletes/unit';
    }),

    currentUnitText: computed('userType', function () {
      return get(this, 'i18n').t('addUnit.current.' + this.get('userType'), { default: 'addUnit.current.default' });
    }),

    searchText: computed('userType', function () {
      return get(this, 'i18n').t('addUnit.search.' + this.get('userType'), { default: 'addUnit.search.default' });
    }),

    actions: {
      setUnit: function setUnit(unitResult) {
        var _this = this;

        var unit_name = unitResult.value;
        get(this, 'store').findRecord('unit', unitResult.id).then(function (unit) {
          get(_this, 'experience').setProperties({ unit: unit, unit_name: unit_name });
        });
      },
      toggleExperienceCurrent: function toggleExperienceCurrent() {
        this.toggleProperty('current');
      }
    }
  });
});