define('desktop-frontend/components/connect-user-button/connect-user-modal/component', ['exports', 'ember-concurrency', 'rp-common/models/contact-request'], function (exports, _emberConcurrency, _contactRequest) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var guidFor = Ember.guidFor;
  var Component = Ember.Component;
  var computed = Ember.computed;
  var set = Ember.set;
  var get = Ember.get;
  var lt = Ember.computed.lt;
  var alias = Ember.computed.alias;
  var service = Ember.inject.service;
  exports.default = Component.extend({
    profile: null,
    serverError: '',
    submitAttempted: false,

    store: service(),
    i18n: service(),
    flashMessages: service(),
    currentSession: service(),

    explanation: alias('contactRequest.explanation_text'),
    isValid: alias('contactRequest.validations.isValid'),
    validationMessages: alias('contactRequest.validations.messages'),

    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      set(this, 'contactRequest', get(this, 'store').createRecord('contact-request', {
        id: guidFor(Math.random()),
        receiver: get(this, 'profile'),
        sender: get(this, 'currentSession.profile')
      }));
    },
    willDestroy: function willDestroy() {
      get(this, 'contactRequest').rollbackAttributes(); /* If record is new, can be garbage collected */
      this._super.apply(this, arguments);
    },


    charsLeft: computed('explanation', function () {
      return _contactRequest.MAX_EXPLANATION_LENGTH - (get(this, 'explanation.length') || 0);
    }),
    negativeChars: lt('charsLeft', 0),

    errorMessage: computed('isValid', 'validationMessages.[]', 'serverError', function () {
      var serverError = get(this, 'serverError');
      if (serverError) {
        return serverError;
      }
      if (!get(this, 'isValid') && get(this, 'validationMessages.length')) {
        return get(this, 'validationMessages')[0];
      }
      return null;
    }),

    sendContactRequest: (0, _emberConcurrency.task)(_emberConcurrency.drop, regeneratorRuntime.mark(function _callee() {
      return regeneratorRuntime.wrap(function _callee$(_context) {
        while (1) {
          switch (_context.prev = _context.next) {
            case 0:
              set(this, 'submitAttempted', true);

              if (get(this, 'isValid')) {
                _context.next = 3;
                break;
              }

              return _context.abrupt('return');

            case 3:
              if (!get(this, 'profile.blocked')) {
                _context.next = 6;
                break;
              }

              set(this, 'serverError', get(this, 'i18n').t('blocking.universal'));
              return _context.abrupt('return');

            case 6:
              _context.prev = 6;
              _context.next = 9;
              return get(this, 'contactRequest').save();

            case 9:
              get(this, 'flashMessages').success(get(this, 'i18n').t('contacts.connectButton.success'));
              this.sendAction('sentConnection', get(this, 'profile'));
              _context.next = 17;
              break;

            case 13:
              _context.prev = 13;
              _context.t0 = _context['catch'](6);

              this.reportError(_context.t0);
              set(this, 'serverError', get(this, 'i18n').t('contacts.connectButton.serverError'));

            case 17:
            case 'end':
              return _context.stop();
          }
        }
      }, _callee, this, [[6, 13]]);
    }))
  });
});