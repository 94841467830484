define('desktop-frontend/components/image-attachment-set/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  exports.default = Component.extend({
    classNames: ['pictures-area'],
    pictures: null,
    showInlineAdd: false,

    actions: {
      removeImage: function removeImage(picture) {
        this.sendAction('action', picture);
      }
    }
  });
});