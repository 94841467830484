define('desktop-frontend/content-creation/service', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Evented = Ember.Evented;
  var Service = Ember.Service;
  var CONTENT_CREATION_EVENTS = exports.CONTENT_CREATION_EVENTS = {
    CREATE_LINK: 'content-creation:create-link',
    CREATE_QUESTION: 'content-creation:create-question',
    CREATE_UPDATE: 'content-creation:create-update'
  };

  exports.default = Service.extend(Evented, {});
});