define('desktop-frontend/helpers/range', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.range = range;

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  var helper = Ember.Helper.helper;


  /*
   *
   * Use:
   *
   * {{#each (range 0 10) }}
   * ...
   *
   * Optional third param with a limit if second arg is variable.
   *
   * {{#each (range 0 recordCount displayLimit)}}
   * ...
   *
   */

  function range(params /* , hash */) {
    var _params = _slicedToArray(params, 3),
        start = _params[0],
        stop = _params[1],
        limit = _params[2];

    var count = stop > limit ? limit : stop;

    var ret = [];
    for (var i = 0; i < count; i++) {
      ret.push(i + start);
    }
    return ret;
  }

  exports.default = helper(range);
});