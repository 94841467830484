define('desktop-frontend/services/takeovers/penfed', ['exports', 'desktop-frontend/services/takeovers/base-takeover'], function (exports, _baseTakeover) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var get = Ember.get;
  var computed = Ember.computed;
  var service = Ember.inject.service;


  var POSSIBLE_ROUTES = ['home.index'];

  exports.default = _baseTakeover.default.extend({
    isOn: false,
    routing: service(),

    cssClass: 'penfed',
    preContentComponent: 'takeovers/penfed-pre',

    active: computed('routing.router.currentRouteName', function () {
      return get(this, 'isOn') && POSSIBLE_ROUTES.indexOf(get(this, 'routing.router.currentRouteName')) >= 0;
    })
  });
});