define('desktop-frontend/components/job-application-modal/component', ['exports', 'ember-concurrency'], function (exports, _emberConcurrency) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var guidFor = Ember.guidFor;
  var Component = Ember.Component;
  var set = Ember.set;
  var get = Ember.get;
  var service = Ember.inject.service;
  var tryInvoke = Ember.tryInvoke;
  var and = Ember.computed.and;
  var alias = Ember.computed.alias;
  exports.default = Component.extend({
    classNames: ['job-application-modal'],
    classNameBindings: ['formDisabled'],
    job: null,
    jobApplication: null,
    formError: '',

    store: service(),
    currentSession: service(),
    i18n: service(),

    profile: alias('currentSession.listProfile'),
    formInvalid: alias('jobApplication.validations.isInvalid'),
    formDisabled: and('submitAttempted', 'formInvalid'),

    didInsertElement: function didInsertElement() {
      var name = get(this, 'profile.first_name') + ' ' + get(this, 'profile.last_name');
      var email = get(this, 'store').createRecord('job-email', {
        id: guidFor(Math.random()),
        job_id: get(this, 'job.id'),
        name: name,
        applicant_email: get(this, 'profile.email')
      });
      set(this, 'jobApplication', email);
    },


    send: (0, _emberConcurrency.task)(_emberConcurrency.drop, regeneratorRuntime.mark(function _callee() {
      return regeneratorRuntime.wrap(function _callee$(_context) {
        while (1) {
          switch (_context.prev = _context.next) {
            case 0:
              if (!get(this, 'formInvalid')) {
                _context.next = 3;
                break;
              }

              set(this, 'submitAttempted', true);
              return _context.abrupt('return');

            case 3:
              _context.prev = 3;
              _context.next = 6;
              return get(this, 'jobApplication').save();

            case 6:
              get(this, 'store').unloadRecord(get(this, 'jobApplication'));
              set(this, 'showConfirmation', true);
              _context.next = 14;
              break;

            case 10:
              _context.prev = 10;
              _context.t0 = _context['catch'](3);

              tryInvoke(this, 'reportError', _context.t0);
              set(this, 'formError', get(this, 'i18n').t('jobs.emailApplication.submitError'));

            case 14:
            case 'end':
              return _context.stop();
          }
        }
      }, _callee, this, [[3, 10]]);
    })),

    actions: {
      cancel: function cancel() {
        get(this, 'jobApplication').rollbackAttributes();
        this.sendAction('applicationClosed');
      }
    }
  });
});