define('desktop-frontend/components/upload-photos-button/component', ['exports', 'rp-common/mixins/has-images', 'ember-concurrency'], function (exports, _hasImages, _emberConcurrency) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var get = Ember.get;
  var set = Ember.set;
  var service = Ember.inject.service;


  var MAX_PICTURES = 10;
  var SUCCESS_MSG_DURATION_MS = 2000;

  exports.default = Component.extend(_hasImages.default, {
    classNames: ['upload-photos-button'],
    classNameBindings: ['showSuccessMessage'],
    tagName: 'span',
    pictures: null,
    showSuccessMessage: false,

    store: service(),
    groupSvc: service('group'),

    onUploadFinishedTask: (0, _emberConcurrency.task)(_emberConcurrency.drop, regeneratorRuntime.mark(function _callee() {
      var tag, statusUpdate;
      return regeneratorRuntime.wrap(function _callee$(_context) {
        while (1) {
          switch (_context.prev = _context.next) {
            case 0:
              set(this, 'uploading', false);
              set(this, 'pictures', get(this, 'pictures').slice(0, MAX_PICTURES));

              tag = get(this, 'groupSvc').getTag(get(this, 'group'));
              statusUpdate = get(this, 'store').createRecord('status-update', {
                pictures: get(this, 'pictures'),
                tags: [tag]
              });
              _context.next = 6;
              return statusUpdate.save();

            case 6:
              get(this, 'onPostUpdate')(statusUpdate);
              set(this, 'showSuccessMessage', true);
              _context.next = 10;
              return (0, _emberConcurrency.timeout)(SUCCESS_MSG_DURATION_MS);

            case 10:
              set(this, 'showSuccessMessage', false);

            case 11:
            case 'end':
              return _context.stop();
          }
        }
      }, _callee, this);
    })).drop(),

    actions: {
      onUploadStart: function onUploadStart() {
        set(this, 'pictures', []);
        set(this, 'uploading', true);
      }
    }
  });
});