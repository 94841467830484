define('desktop-frontend/components/feed-mention-profile-image/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var computed = Ember.computed;
  exports.default = Component.extend({
    tagName: 'li',
    classNames: ['mention-image'],
    mention: null,

    mentionUrl: computed('mention.slug', function () {
      return '/profiles/' + this.get('mention.slug');
    })
  });
});