define('desktop-frontend/components/network-builder/base-link/component', ['exports', 'desktop-frontend/components/network-builder/base-link/template'], function (exports, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var get = Ember.get;
  var computed = Ember.computed;
  exports.default = Component.extend({
    layout: _template.default,
    tagName: 'li',
    classNames: ['site-item'],

    oAuthKey: null,
    siteName: null,
    logoSrc: null,

    oAuthUrl: computed('oAuthKey', function () {
      return '/auth/' + get(this, 'oAuthKey') + '?from_wizard=true&load_contacts_popup=true&next_step_url=%2Fnetwork_builder%2Fconnect';
    }),

    actions: {
      openOAuthWindow: function openOAuthWindow(evt) {
        var href = evt.currentTarget.href;

        window.open(href, 'oauth_win', 'width=800,height=600');
        evt.preventDefault();
      }
    }
  });
});