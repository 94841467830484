define('desktop-frontend/components/search-picker/multiple-results/result/component', ['exports', 'ember-awesome-macros'], function (exports, _emberAwesomeMacros) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var get = Ember.get;
  exports.default = Component.extend({
    tagName: 'li',
    classNames: ['result-item'],

    selected: null,

    // The back end sometimes wants these objects as a plain array of strings, sometimes wants objects with values associated with them
    // so the front end model changes after save
    displayName: (0, _emberAwesomeMacros.conditional)('selected.value', 'selected.value', 'selected'),

    actions: {
      removeItem: function removeItem() {
        get(this, 'deselectItem')(get(this, 'selected'));
      }
    }
  });
});