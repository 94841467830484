define('desktop-frontend/components/modal-popup/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var get = Ember.get;
  var computed = Ember.computed;
  var service = Ember.inject.service;
  exports.default = Component.extend({
    classNames: ['modal-popup-blocker'],
    showTitle: true,
    showDefaultButtons: true,
    hideCancelButton: false,
    frameClick: null, // optional action for capturing clicks outside of modal body
    title: '',
    isOkDisabled: false,
    busy: false, // ok button spinner
    i18n: service(),
    okButtonText: computed(function () {
      return get(this, 'i18n').t('buttons.ok');
    }),
    cancelButtonText: computed(function () {
      return get(this, 'i18n').t('buttons.cancel');
    }),

    click: function click(e) {
      if (e.target === this.element && get(this, 'frameClick')) {
        this.sendAction('frameClick');
      }
    }
  });
});