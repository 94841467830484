define('desktop-frontend/components/invitation-contact-list/component', ['exports', 'desktop-frontend/mixins/social-connectedness'], function (exports, _socialConnectedness) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var service = Ember.inject.service;
  var readOnly = Ember.computed.readOnly;
  var gt = Ember.computed.gt;
  var Component = Ember.Component;
  var EmberObject = Ember.Object;
  var computed = Ember.computed;
  var alias = computed.alias;
  exports.default = Component.extend(_socialConnectedness.default, {
    contacts: null,
    usertypes: ['servicemembers', 'veterans', 'civilians'],
    usertypesDisplay: EmberObject.create({
      'servicemembers': 'Service Members',
      'veterans': 'Veterans',
      'civilians': 'Civilians'
    }),

    localStorage: service(),
    invitationContactChecker: service(),

    user: alias('currentSession.user'),

    servicemembers: readOnly('sortedContacts.servicemember'),
    foundServicemembers: gt('servicemembers.length', 0),
    veterans: readOnly('sortedContacts.veteran'),
    foundVeterans: gt('veterans.length', 0),
    civilians: readOnly('sortedContacts.civilian'),
    foundCivilians: gt('civilians.length', 0),

    loadingContacts: computed('invitationContactChecker.totalFound', 'contacts.length', function () {
      return this.get('contacts.length') < this.get('invitationContactChecker.totalFound');
    }),

    sortedContacts: computed('contacts.[]', function () {
      var sortedContacts = {
        servicemember: [],
        veteran: [],
        civilian: []
      };
      this.get('contacts').forEach(function (contact) {
        var userType = contact.get('list_profile.user_type');
        if (userType) {
          userType = userType.toLowerCase();
          sortedContacts[userType].push(contact.get('list_profile'));
        }
      });
      return sortedContacts;
    })
  });
});