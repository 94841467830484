define('desktop-frontend/models/suggested-contact', ['exports', 'rp-common/models/suggested-contact'], function (exports, _suggestedContact) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _suggestedContact.default;
    }
  });
});