define('desktop-frontend/resource-center/controller', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Controller = Ember.Controller;
  var get = Ember.get;
  var computed = Ember.computed;
  var equal = Ember.computed.equal;
  var or = Ember.computed.or;


  var THREE_COLUMN_CENTERS = ['finance', 'wellness'];
  var REAL_ESTATE_RESOURCE_CENTER = 'real-estate';
  var WELLNESS_ESTATE_RESOURCE_CENTER = 'wellness';

  exports.default = Controller.extend({
    columnCount: computed('model.id', function () {
      var id = get(this, 'model.id');
      return id && THREE_COLUMN_CENTERS.includes(id) ? 3 : 2;
    }),

    logoPath: computed('model.id', function () {
      return '/assets/images/resource_centers/icon-' + get(this, 'model.id') + '.svg';
    }),

    isRealEstateResourceCenter: equal('model.id', REAL_ESTATE_RESOURCE_CENTER),

    isWellnessResourceCenter: equal('model.id', WELLNESS_ESTATE_RESOURCE_CENTER),

    hidePresentedBySection: or('isRealEstateResourceCenter', 'isWellnessResourceCenter')
  });
});