define('desktop-frontend/helpers/boldify-term', ['exports', 'rp-common/helpers/boldify-term'], function (exports, _boldifyTerm) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _boldifyTerm.default;
    }
  });
  Object.defineProperty(exports, 'boldifyTerm', {
    enumerable: true,
    get: function () {
      return _boldifyTerm.boldifyTerm;
    }
  });
});