define('desktop-frontend/components/group-sort-options/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;


  var SORT_OPTIONS = [{ label: 'Date Published', value: 'published_date' }, { label: 'Trending Now', value: 'trending' }, { label: 'Most Popular', value: 'popularity' }, { label: 'Recent Activity', value: 'recent' }, { label: 'Posted Anonymously', value: 'anonymous' }];

  exports.default = Component.extend({
    classNames: ['group-sort-options'],
    sortOptions: SORT_OPTIONS,
    sortOrder: null
  });
});