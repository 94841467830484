define('desktop-frontend/components/profile-card-branch-speciality/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var computed = Ember.computed;
  var get = Ember.get;
  exports.default = Component.extend({
    // Required: profile
    classNames: ['profile-card-branch-speciality'],
    profile: null,

    // The contacts page can't use css truncation because of how it interacts with flexbox
    // and IE :-(
    truncatedText: computed('truncateLength', 'profile.branch.name', 'profile.speciality.name', function () {
      var text = get(this, 'profile.branch.name') + ' | ' + get(this, 'profile.speciality.name');
      var truncateLength = get(this, 'truncateLength');
      if (truncateLength && truncateLength < text.length) {
        return text.slice(0, truncateLength) + '...';
      }
      return text;
    })
  });
});