define('desktop-frontend/components/vote-button/component', ['exports', 'rp-common/mixins/voting', 'ember-concurrency'], function (exports, _voting, _emberConcurrency) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.UPVOTE_BUTTON_SIZE_NORMAL = exports.UPVOTE_BUTTON_SIZE_LARGE = undefined;
  var Component = Ember.Component;
  var get = Ember.get;
  var computed = Ember.computed;
  var service = Ember.inject.service;
  var UPVOTE_BUTTON_SIZE_LARGE = exports.UPVOTE_BUTTON_SIZE_LARGE = 'large';
  var UPVOTE_BUTTON_SIZE_NORMAL = exports.UPVOTE_BUTTON_SIZE_NORMAL = 'normal';

  exports.default = Component.extend(_voting.default, {
    classNames: ['vote-component'],
    classNameBindings: ['voteType', 'buttonSize'],
    voteableModel: null,
    voteableProfile: null,
    voteType: 'up',
    upvoteButtonSize: UPVOTE_BUTTON_SIZE_NORMAL,

    router: service(),
    pendingLoginActions: service(),

    upvoteImgSrc: computed('upVoted', function () {
      if (get(this, 'buttonSize') === UPVOTE_BUTTON_SIZE_LARGE) {
        var _modifier = get(this, 'upVoted') ? '-activated' : '';
        return 'assets/images/vote-button/vote-up-bg' + _modifier + '.png';
      }
      var modifier = get(this, 'upVoted') ? '-selected' : '';
      return 'assets/images/vote-button/upvote' + modifier + '.png';
    }),

    downvoteImgSrc: computed('downVoted', function () {
      var modifier = get(this, 'downVoted') ? '-selected' : '';
      return 'assets/images/vote-button/downvote' + modifier + '.png';
    }),

    signedOutVoteTask: (0, _emberConcurrency.task)(_emberConcurrency.drop, regeneratorRuntime.mark(function _callee() {
      var direction, confirmed, voteableModel;
      return regeneratorRuntime.wrap(function _callee$(_context) {
        while (1) {
          switch (_context.prev = _context.next) {
            case 0:
              direction = get(this, 'voteType');
              _context.next = 3;
              return direction === 'up' || get(this, 'voting').confirmDownvote();

            case 3:
              confirmed = _context.sent;

              if (!confirmed) {
                _context.next = 10;
                break;
              }

              _context.next = 7;
              return get(this, 'voteableModel');

            case 7:
              voteableModel = _context.sent;

              get(this, 'pendingLoginActions').addVote(voteableModel, direction);
              get(this, 'router').transitionTo('registration.landing');

            case 10:
            case 'end':
              return _context.stop();
          }
        }
      }, _callee, this);
    })),

    click: function click() {
      if (get(this, 'currentUser.isSignedIn')) {
        this.send('vote', get(this, 'voteType'));
      } else {
        get(this, 'signedOutVoteTask').perform();
      }
    }
  });
});