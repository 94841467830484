define('desktop-frontend/components/feed-jobs-list/job-item/component', ['exports', 'desktop-frontend/components/feed-jobs-list/job-item/template', 'rp-common/components/feed-jobs-list/job-item/component', 'desktop-frontend/components/trackable-link/component'], function (exports, _template, _component, _component2) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed;
  var set = Ember.set;
  var get = Ember.get;
  exports.default = _component.default.extend({
    layout: _template.default,
    location: null,
    position: null,

    jobApplicationOpen: false,
    tracker: _component2.JOB_TRACKER,

    jobUrl: computed('job.{company_slug,slug}', function () {
      return '/organizations/' + get(this, 'job.company_slug') + '/jobs/' + get(this, 'job.slug');
    }),

    jobCompanyLink: computed('job.company_slug', function () {
      var slug = get(this, 'job.company_slug');
      return '/organizations/' + slug + '/jobs';
    }),

    actions: {
      openJobApplicationForm: function openJobApplicationForm() {
        set(this, 'jobApplicationOpen', true);
      },
      applicationClosed: function applicationClosed() {
        set(this, 'jobApplicationOpen', false);
      }
    }
  });
});