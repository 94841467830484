define('desktop-frontend/components/feeds-list/component', ['exports', 'desktop-frontend/content-creation/service', 'rp-common/models/list-feed-content', 'rp-common/components/google-ad/component'], function (exports, _service, _listFeedContent, _component) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.WIZARD_UPDATE = exports.WIZARD_LINK = exports.WIZARD_QUESTION = undefined;
  var or = Ember.computed.or;
  var empty = Ember.computed.empty;
  var service = Ember.inject.service;
  var Component = Ember.Component;
  var run = Ember.run;
  var computed = Ember.computed;
  var set = Ember.set;
  var get = Ember.get;
  var WIZARD_QUESTION = exports.WIZARD_QUESTION = 'question';
  var WIZARD_LINK = exports.WIZARD_LINK = 'link';
  var WIZARD_UPDATE = exports.WIZARD_UPDATE = 'update';

  exports.default = Component.extend({
    classNames: ['feed-list', 'js-open-wizard'],
    feeds: [],
    location: null,
    openWizard: '',
    newFeeds: [],
    scrollable: 'body',
    perPage: 10,
    modelName: 'list-feed',
    modelPath: 'feeds',
    showStatusUpdateWizard: false,
    showShareLinkWizard: false,
    showAskQuestionWizard: false,
    showReasons: false,

    questionModal: null,
    bannerAdSize: _component.BANNER_SLOT_SIZE,

    i18n: service(),
    permission: service(),
    store: service(),
    ui: service(),
    contentCreation: service(),
    localStorage: service(),
    controlCenter: service(),

    wizardVisible: or('showAskQuestionWizard', 'showStatusUpdateWizard', 'showShareLinkWizard'),
    feedsEmpty: empty('feeds'),
    showEmptyFeedMessage: or('feedsEmpty', 'feeds.reachedInfinity'),

    lastYear: computed(function () {
      var dt = new Date();
      dt.setFullYear(dt.getFullYear() - 1);
      return dt;
    }),

    shouldShowSponsor: true,

    didInsertElement: function didInsertElement() {
      this.autoOpenWizard();
      this.setupListeners();
      this._super.apply(this, arguments);
    },
    willDestroyElement: function willDestroyElement() {
      this.teardownListeners();
      this._super.apply(this, arguments);
    },
    autoOpenWizard: function autoOpenWizard() {
      switch (get(this, 'openWizard')) {
        case WIZARD_QUESTION:
          this.send('askQuestionWhenReady');
          break;

        case WIZARD_LINK:
        case 'shared_link':
          this.send('shareLink');
          break;

        default:
          set(this, 'showStatusUpdateWizard', true);
      }
    },
    setupListeners: function setupListeners() {
      var _this = this;

      this.$().on('feeds-list:openWizard', function (evt, wiz) {
        set(_this, 'openWizard', wiz);
        _this.autoOpenWizard();
        return true;
      });

      get(this, 'contentCreation').on(_service.CONTENT_CREATION_EVENTS.CREATE_LINK, this, function () {
        return _this.send('shareLink');
      });
      get(this, 'contentCreation').on(_service.CONTENT_CREATION_EVENTS.CREATE_QUESTION, this, function () {
        return _this.send('askQuestionWhenReady');
      });
      get(this, 'contentCreation').on(_service.CONTENT_CREATION_EVENTS.CREATE_UPDATE, this, function () {
        return _this.send('postUpdate');
      });
    },
    teardownListeners: function teardownListeners() {
      this.$().off('feeds-list:openWizard');
    },
    showPermissionDialog: function showPermissionDialog() {
      get(this, 'ui').nativeAlert(get(this, 'permission.newQuestionReputationError'), get(this, 'i18n').t('feedsList.permissionModal.title'));
    },


    actions: {
      reachedInfinity: function reachedInfinity() {
        set(this, 'feeds.reachedInfinity', true);
      },
      askQuestionWhenReady: function askQuestionWhenReady() {
        var _this2 = this;

        run.cancel(get(this, 'whenReadyTimeout'));

        /* eslint-disable eqeqeq, no-eq-null */
        if (get(this, 'permission.requiredQuestionPoints') == null || get(this, 'currentSession.session.reputation') === undefined) {
          set(this, 'whenReadyTimeout', run.later(function () {
            return _this2.send('askQuestionWhenReady');
          }, 500));
          return;
        }
        /* eslint-enable eqeqeq, no-eq-null */

        this.send('askQuestion');
      },
      askQuestion: function askQuestion() {
        var requiredQuestionPoints = get(this, 'permission.requiredQuestionPoints');
        var currentPoints = get(this, 'currentSession.session.reputation');

        // Wait for requirements to load
        if (requiredQuestionPoints === null || currentPoints === null || currentPoints === undefined) {
          this.send('askQuestionWhenReady');
          return;
        }

        if (currentPoints < requiredQuestionPoints) {
          this.showPermissionDialog();
          return;
        }
        set(this, 'showAskQuestionWizard', true);
        set(this, 'showStatusUpdateWizard', false);
        set(this, 'showShareLinkWizard', false);
      },
      shareLink: function shareLink() {
        set(this, 'showShareLinkWizard', true);
        set(this, 'showStatusUpdateWizard', false);
        set(this, 'showAskQuestionWizard', false);
      },
      postUpdate: function postUpdate() {
        set(this, 'showStatusUpdateWizard', true);
        set(this, 'showShareLinkWizard', false);
        set(this, 'showAskQuestionWizard', false);
      },
      forceLoader: function forceLoader() {
        this.sendAction('forceLoader');
      },
      forceRefresh: function forceRefresh() {
        this.sendAction('forceRefresh');
      },
      saveScrollPosition: function saveScrollPosition(position) {
        this.sendAction('saveScrollPosition', position);
      },
      questionCreated: function questionCreated(pojoQuestion) {
        var _this3 = this;

        var store = get(this, 'store');

        store.find('list-question', pojoQuestion.id).then(function (listQuestion) {
          var feedItem = store.createRecord('list-feed', {
            list_feed_content: store.createRecord('list-feed-question', {
              list_question: listQuestion,
              reason: _listFeedContent.OWN_POST_REASON
            })
          });

          get(_this3, 'newFeeds').unshiftObject(feedItem);
          get(_this3, 'controlCenter').update();
        });
      },
      statusUpdateCreated: function statusUpdateCreated(statusUpdate) {
        var _this4 = this;

        var store = get(this, 'store');

        store.find('list-status-update', get(statusUpdate, 'id')).then(function (listStatusUpdate) {
          var feedItem = store.createRecord('list-feed', {
            list_feed_content: store.createRecord('list-feed-status-update', {
              list_status_update: listStatusUpdate,
              reason: _listFeedContent.OWN_POST_REASON
            })
          });

          get(_this4, 'newFeeds').unshiftObject(feedItem);
          get(_this4, 'controlCenter').update();
        });
      },
      sharedLinkCreated: function sharedLinkCreated(sharedLink) {
        var _this5 = this;

        var store = get(this, 'store');

        store.find('list-shared-link', get(sharedLink, 'id')).then(function (listSharedLink) {
          var feedItem = store.createRecord('list-feed', {
            list_feed_content: store.createRecord('list-feed-shared-link', {
              list_shared_link: listSharedLink,
              reason: _listFeedContent.OWN_POST_REASON
            })
          });

          get(_this5, 'newFeeds').unshiftObject(feedItem);
          get(_this5, 'controlCenter').update();
        });
      }
    }
  });
});