define('desktop-frontend/components/header-search/base-result/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var notEmpty = Ember.computed.notEmpty;
  exports.default = Component.extend({
    classNames: ['header-search-base-result'],
    classNameBindings: ['hasImage', 'categoryResult'],
    attributeBindings: ['href'],
    tagName: 'a',

    href: '#',
    listResult: null,
    term: null,
    svgURL: '',
    i18nLabel: '',
    categoryResult: false,

    hasImage: notEmpty('svgURL')
  });
});