define('desktop-frontend/header-menu/service', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Service = Ember.Service;
  var set = Ember.set;
  var $ = Ember.$;
  var homeIsOpen = exports.homeIsOpen = 'homeIsOpen';
  var resourcesIsOpen = exports.resourcesIsOpen = 'resourcesIsOpen';
  var offersIsOpen = exports.offersIsOpen = 'offersIsOpen';
  var messagesIsOpen = exports.messagesIsOpen = 'messagesIsOpen';
  var notificationsIsOpen = exports.notificationsIsOpen = 'notificationsIsOpen';
  var profileIsOpen = exports.profileIsOpen = 'profileIsOpen';
  var businessIsOpen = exports.businessIsOpen = 'businessIsOpen';

  var ALL_MENUS = new Set([homeIsOpen, resourcesIsOpen, offersIsOpen, messagesIsOpen, notificationsIsOpen, profileIsOpen, businessIsOpen]);

  exports.default = Service.extend({
    homeIsOpen: false,
    resourcesIsOpen: false,
    offersIsOpen: false,
    messagesIsOpen: false,
    notificationsIsOpen: false,
    profileIsOpen: false,
    businessIsOpen: false,

    hideAll: function hideAll() {
      this.setProperties({ 'homeIsOpen': false, 'resourcesIsOpen': false, 'offersIsOpen': false, 'messagesIsOpen': false,
        'notificationsIsOpen': false, 'profileIsOpen': false, 'businessIsOpen': false });
    },
    showMenu: function showMenu(menu) {
      var _this = this;

      set(this, menu, true);
      var menusToHide = new Set(ALL_MENUS);
      menusToHide.delete(menu);
      menusToHide.forEach(function (menuToHide) {
        set(_this, menuToHide, false);
      });
    },
    onBodyClick: function onBodyClick(evt, applicationController) {
      var $target = $(evt.target);
      if (!($target.is('.js-header-drop-down-wrapper') || $target.parents('.js-header-drop-down-wrapper').length)) {
        if (!applicationController.isDestroyed) {
          this.hideAll();
        }
      }
    }
  });
});