define('desktop-frontend/components/sidebar-jobs-list/component', ['exports', 'ember-data', 'rp-common/services/trackers/job'], function (exports, _emberData, _job) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var alias = Ember.computed.alias;
  var Component = Ember.Component;
  var set = Ember.set;
  var get = Ember.get;
  var computed = Ember.computed;
  var service = Ember.inject.service;
  exports.default = Component.extend({
    classNames: ['sidebar-jobs-list'],

    hasFreshCareerPreferences: false,
    location: _job.JOB_SIDE_HOME,

    popularJobs: service(),
    jobTracker: service('trackers/job'),
    careerPreferences: service(),

    jobs: alias('widgetJobs.content'),

    viewMoreJobsLink: computed('hasFreshCareerPreferences', function () {
      if (get(this, 'hasFreshCareerPreferences')) {
        return '/careers/civilian';
      } else {
        return 'careers/civilian/preferences';
      }
    }),

    track: function track(jobs) {
      if (jobs.length) {
        get(this, 'jobTracker').trackImpressions(jobs, get(this, 'location'));
      }
      return jobs;
    },


    widgetJobs: computed(function () {
      return _emberData.default.PromiseObject.create({
        promise: get(this, 'popularJobs').next(4).then(this.track.bind(this))
      });
    }),

    didReceiveAttrs: function didReceiveAttrs() {
      var _this = this;

      this._super.apply(this, arguments);
      get(this, 'careerPreferences').userHasFreshPreferences().then(function (isFresh) {
        set(_this, 'hasFreshCareerPreferences', isFresh);
      });
    }
  });
});