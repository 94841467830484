define('desktop-frontend/components/sent-contacts-modal/component', ['exports', 'desktop-frontend/components/pending-contacts-modal/component', 'ember-concurrency'], function (exports, _component, _emberConcurrency) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var set = Ember.set;
  var get = Ember.get;
  var equal = Ember.computed.equal;
  exports.default = _component.default.extend({
    requestDirection: 'sent',
    sentRequestCount: 0,

    noRequests: equal('sentRequestCount', 0),

    didReceiveAttrs: function didReceiveAttrs() {
      set(this, 'sentRequestCount', get(this, 'contactRequests.length'));
    },


    cancel: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function _callee(contactRequest) {
      return regeneratorRuntime.wrap(function _callee$(_context) {
        while (1) {
          switch (_context.prev = _context.next) {
            case 0:
              _context.prev = 0;
              _context.next = 3;
              return contactRequest.destroyRecord();

            case 3:
              this.decrementProperty('sentRequestCount');
              if (get(this, 'noRequests')) {
                this.sendAction('done');
              }
              _context.next = 11;
              break;

            case 7:
              _context.prev = 7;
              _context.t0 = _context['catch'](0);

              this.logError(_context.t0);
              set(this, 'requestError', 'Unable to Connect. Please try again.');

            case 11:
            case 'end':
              return _context.stop();
          }
        }
      }, _callee, this, [[0, 7]]);
    }))
  });
});