define('desktop-frontend/components/command-post-submission-cta/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var service = Ember.inject.service;
  var set = Ember.set;
  var get = Ember.get;


  var MAIL_TO_URL = 'command-post@rallypoint.com';

  exports.default = Component.extend({
    classNames: ['command-post-submission-cta'],

    i18n: service(),

    init: function init() {
      this._super.apply(this, arguments);
      var subject = get(this, 'i18n').t('commandPostSubmissionCTA.emailSubject');
      set(this, 'mailTo', 'mailto:' + MAIL_TO_URL + '?subject=' + encodeURI(subject));
    }
  });
});