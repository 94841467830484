define('desktop-frontend/serializers/civilian-preference', ['exports', 'active-model-adapter'], function (exports, _activeModelAdapter) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _activeModelAdapter.ActiveModelSerializer.extend({
    normalize: function normalize(typeClass, payload) {
      if (payload.job_interests) {
        payload.job_interests = payload.job_interests.mapBy('job_interest_name');
      }

      return this._super.apply(this, arguments);
    }
  });
});