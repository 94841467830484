define('desktop-frontend/components/feed-add-comment/component', ['exports', 'rp-common/mixins/has-images', 'rp-common/mixins/mention-limit', 'ember-concurrency'], function (exports, _hasImages, _mentionLimit, _emberConcurrency) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var service = Ember.inject.service;
  var not = Ember.computed.not;
  var gt = Ember.computed.gt;
  var and = Ember.computed.and;
  var readOnly = Ember.computed.readOnly;
  var notEmpty = Ember.computed.notEmpty;
  var or = Ember.computed.or;
  var alias = Ember.computed.alias;
  var Component = Ember.Component;
  var computed = Ember.computed;
  var get = Ember.get;
  var set = Ember.set;
  var run = Ember.run;
  exports.default = Component.extend(_hasImages.default, _mentionLimit.default, {
    classNames: ['feed-add-comment'],
    listModel: null,
    newComment: null,
    uploading: false,
    response: null,

    store: service(),

    currentProfile: alias('currentSession.listProfile'),
    notUploading: not('uploading'),
    rootModel: alias('listModel'),
    pictures: alias('newComment.pictures'),
    hasPictures: gt('pictures.length', 0),
    hasValidPicture: and('hasPictures', 'notUploading'),
    blocked: readOnly('listProfile.blocked'),
    bodyValid: notEmpty('newComment.body'),
    formValid: or('bodyValid', 'hasValidPicture'),
    formInvalid: not('formValid'),
    mentionBody: readOnly('newComment.body'),

    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      this.$('textarea').focus();
      get(this, 'generateNewCommentTask').perform();
    },


    loadingPicture: computed(function () {
      var store = get(this, 'store');
      return store.createRecord('picture', { url: 'assets/images/spinner-star-gray.gif' });
    }),

    generateNewCommentTask: (0, _emberConcurrency.task)(_emberConcurrency.drop, regeneratorRuntime.mark(function _callee() {
      var store, list_response, profile;
      return regeneratorRuntime.wrap(function _callee$(_context) {
        while (1) {
          switch (_context.prev = _context.next) {
            case 0:
              store = get(this, 'store');
              _context.next = 3;
              return get(this, 'response');

            case 3:
              list_response = _context.sent;
              _context.next = 6;
              return get(this, 'currentSession.profile');

            case 6:
              profile = _context.sent;

              set(this, 'newComment', store.createRecord('comment', {
                profile: profile,
                list_response: list_response
              }));

            case 8:
            case 'end':
              return _context.stop();
          }
        }
      }, _callee, this);
    })),

    postCommentTask: (0, _emberConcurrency.task)(_emberConcurrency.drop, regeneratorRuntime.mark(function _callee2() {
      var comment;
      return regeneratorRuntime.wrap(function _callee2$(_context2) {
        while (1) {
          switch (_context2.prev = _context2.next) {
            case 0:
              _context2.next = 2;
              return get(this, 'newComment').save();

            case 2:
              comment = _context2.sent;

              get(this, 'afterPostComment')(get(comment, 'id'));
              get(this, 'generateNewCommentTask').perform();

            case 5:
            case 'end':
              return _context2.stop();
          }
        }
      }, _callee2, this);
    })),

    willDestroyElement: function willDestroyElement() {
      get(this, 'newComment').unloadRecord();
      this._super.apply(this, arguments);
    },


    actions: {
      closeSelf: function closeSelf() {
        get(this, 'close')();
      },
      toggleMention: function toggleMention() {
        var _this = this;

        var bodyEle = this.$('.comment-body').get(0);
        var body = get(this, 'newComment.body');
        var idx = bodyEle.selectionStart || body.length;
        var spaceNeeded = body[idx - 2] !== ' ';

        if (body[idx - 1] !== '@') {
          set(this, 'newComment.body', '' + body.substr(0, idx) + (spaceNeeded ? ' ' : '') + '@' + body.substr(idx));
        }

        run.scheduleOnce('afterRender', function () {
          bodyEle.focus();
          bodyEle.selectionStart = bodyEle.selectionEnd = idx + (spaceNeeded ? 2 : 1);
          _this.$('.comment-body').trigger('mentions:toggle');
        });

        set(this, '_manualToggle', true);
      },
      hidingMention: function hidingMention() {
        if (!get(this, '_manualToggle')) {
          return;
        }

        set(this, '_manualToggle', false);

        var $body = this.$('.comment-body');
        var body = get(this, 'newComment.body');
        var idx = $body.get(0).selectionStart || body.length;

        if (body[idx - 1] === '@') {
          set(this, 'newComment.body', '' + body.substr(0, idx - 1) + body.substr(idx));
        }
      },
      onUploadStart: function onUploadStart() {
        set(this, 'uploading', true);
        get(this, 'pictures').pushObject(get(this, 'loadingPicture'));
      },
      onUploadFinished: function onUploadFinished() {
        set(this, 'uploading', false);
        get(this, 'pictures').removeObject(get(this, 'loadingPicture'));
      }
    }
  });
});