define('desktop-frontend/components/sidebar-creation-menu/component', ['exports', 'desktop-frontend/content-creation/service'], function (exports, _service) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var $ = Ember.$;
  var Component = Ember.Component;
  var get = Ember.get;
  var service = Ember.inject.service;
  var run = Ember.run;
  exports.default = Component.extend({
    classNames: ['sidebar-creation-menus'],

    contentCreation: service(),

    actions: {
      scrollToTop: function scrollToTop() {
        run(function () {
          $('body,html').animate({ scrollTop: 0 }, 'fast');
        });
      },
      shareLink: function shareLink() {
        this.send('scrollToTop');

        if (get(this, 'shareLink')) {
          return get(this, 'shareLink')();
        }
        get(this, 'contentCreation').trigger(_service.CONTENT_CREATION_EVENTS.CREATE_LINK);
      },
      askQuestion: function askQuestion() {
        this.send('scrollToTop');

        if (get(this, 'askQuestion')) {
          return get(this, 'askQuestion')();
        }
        get(this, 'contentCreation').trigger(_service.CONTENT_CREATION_EVENTS.CREATE_QUESTION);
      },
      postUpdate: function postUpdate() {
        this.send('scrollToTop');

        if (get(this, 'postUpdate')) {
          return get(this, 'postUpdate')();
        }
        get(this, 'contentCreation').trigger(_service.CONTENT_CREATION_EVENTS.CREATE_UPDATE);
      }
    }
  });
});