define('desktop-frontend/components/contacts-counter/component', ['exports', 'desktop-frontend/mixins/social'], function (exports, _social) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var service = Ember.inject.service;
  var alias = Ember.computed.alias;
  var gt = Ember.computed.gt;
  var Component = Ember.Component;
  var computed = Ember.computed;
  exports.default = Component.extend(_social.default, {
    invitationContactChecker: service(),
    contactCount: alias('invitationContactChecker.contactCount'),
    plural: gt('contactCount', 1),

    grayBoxCount: computed('contactCount', function () {
      return this.get('contactCount') - 1;
    })
  });
});