define('desktop-frontend/contacts/index/route', ['exports', 'ember-infinity/mixins/route', 'ember-concurrency'], function (exports, _route, _emberConcurrency) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.CONTACTS_PAGE_SIZE = undefined;
  var Route = Ember.Route;
  var RSVP = Ember.RSVP;
  var computed = Ember.computed;
  var set = Ember.set;
  var get = Ember.get;
  var service = Ember.inject.service;
  var CONTACTS_PAGE_SIZE = exports.CONTACTS_PAGE_SIZE = 6;

  var SORT_VALS = {
    recent: 'recently_connected',
    first_name: 'first_name',
    last_name: 'last_name'
  };

  exports.default = Route.extend(_route.default, {
    per: CONTACTS_PAGE_SIZE,
    profile: null,

    modelContacts: null,
    modelPYMK: null,
    modelContactRequests: null,
    contactsCurrentPage: 0,
    contactsSort: null,
    contactsTotalPages: 0,

    api: service('api-service'),

    queryParams: {
      sort: {
        refreshModel: true
      }
    },

    model: function model(params) {
      var sort = params.sort,
          filter = params.filter;

      var _modelFor = this.modelFor('contacts'),
          profile = _modelFor.profile;

      set(this, 'profile', profile);

      var contacts = void 0,
          pymk = void 0,
          contactRequests = void 0,
          sentRequests = void 0;

      contacts = this.initContacts(sort, filter);
      set(this, 'modelContacts', contacts);

      pymk = this.infinityModel('suggested-contact', {
        perPage: get(this, 'per'),
        modelPath: 'controller.model.pymk'
      });

      contactRequests = this.store.findAll('contact-request');
      set(this, 'modelContactRequests', contactRequests);

      sentRequests = this.store.query('contact-request', { request_type: 'sent' });

      return RSVP.hash({
        contacts: contacts,
        pymk: pymk,
        contactRequests: contactRequests,
        sentRequests: sentRequests
      });
    },


    contactsCanLoadMore: computed('contactsCurrentPage', 'contactsTotalPages', function () {
      var totalPages = get(this, 'contactsTotalPages');
      var currentPage = get(this, 'contactsCurrentPage');
      return totalPages && currentPage !== undefined ? currentPage < totalPages : false;
    }),

    initContacts: function initContacts(sort) {
      set(this, 'contactsCurrentPage', 0);
      set(this, 'contactsTotalPages', 0);
      set(this, 'contactsSort', sort);
      return this.requestMoreContacts();
    },
    requestMoreContacts: function requestMoreContacts() {
      var _this = this;

      this.incrementProperty('contactsCurrentPage');
      var query = {
        member: 'contacts',
        profile_id: get(this, 'profile.id'),
        order: SORT_VALS[get(this, 'contactsSort')],
        per_page: get(this, 'per'),
        page: get(this, 'contactsCurrentPage')
      };
      if (!get(this, 'contactsSort')) {
        delete query.sort_by;
      }

      return this.store.query('contact', query).then(function (contacts) {
        set(_this, 'contactsTotalPages', get(contacts, 'meta.total_pages'));
        return contacts;
      });
    },


    loadMoreContacts: (0, _emberConcurrency.task)(_emberConcurrency.drop, regeneratorRuntime.mark(function _callee() {
      var contacts, modelContacts;
      return regeneratorRuntime.wrap(function _callee$(_context) {
        while (1) {
          switch (_context.prev = _context.next) {
            case 0:
              set(this, 'controller.loadingMoreContacts', true);
              _context.next = 3;
              return this.requestMoreContacts();

            case 3:
              contacts = _context.sent;
              _context.next = 6;
              return get(this, 'modelContacts');

            case 6:
              modelContacts = _context.sent;

              modelContacts.pushObjects(contacts.get('content'));
              set(this, 'controller.loadingMoreContacts', false);
              set(this, 'controller.infinityContactsReached', !get(this, 'contactsCanLoadMore'));

            case 10:
            case 'end':
              return _context.stop();
          }
        }
      }, _callee, this);
    })),

    deleteContact: (0, _emberConcurrency.task)(_emberConcurrency.drop, regeneratorRuntime.mark(function _callee2(contact) {
      var modelContacts;
      return regeneratorRuntime.wrap(function _callee2$(_context2) {
        while (1) {
          switch (_context2.prev = _context2.next) {
            case 0:
              _context2.next = 2;
              return get(this, 'modelContacts');

            case 2:
              modelContacts = _context2.sent;

              modelContacts.removeObject(contact);
              _context2.next = 6;
              return contact.destroyRecord();

            case 6:
              this.refresh();

            case 7:
            case 'end':
              return _context2.stop();
          }
        }
      }, _callee2, this);
    })),

    hidePYMK: (0, _emberConcurrency.task)(_emberConcurrency.drop, regeneratorRuntime.mark(function _callee3(pymk) {
      var modelPYMK;
      return regeneratorRuntime.wrap(function _callee3$(_context3) {
        while (1) {
          switch (_context3.prev = _context3.next) {
            case 0:
              _context3.next = 2;
              return get(this, '_infinityModel');

            case 2:
              modelPYMK = _context3.sent;

              modelPYMK.call(this).removeObject(pymk);

            case 4:
            case 'end':
              return _context3.stop();
          }
        }
      }, _callee3, this);
    })),

    deletePYMK: (0, _emberConcurrency.task)(_emberConcurrency.drop, regeneratorRuntime.mark(function _callee4(suggestedContact) {
      var _get;

      var _args4 = arguments;
      return regeneratorRuntime.wrap(function _callee4$(_context4) {
        while (1) {
          switch (_context4.prev = _context4.next) {
            case 0:
              _context4.next = 2;
              return (_get = get(this, 'hidePYMK')).perform.apply(_get, _args4);

            case 2:
              _context4.next = 4;
              return suggestedContact.destroyRecord();

            case 4:
            case 'end':
              return _context4.stop();
          }
        }
      }, _callee4, this);
    })),

    hideContactRequest: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function _callee5(contactRequest) {
      var modelContactRequests;
      return regeneratorRuntime.wrap(function _callee5$(_context5) {
        while (1) {
          switch (_context5.prev = _context5.next) {
            case 0:
              _context5.next = 2;
              return get(this, 'modelContactRequests');

            case 2:
              modelContactRequests = _context5.sent;

              modelContactRequests.removeObject(contactRequest);

            case 4:
            case 'end':
              return _context5.stop();
          }
        }
      }, _callee5, this);
    })),

    setupController: function setupController(controller) {
      this._super.apply(this, arguments);
      set(controller, 'infinityContactsReached', false);
      set(controller, 'loadingMoreContacts', false);
      set(controller, 'contactsListCollapsed', false);
      set(controller, 'contactsListHasCollapsed', false);
    },


    actions: {
      loadMoreContacts: function loadMoreContacts() {
        if (get(this, 'contactsCanLoadMore')) {
          get(this, 'loadMoreContacts').perform();
        }
      },
      deleteContact: function deleteContact() {
        var _get2;

        (_get2 = get(this, 'deleteContact')).perform.apply(_get2, arguments);
      },
      hidePYMK: function hidePYMK() {
        var _get3;

        (_get3 = get(this, 'hidePYMK')).perform.apply(_get3, arguments);
      },
      deletePYMK: function deletePYMK() {
        var _get4;

        (_get4 = get(this, 'deletePYMK')).perform.apply(_get4, arguments);
      },
      hideContactRequest: function hideContactRequest() {
        var _get5;

        (_get5 = get(this, 'hideContactRequest')).perform.apply(_get5, arguments);
      },
      loading: function loading(transition) {
        var controller = this.controllerFor('contacts.index');
        controller.set('loadingFirstContacts', true);
        transition.promise.finally(function () {
          controller.set('loadingFirstContacts', false);
        });
      }
    }
  });
});