define('desktop-frontend/components/profile-card/component', ['exports', 'desktop-frontend/mixins/components/image-size', 'rp-common/utils/utils-base'], function (exports, _imageSize, _utilsBase) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var $ = Ember.$;
  var get = Ember.get;
  var computed = Ember.computed;
  exports.default = Component.extend(_imageSize.default, {
    classNames: ['profile-card', 'clearfix'],
    classNameBindings: ['imageSizeClass'],
    attributeBindings: ['autoId'],

    profile: null,
    image: true,
    linkToProfile: true,
    imageSize: 56,

    imageSizeClass: computed('imageSize', function () {
      var imgSize = this.get('imageSize');
      return $.isNumeric(imgSize) ? 'profile-image-size-' + imgSize : '';
    }),

    openProfile: function openProfile() {
      _utilsBase.default.browserRedirect(get(this, 'profile.profileLink'));
    },
    click: function click() {
      if (!this.get('linkToProfile')) {
        return;
      }

      this.openProfile();
    }
  });
});