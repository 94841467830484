define('desktop-frontend/components/invitation-social-connections/component', ['exports', 'desktop-frontend/mixins/contact-counter', 'desktop-frontend/mixins/social-connectedness', 'desktop-frontend/config/environment'], function (exports, _contactCounter, _socialConnectedness, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var not = Ember.computed.not;
  var Component = Ember.Component;
  var set = Ember.set;
  var get = Ember.get;
  exports.default = Component.extend(_contactCounter.default, _socialConnectedness.default, {
    torii: null, // Passed in from route

    continueDisabled: not('atLeastOneConnectedNetwork'),

    oauthName: function oauthName(provider) {
      return {
        google: 'google-oauth2',
        facebook: 'facebook-connect'
      }[provider];
    },
    afterConnect: function afterConnect(network, authData) {
      var _this = this;

      var token = authData.authorizationCode;
      var tokenName = void 0;
      var uid = void 0;
      var signedIn = get(this, 'currentSession.isSignedIn');
      var modelPath = signedIn ? 'user' : 'registration';
      switch (network) {
        case 'google':
          tokenName = signedIn ? 'has_gmail' : 'google_token';
          break;
        case 'facebook':
          token = authData.accessToken;
          uid = authData.userId;
          tokenName = signedIn ? 'has_facebook' : 'facebook_token';
          break;
      }
      set(this, modelPath + '.' + tokenName, token);

      var url = window.location.protocol + '//' + window.location.host + window.location.pathname;
      this.get('invitationContactChecker').queueAccountCheck(network, token, url, uid).then(function (authData) {
        if (!get(_this, 'currentSession.isSignedIn')) {
          if (authData.existing) {
            window.location.href = '/login';
          } else if (authData.loginData) {
            _this.get('registration').setProperties({
              email: authData.loginData.email,
              first_name: authData.loginData.first_name,
              last_name: authData.loginData.last_name
            });
          }
        }
      });
    },

    actions: {
      connect: function connect(provider) {
        var _this2 = this;

        var url = window.location.protocol + '//' + window.location.host + window.location.pathname;
        for (var name in _environment.default.torii.providers) {
          _environment.default.torii.providers[name].redirectUri = url;
        }
        this.get('torii').open(this.oauthName(provider)).then(function (auth) {
          return _this2.afterConnect(provider, auth);
        });
      }
    }
  });
});