define('desktop-frontend/answers/index/controller', ['exports', 'rp-common/components/google-ad/component'], function (exports, _component) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Controller = Ember.Controller;
  exports.default = Controller.extend({
    bannerAdSize: _component.BANNER_SLOT_SIZE
  });
});