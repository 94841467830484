define('desktop-frontend/components/connect-user-button/component', ['exports', 'rp-common/components/connect-user-button/component', 'ember-concurrency', 'rp-common/models/contact-request-ticket'], function (exports, _component, _emberConcurrency, _contactRequestTicket) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var set = Ember.set;
  var get = Ember.get;
  var service = Ember.inject.service;
  exports.default = _component.default.extend({
    showPaywall: false,

    store: service(),
    currentSession: service(),
    flashMessages: service(),

    authorizeConnectionRequestTask: (0, _emberConcurrency.task)(_emberConcurrency.drop, regeneratorRuntime.mark(function _callee() {
      var ticketRequest;
      return regeneratorRuntime.wrap(function _callee$(_context) {
        while (1) {
          switch (_context.prev = _context.next) {
            case 0:
              _context.prev = 0;
              _context.next = 3;
              return get(this, 'store').createRecord('contact-request-ticket', {
                sender: get(this, 'currentSession.profile'),
                receiver: get(this, 'profile')
              }).save();

            case 3:
              ticketRequest = _context.sent;

              set(this, 'showPaywall', !get(ticketRequest, _contactRequestTicket.APPROVED));
              set(this, 'connectUserOpen', true);
              _context.next = 12;
              break;

            case 8:
              _context.prev = 8;
              _context.t0 = _context['catch'](0);

              this.reportError(_context.t0);
              get(this, 'flashMessages').danger('An unknown error occurred. Please try again later.');

            case 12:
            case 'end':
              return _context.stop();
          }
        }
      }, _callee, this, [[0, 8]]);
    })),

    actions: {
      openConnectUserModal: function openConnectUserModal() {
        if (!get(this, 'requestSent')) {
          get(this, 'authorizeConnectionRequestTask').perform();
        }
      },
      closeConnectUserModal: function closeConnectUserModal() {
        set(this, 'connectUserOpen', false);
      },
      sentConnection: function sentConnection() {
        set(this, 'requestSent', true);
        set(this, 'connectUserOpen', false);
        this.sendAction('sentConnection');
      }
    }
  });
});