define('desktop-frontend/components/automatic-update-card/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var alias = Ember.computed.alias;
  var set = Ember.set;
  exports.default = Component.extend({
    classNames: ['automatic-update-card'],
    showThanks: false,
    owner: null, // profile the card is about
    cardId: alias('feedItem.hide_key'),

    actions: {
      goToThanks: function goToThanks() {
        set(this, 'showThanks', true);
      }
    }
  });
});