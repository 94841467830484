define('desktop-frontend/components/custom-list-feed/component', ['exports', 'desktop-frontend/mixins/components/ember-infinity', 'rp-common/mixins/infinite-scroll-analytics', 'rp-common/models/list-feed-content', 'rp-common/components/google-ad/component', 'rp-common/mixins/discussion-tracking-route'], function (exports, _emberInfinity, _infiniteScrollAnalytics, _listFeedContent, _component, _discussionTrackingRoute) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var service = Ember.inject.service;
  var not = Ember.computed.not;
  var Component = Ember.Component;
  var get = Ember.get;
  var set = Ember.set;
  var computed = Ember.computed;
  exports.default = Component.extend(_emberInfinity.default, _infiniteScrollAnalytics.default, _discussionTrackingRoute.FeedDiscussionTrackingMixin, {
    impressionLocation: null,
    newFeeds: null,
    islands: true,
    bannerAdSize: _component.BANNER_SLOT_SIZE,

    ui: service(),
    store: service(),
    serverMaintenance: service(),

    hasMoreItems: not('feeds.reachedInfinity'),

    feeds: computed('unfilteredFeeds.[]', function () {
      return this.get('unfilteredFeeds').filterBy('isNew', false);
    }),

    init: function init() {
      this._super.apply(this, arguments);
      set(this, 'newFeeds', []);
    },
    trackDiscussionImpressions: function trackDiscussionImpressions(results) {
      if (get(this, 'islands')) {
        this._super({ newObjects: results });
      }
    },


    actions: {
      statusUpdateCreated: function statusUpdateCreated(update) {
        var _this = this;

        var store = get(this, 'store');
        store.find('list-status-update', get(update, 'id')).then(function (list_status_update) {
          var listDiscussion = store.createRecord('list-discussion', {
            list_feed_content: store.createRecord('list-feed-status-update', {
              list_status_update: list_status_update,
              reason: _listFeedContent.OWN_POST_REASON
            })
          });
          get(_this, 'newFeeds').unshiftObject(listDiscussion);
        });
      },
      reloadApplication: function reloadApplication() {
        window.location.reload();
      },


      // since this component may or may not live inside another component (islands),
      // goToLink may or may not be available.
      _goToLink: function _goToLink() {
        if (this.attrs.goToLink) {
          this.attrs.goToLink();
        }
      }
    }
  });
});