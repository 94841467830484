define('desktop-frontend/email-invite/service', ['exports', 'ember-network/fetch'], function (exports, _fetch) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var $ = Ember.$;
  var RSVP = Ember.RSVP;
  var Service = Ember.Service;
  var service = Ember.inject.service;
  var get = Ember.get;


  var EMAIL_API = 'email_invite';

  exports.default = Service.extend({
    apiService: service(),

    invite: function invite(emails) {
      var apiService = get(this, 'apiService');
      var requestParams = apiService.postRequestSettings();
      requestParams.headers['Content-Type'] = 'application/x-www-form-urlencoded; charset=UTF-8';
      requestParams.headers['X-CSRF-Token'] = $('meta[name=csrf-token]').attr('content');
      requestParams.body = encodeURIComponent('invitation[email]') + '=' + encodeURIComponent(emails.join(','));

      return (0, _fetch.default)(apiService.getApiPath(EMAIL_API, 'api'), requestParams).then(function (response) {
        return new RSVP.Promise(function (resolve, reject) {
          return response.ok ? resolve(response.json()) : reject(response);
        });
      });
    }
  });
});