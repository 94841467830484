define('desktop-frontend/components/feed-author-name/component', ['exports', 'rp-common/components/feed-author-name/component'], function (exports, _component) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed;
  exports.default = _component.default.extend({
    tagName: 'a',
    attributeBindings: ['href', 'target'],
    listProfile: null,

    href: computed('listProfile.slug', function () {
      return '/profiles/' + this.get('listProfile.slug');
    })
  });
});