define('desktop-frontend/model-utils/util', ['exports', 'rp-common/utils/model-utils'], function (exports, _modelUtils) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'cleanProperty', {
    enumerable: true,
    get: function () {
      return _modelUtils.cleanProperty;
    }
  });
  Object.defineProperty(exports, 'cleanProperties', {
    enumerable: true,
    get: function () {
      return _modelUtils.cleanProperties;
    }
  });
  Object.defineProperty(exports, 'setAndCleanProperties', {
    enumerable: true,
    get: function () {
      return _modelUtils.setAndCleanProperties;
    }
  });
});