define('desktop-frontend/components/new-custom-discussion/component', ['exports', 'rp-common/mixins/mention-limit', 'rp-common/mixins/has-images', 'desktop-frontend/mixins/status-update-validator'], function (exports, _mentionLimit, _hasImages, _statusUpdateValidator) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var service = Ember.inject.service;
  var run = Ember.run;
  var set = Ember.set;
  var get = Ember.get;
  var computed = Ember.computed;
  var alias = Ember.computed.alias;
  var gt = Ember.computed.gt;
  var and = Ember.computed.and;
  var not = Ember.computed.not;
  exports.default = Component.extend(_mentionLimit.default, _hasImages.default, _statusUpdateValidator.default, {
    expanded: false,
    saving: false,
    uploading: false,

    store: service(),
    ui: service(),
    i18n: service(),

    hasImages: gt('statusUpdate.pictures.length', 0),
    statusValid: alias('validations.isValid'),
    postUpdateActive: and('statusValid', 'notSaving'),
    currentProfile: alias('currentSession.listProfile'),
    notSaving: not('saving'),
    pictures: alias('statusUpdate.pictures'),

    init: function init() {
      this._super.apply(this, arguments);
      var statusUpdate = { pictures: [], tags: [], body: '' };
      set(this, 'statusUpdate', statusUpdate);
      set(this, 'mentions', []);
    },
    removeEncodedMentions: function removeEncodedMentions() {
      if (!get(this, 'hasMentions')) {
        return;
      }

      var body = get(this, 'statusUpdate.body') || '';
      set(this, 'statusUpdate.body', body.replace('\n\n' + get(this, 'encodedMentions').join('\n'), ''));
    },


    loadingPicture: computed(function () {
      var store = get(this, 'store');
      return store.createRecord('picture', { url: 'assets/images/spinner-star-gray.gif' });
    }),

    actions: {
      onUploadStart: function onUploadStart() {
        this.set('uploading', true);
        this.get('pictures').pushObject(this.get('loadingPicture'));
      },
      onUploadFinished: function onUploadFinished() {
        this.set('uploading', false);
        this.get('pictures').removeObject(this.get('loadingPicture'));
      },
      toggleMention: function toggleMention() {
        var _this = this;

        // disgusting hack to insert an @ sign to trigger tagging... do not touch, remove if possible
        var bodyEle = this.$('.status-update-body').get(0);
        var body = this.get('statusUpdate.body');
        var idx = bodyEle.selectionStart || body.length;
        var spaceNeeded = body[idx - 2] !== ' ';

        if (body[idx - 1] !== '@') {
          set(this, 'statusUpdate.body', '' + body.substr(0, idx) + (spaceNeeded ? ' ' : '') + '@' + body.substr(idx));
        }

        run.scheduleOnce('afterRender', function () {
          bodyEle.focus();
          bodyEle.selectionStart = bodyEle.selectionEnd = idx + (spaceNeeded ? 2 : 1);
          _this.$('.status-update-body').trigger('mentions:toggle');
        });

        set(this, '_manualToggle', true);
      }
    }
  });
});