define('desktop-frontend/answers/controller', ['exports', 'rp-common/components/google-ad/component', 'rp-common/services/trackers/job', 'desktop-frontend/mixins/with-qlu-sidebar'], function (exports, _component, _job, _withQluSidebar) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.QP_ORDER = undefined;
  var Controller = Ember.Controller;
  var set = Ember.set;
  var alias = Ember.computed.alias;
  var QP_ORDER = exports.QP_ORDER = 'order';

  exports.default = Controller.extend(_withQluSidebar.default, {
    queryParams: [QP_ORDER],
    order: 'published_date',
    bottomAdSizes: _component.SIDEBAR_WITH_LONG_SLOT_SIZE,
    bannerAdSize: _component.BANNER_SLOT_SIZE,
    sidebarJobsListLocation: _job.JOB_SIDE_ANSWERS,

    sortOrder: alias('order'),

    actions: {
      changeSort: function changeSort(sort) {
        set(this, 'sortOrder', sort);
      }
    }
  });
});