define('desktop-frontend/components/user-promo/component', ['exports', 'desktop-frontend/components/base-suggest-sidebar/component', 'ember-data'], function (exports, _component, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var service = Ember.inject.service;
  var set = Ember.set;
  var get = Ember.get;
  var computed = Ember.computed;
  var alias = computed.alias;

  var RC_LEFT_ALIGN_TESTIMONIAL_TEXT = [];
  var RC_HIDE_USER_PROMO_ATTRIBUTES = ['wellness'];

  exports.default = _component.default.extend({
    classNames: ['container-card', 'user-promo'],
    classNameBindings: ['alignTestimonialTextLeft'],
    profileToDisplay: 0,
    testimonials: null,
    promoHeader: null,
    resourceCenter: null,
    promoText: 'promo text goes here',

    visibleTestimonialText: alias('currentTestimonial.text'),

    store: service(),
    i18n: service(),

    hideUserPromoAttributes: computed('resourceCenter', function () {
      return RC_HIDE_USER_PROMO_ATTRIBUTES.includes(get(this, 'resourceCenter'));
    }),

    alignTestimonialTextLeft: computed('resourceCenter', function () {
      return RC_LEFT_ALIGN_TESTIMONIAL_TEXT.includes(get(this, 'resourceCenter'));
    }),

    moduleHeader: computed('resourceCenter', function () {
      return get(this, 'i18n').t('resourceCenter.' + get(this, 'resourceCenter') + '.promoSubtitle');
    }),

    viewProfileButtonText: computed('resourceCenter', function () {
      return get(this, 'i18n').t('resourceCenter.' + get(this, 'resourceCenter') + '.viewProfileButtonText');
    }),

    nextProfileButtonText: computed('resourceCenter', function () {
      return get(this, 'i18n').t('resourceCenter.' + get(this, 'resourceCenter') + '.nextButtonText');
    }),

    currentTestimonial: computed('profileToDisplay', function () {
      var profileToDisplay = get(this, 'profileToDisplay');
      return get(this, 'testimonials').objectAt(profileToDisplay);
    }),

    visibleProfile: computed('profileToDisplay', function () {
      var _this = this;

      return _emberData.default.PromiseObject.create({
        promise: get(this, 'store').findRecord('list-profile', get(this, 'currentTestimonial.list_profile_id')).catch(function () {
          _this.reportError(new Error('User for resource center user promo was not found'));
        })
      });
    }),

    visibleProfileUrl: computed('visibleProfile.slug', function () {
      var profileSlug = get(this, 'visibleProfile.slug');
      return '/profiles/' + profileSlug;
    }),

    // for animation
    cloneFromElement: '.promo-content',

    actions: {
      nextProfile: function nextProfile() {
        var profileToDisplay = get(this, 'profileToDisplay');
        var testimonials = get(this, 'testimonials');
        if (profileToDisplay < get(testimonials, 'length') - 1) {
          this.incrementProperty('profileToDisplay');
        } else {
          set(this, 'profileToDisplay', 0);
        }
      },
      viewProfile: function viewProfile() {
        var id = get(this, 'visibleProfile.slug');
        window.location.href = '/profiles/' + id;
      },
      onAnimate: function onAnimate() {
        this.send('nextProfile');
      },
      nextButtonClicked: function nextButtonClicked() {
        this.send('swapBuffers');
      }
    }
  });
});