define('desktop-frontend/router', ['exports', 'desktop-frontend/config/environment', 'desktop-frontend/mixins/google-pageview', 'rp-common/mixins/pageview-tracking-route', 'desktop-frontend/mixins/control-center-manager'], function (exports, _environment, _googlePageview, _pageviewTrackingRoute, _controlCenterManager) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var EmberRouter = Ember.Router;


  var Router = void 0;
  if (_environment.default.APP.IS_ISLANDS) {
    Router = EmberRouter.extend({
      location: _environment.default.locationType,
      rootURL: _environment.default.rootURL
    });
  } else {
    Router = EmberRouter.extend(_googlePageview.default, _pageviewTrackingRoute.default, _controlCenterManager.default, {
      location: _environment.default.locationType,
      rootURL: _environment.default.rootURL
    });
  }

  var devMode = _environment.default.environment === 'development';

  Router.map(function () {
    this.route('not-found', { path: '/*path' });
    this.route('emergency-support', { path: 'emergency-support' });
    this.route('home', { path: '/home' }, function () {
      this.route('index', { path: '/' });
    });

    this.route('command-post', function () {
      this.route('loading');
    });

    this.route('resource-center', { path: '/topics/:topic' }, function () {
      this.route('loading');
    });

    this.route('offers', { path: '/offers' }, function () {
      this.route('index', { path: '/' });
    });

    this.route('contacts', function () {});

    this.route('catch-all', { path: '*path' });

    if (devMode) {
      this.route('login');
      this.route('logout');
    }

    this.route('answers', function () {
      this.route('index', { path: '/' });
    });
  });

  exports.default = Router;
});