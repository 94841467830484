define('desktop-frontend/components/question-wizard/details/component', ['exports', 'desktop-frontend/mixins/caret-adjustment', 'desktop-frontend/config/environment', 'rp-common/mixins/has-images', 'desktop-frontend/mixins/mention-limit', 'desktop-frontend/content-creation/service', 'ember-cp-validations'], function (exports, _caretAdjustment, _environment, _hasImages, _mentionLimit, _service, _emberCpValidations) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var filter = Ember.computed.filter;
  var mapBy = Ember.computed.mapBy;
  var equal = Ember.computed.equal;
  var gt = Ember.computed.gt;
  var alias = Ember.computed.alias;
  var service = Ember.inject.service;
  var run = Ember.run;
  var set = Ember.set;
  var get = Ember.get;
  var computed = Ember.computed;


  var MAX_TITLE_LENGTH = 141;
  var MIN_TITLE_LENGTH = 2;
  var MAX_TAG_AMOUNT = 5;
  var MIN_SURVEY_OPTION_LENGTH = 1;
  var MIN_VALID_SURVEY_OPTIONS = 2;
  var MAX_BODY_LENGTH = get(_environment.default, 'qrcContent.maxBodyLength');
  var MAX_PICTURES = 10;
  var ANONYMOUS_POST_ENABLED_PROFILE_IDS = get(_environment.default, 'APP.ANONYMOUS_POSTING.enabledProfileIds');

  var QuestionValidations = (0, _emberCpValidations.buildValidations)({
    trimmedTitle: {
      descriptionKey: 'inlineWizardValidations.titleValidationDescription', /* ember-i18n-cp-validations */
      validators: [(0, _emberCpValidations.validator)('presence', { presence: true, ignoreBlank: true }), (0, _emberCpValidations.validator)('length', { min: MIN_TITLE_LENGTH, max: MAX_TITLE_LENGTH }), (0, _emberCpValidations.validator)('format', { regex: /\?$/, messageKey: 'inlineWizardValidations.questionMarkValidationMessage' })]
    },

    firstTagName: {
      messageKey: 'inlineWizardValidations.topicValidationMessage', /* ember-i18n-cp-validations */
      validators: [(0, _emberCpValidations.validator)('presence', { presence: true, ignoreBlank: true })]
    },

    validSurveyOptions: {
      messageKey: 'inlineWizardValidations.surveyValidationMessage', /* ember-i18n-cp-validations */
      validators: [(0, _emberCpValidations.validator)('length', { min: MIN_VALID_SURVEY_OPTIONS, disabled: equal('model.survey', null) })]
    }
  });

  exports.default = Component.extend(QuestionValidations, _hasImages.default, _mentionLimit.default, _caretAdjustment.default, {
    classNames: ['question-wizard'],
    saving: false,
    survey: null,
    questionMentions: '@',
    titleLengthLimit: MAX_TITLE_LENGTH,
    bodyLengthLimit: MAX_BODY_LENGTH,
    maxTagAmount: MAX_TAG_AMOUNT,
    question: null,
    showErrors: false,
    showSurvey: false,
    maxPictures: MAX_PICTURES,

    store: service(),
    currentSession: service(),
    i18n: service(),
    contentCreation: service(),

    pictures: alias('question.pictures'),
    tags: alias('question.tags'),
    showTagDisplay: gt('question.tags.length', 0),
    hasPictures: gt('pictures.length', 0),
    firstValidationError: alias('validations.messages.firstObject'),

    init: function init() {
      this._super.apply(this, arguments);
      this.initQuestion();
    },
    initQuestion: function initQuestion() {
      var question = get(this, 'store').createRecord('question');
      set(this, 'question', question);
    },


    firstTagName: computed('tags.firstObject.name', function () {
      return get(this, 'tags.firstObject.name');
    }),

    // ember-cp-validations

    trimmedTitle: computed('question.title', function () {
      var title = get(this, 'question.title') || '';
      return title.trim();
    }),

    validSurveyOptions: filter('optionLengths', function (length /* , index, options */) {
      return length >= MIN_SURVEY_OPTION_LENGTH;
    }),
    optionLengths: mapBy('survey.survey_options', 'name.length'),

    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      this.focusTitle();
      this.onFocusTitle = this.focusTitle.bind(this);
      get(this, 'contentCreation').on(_service.CONTENT_CREATION_EVENTS.CREATE_QUESTION, this, this.onFocusTitle);
    },
    willDestroyElement: function willDestroyElement() {
      get(this, 'contentCreation').off(_service.CONTENT_CREATION_EVENTS.CREATE_QUESTION, this, this.onFocusTitle);
      this.onFocusTitle = null;
      this._super.apply(this, arguments);
    },


    allowAnonymousPost: computed('currentUser', function () {
      var profileId = Number(this.get('currentSession.profile.id'));
      if (ANONYMOUS_POST_ENABLED_PROFILE_IDS.includes(profileId)) {
        return true;
      }

      var isVerified = this.get('currentSession.listProfile.verified');
      var userType = this.get('currentSession.listProfile.user_type').toLowerCase();
      var isServiceMember = userType === 'servicemember';

      return profileId % 10 === 1 && isVerified && isServiceMember;
    }),

    focusTitle: function focusTitle() {
      this.$('.question-title').focus();
    },


    loadingPicture: computed(function () {
      var store = get(this, 'store');
      return store.createRecord('picture', { url: 'assets/images/spinner-star-gray.gif' });
    }),

    mentionBody: computed('question.body', 'questionMentions', function () {
      return get(this, 'question.body') + '\n\n' + get(this, 'questionMentions');
    }),

    resetState: function resetState() {
      this.initQuestion();
      set(this, 'survey', null);
      set(this, 'showSurvey', false);
      set(this, 'questionMentions', '@');
      set(this, 'question.body', '');
      set(this, 'showErrors', false);
    },


    actions: {
      postQuestion: function postQuestion() {
        var _this = this;

        if (!get(this, 'validations.isValid')) {
          set(this, 'showErrors', true);
          return;
        }

        set(this, 'saving', true);

        var survey = get(this, 'survey');
        if (survey) {
          set(this, 'question.survey', survey);
        }

        var body = get(this, 'question.body') || '';
        var questionMentions = get(this, 'questionMentions');
        if (questionMentions) {
          var cleanMentions = this.parseMentions(questionMentions) || [];
          body = body + ' \n\n ' + cleanMentions.join(' ');
        }
        set(this, 'question.body', body);

        this.get('question').save().then(function (question) {
          return _this.get('store').find('list-question', question.get('id'));
        }).then(function (question) {
          _this.sendAction('questionCreated', question);
          _this.resetState();
        }).catch(function () {
          _this.get('flashMessages').danger(get(_this, 'i18n').t('newQuestionFlash.error'));
        }).finally(function () {
          set(_this, 'saving', false);
        });
      },
      addTag: function addTag(tag) {
        var _this2 = this;

        var edTag = get(this, 'store').createRecord('tag', {
          name: tag.value,
          imagePath: tag.image_path,
          group_id: tag.id
        });

        get(this, 'question.tags').pushObject(edTag);
        run.next(function () {
          _this2.$('.tag-search-input').trigger('focus');
        });
      },
      addImage: function addImage() {
        this.$('.photo-upload-container input[type=file]').trigger('click');
      },
      onUploadStart: function onUploadStart() {
        set(this, 'uploading', true);
        var loading = get(this, 'loadingPicture');
        get(this, 'pictures').pushObject(loading);
      },
      onUploadFinished: function onUploadFinished() {
        set(this, 'uploading', false);
        get(this, 'pictures').removeObject(get(this, 'loadingPicture'));
        set(this, 'pictures', get(this, 'pictures').slice(0, MAX_PICTURES));
      },
      toggleSurvey: function toggleSurvey() {
        if (!get(this, 'showSurvey')) {
          var store = get(this, 'store');
          var survey = store.createRecord('survey');
          var optionOne = store.createRecord('survey-option');
          var optionTwo = store.createRecord('survey-option');
          get(survey, 'survey_options').pushObjects([optionOne, optionTwo]);
          set(this, 'survey', survey);
          set(this, 'showSurvey', true);
        } else {
          get(this, 'survey').unloadRecord();
          set(this, 'survey', null);
          set(this, 'showSurvey', false);
        }
      },
      hidingMention: function hidingMention() {
        if (!get(this, '_manualToggle')) {
          return;
        }

        set(this, '_manualToggle', false);

        var $body = this.$('.question-body');
        var body = get(this, 'question.body');
        var idx = $body.get(0).selectionStart || body.length;

        if (body[idx - 1] === '@') {
          set(this, 'question.body', '' + body.substr(0, idx - 1) + body.substr(idx));
        }
      }
    }
  });
});