define('desktop-frontend/instance-initializers/set-up-local-storage', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.initialize = initialize;

  var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) {
    return typeof obj;
  } : function (obj) {
    return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
  };

  function initialize(instance) {
    mockIfNeeded();
    hydrate(instance);
  }

  function mockIfNeeded() {
    // Safari, in Private Browsing Mode, looks like it supports localStorage but all calls to setItem
    // throw QuotaExceededError. We're going to detect this and just silently drop any calls to setItem
    // to avoid the entire page breaking, without having to do a check at each usage of Storage.

    // http://stackoverflow.com/questions/14555347/html5-localstorage-error-with-safari-quota-exceeded-err-dom-exception-22-an
    if ((typeof localStorage === 'undefined' ? 'undefined' : _typeof(localStorage)) === 'object') {
      try {
        localStorage.setItem('localStorage', 1);
        localStorage.removeItem('localStorage');
      } catch (e) {
        /* jshint ignore:start */
        Storage.prototype._setItem = Storage.prototype.setItem;
        Storage.prototype.setItem = function () {};
        /* jshint ignore:end */
      }
    }
  }

  function hydrate(instance) {
    var localStore = instance.lookup('service:local-storage');
    localStore.hydrate();
  }

  exports.default = {
    name: 'set-up-local-storage',
    initialize: initialize
  };
});