define('desktop-frontend/components/profile-mention/component', ['exports', 'rp-common/components/profile-mention/component', 'rp-common/mixins/mention-limit'], function (exports, _component, _mentionLimit) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  var copy = Ember.copy;
  var get = Ember.get;
  var computed = Ember.computed;
  var $ = Ember.$;
  exports.default = _component.default.extend(_mentionLimit.default, {
    mentionCount: 0,
    maxMentions: _mentionLimit.MAX_MENTIONS,
    otherMentionsInput: null,

    mentionsRemaining: computed('mentionCount', 'maxMentions', function () {
      return get(this, 'mentionCount') < get(this, 'maxMentions');
    }),

    fireAutoComplete: function fireAutoComplete(str, tokenInfo) {
      if (!get(this, 'mentionsRemaining')) {
        this.onAutocompleteResults({ data: { has_profile_results: false } }, tokenInfo);
        return;
      }

      this._super.apply(this, arguments);
    },
    onAutocompleteResults: function onAutocompleteResults(json, tokenInfo) {
      // Don't affect the cache
      json = copy(json, true);
      var otherMentionsInput = $(get(this, 'otherMentionsInput'));
      var mentions = get(this, 'inputField').val().match(_mentionLimit.TAGGED_RE) || [];
      if (otherMentionsInput.length > 0) {
        var otherMentions = otherMentionsInput.val().match(_mentionLimit.TAGGED_RE) || [];
        mentions = mentions.concat(otherMentions);
      }
      var mentionIds = mentions.map(function (m) {
        return m.length > 0 && m.match(/\[~(\d+):[^\]]+]/)[1];
      });

      if (json.data.has_profile_results) {
        json.data.profile_results = json.data.profile_results.filter(function (profile) {
          var _profile$id$match = profile.id.match(/(\d+)-/),
              _profile$id$match2 = _slicedToArray(_profile$id$match, 2),
              profileId = _profile$id$match2[1];

          return !mentionIds.any(function (id) {
            return parseInt(profileId, 10) === parseInt(id, 10);
          });
        });
      }

      return this._super(json, tokenInfo);
    }
  });
});