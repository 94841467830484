define('desktop-frontend/mixins/contact-counter', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var service = Ember.inject.service;
  var Mixin = Ember.Mixin;
  var alias = Ember.computed.alias;
  exports.default = Mixin.create({
    invitationContactChecker: service(),
    contactCount: alias('invitationContactChecker.contactCount'),
    totalFound: alias('invitationContactChecker.totalFound'),
    updatingCount: alias('invitationContactChecker.updatingCount')
  });
});