define('desktop-frontend/components/feed-card/component', ['exports', 'rp-common/utils/utils-base'], function (exports, _utilsBase) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var service = Ember.inject.service;
  var Component = Ember.Component;
  var set = Ember.set;
  var get = Ember.get;
  var computed = Ember.computed;
  var tryInvoke = Ember.tryInvoke;
  var readOnly = computed.readOnly,
      or = computed.or,
      gt = computed.gt;
  exports.default = Component.extend({
    classNameBindings: ['sponsored'],
    feedItem: null,
    views: 0,
    commandPost: false,
    headerLoading: false,
    showReason: false,
    sponsored: false,
    externalSponsored: false,
    civilianCompany: false,
    moreMenuVisible: false,
    fullModel: null,
    reasonProfile: null,
    listFeed: null,
    useXIconMenu: false,

    resourceCenters: readOnly('feedItem.resource_centers'),

    routing: service(),
    environment: service(),

    sponsoredPost: or('sponsored', 'externalSponsored'),
    inResourceCenter: gt('resourceCenters.length', 0),
    resourceCenterName: computed('inResourceCenter', 'resourceCenters', function () {
      var inResourceCenter = get(this, 'inResourceCenter');
      var centersArray = get(this, 'resourceCenters');
      if (inResourceCenter) {
        return centersArray[0];
      }
    }),

    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
    },


    actions: {
      goToResourceCenter: function goToResourceCenter() {
        if (get(this, 'environment.isIsland')) {
          _utilsBase.default.browserRedirect('/topics/' + get(this, 'resourceCenterName'));
        } else {
          get(this, 'routing').transitionTo('resource-center', get(this, 'resourceCenterName'));
        }
      },
      goToAuthor: function goToAuthor() {
        tryInvoke(this, 'goToAuthor');
      },
      closeMenu: function closeMenu() {
        set(this, 'moreMenuVisible', false);
      },
      toggleMenu: function toggleMenu() {
        this.toggleProperty('moreMenuVisible');
      }
    }
  });
});