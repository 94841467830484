define('desktop-frontend/mixins/serializers/tags', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Mixin = Ember.Mixin;
  var isArray = Ember.isArray;
  exports.default = Mixin.create({
    serializeHasMany: function serializeHasMany(snapshot, json, relationship) {
      this._super.apply(this, arguments);
      if (relationship.key === 'tags' && isArray(snapshot.hasMany('tags'))) {
        json.explicit_group_ids = snapshot.hasMany('tags').filter(function (t) {
          return t.attr('group_id');
        }).map(function (t) {
          return t.attr('group_id');
        });
        json.topics_list = snapshot.hasMany('tags').filter(function (t) {
          return !t.attr('group_id');
        }).map(function (t) {
          return t.attr('name');
        }).join(',');
      }
    }
  });
});