define('desktop-frontend/locales/en/translations', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    general: {
      at: 'at',
      clickToSee: 'Click to see.',
      terms: 'Terms of Service',
      privacyPolicy: 'Privacy Policy',
      yes: 'yes',
      no: 'no',
      youAgree: 'By continuing, you agree to our ',
      youRead: ' and that you have read our ',
      back: 'Back',
      save: 'Save',
      cancel: 'Cancel',
      name: 'name',
      next: 'Next',
      email: 'email',
      message: 'message',
      send: 'send',
      sent: 'sent',
      or: 'or',
      copy: 'copy',
      copied: 'copied',
      recruiter: 'recruiter',
      salute: 'Salute',
      rpMail: 'RPMail',
      contactUs: 'Contact Us',
      errors: {
        unknown: 'An error occurred. Please try again later.'
      }
    },
    nocklabs: {
      nextBtnTitle: 'Next',
      notLikelyBtnTitle: 'Not likely',
      highlyLikelyBtnTitle: 'Highly likely',
      endBtnTitle: 'End',
      doneReading: 'Done reading? Click “Next” to proceed.',
      noBtn: 'No',
      yesBtn: 'Yes',
      closeBtn: 'Close',
      materialClose: '<i class="material-icons">close</i>',
      outreachBarrier: {
        stepOneTitle: 'Sounds like you’re going through something difficult. We care about you and want to help. Click here to learn more about some resources available to you.',
        stepTwoTitle: 'It sounds like you might benefit from support. If you’re in immediate danger and are not sure if you can keep yourself safe, please call 911. Otherwise, we recommend you call the Veteran’s Crisis Line (988, press 1 or text 838255) so that you can talk with a trained professional.',
        stepThreeTitle: 'How likely are you to use these resources?',
        stepFourTitle: '<b>We’re glad to hear it.</b><br /><p>Professionals are standing by 24/7 to help.</p>',
        modal: {
          title: 'Thanks for letting us know. We asked other servicemembers and Veterans about using these kinds of resources and some concerns came up. Can you please let us know which of these concerns speak to you?',
          question1: 'I can handle my own problems.',
          question2: 'Treatment is too expensive.',
          question3: 'I don’t trust mental health professionals.',
          question4: 'I\'m concerned about the police or paramedics getting involved.',
          question5: 'I\'m concerned about getting my personal firearm(s) taken away.',
          question6: 'I’d prefer to talk to someone in the military community.',
          question7: 'I’ve tried treatment or these resources before and they didn’t help.',
          question8: 'It might harm my career or my ability to do my job (e.g., loss of security clearance, command making me non-deployable)',
          question9: 'We’re not supposed to talk about our problems (e.g., leadership told us not to say anything, or peers make it clear we aren’t supposed to talk about it)',
          question10: 'None of these are relevant to me',
          showMore1: 'Talking about complex feelings and experiences has been shown to help us make sense of them. Some people might feel embarrassed or ashamed when asking for help, but it is a badge of strength that you are able to put pride aside and get support.',
          showMore2: 'Free resources like the Veteran’s Crisis Line are a great option if you’re concerned about cost. Most Veterans can also get free support through the VA.',
          showMore3: 'Mental health treatment can be very effective for treating a variety of problems, but it requires you to take a first step and be willing to speak to someone. Just like any relationship, you have control over how much you share, and how quickly you open up, so that you can build trust with a provider over time at your pace.',
          showMore4: 'If you call 911 directly, the police/paramedics will show up in order to provide life-saving care. But, if you\'re calling the Veteran\'s Crisis Line, it is very unlikely that this will result in police or paramedics showing up. For most crisis centers, this happens less than 1% of the time!',
          showMore5: 'Temporary removal of firearms, usually with a trusted friend or family member, is one way we can help keep people safe if they\'re thinking about hurting themselves. In some cases, this life-saving approach may be suggested to you and your provider will work with you to come up with a storage plan, but it does not mean your guns will be taken away forever.',
          showMore6: 'Many of the volunteers with the Veteran\'s Crisis Line are Veterans themselves. The same can be said about providers in the VA.',
          showMore7: 'Effective mental health care looks different for everyone. It’s possible you just haven’t found the right treatment yet. Sometimes people have to try out multiple therapists to find one that works. And, if you have already reached out to the Veteran’s Crisis Line and didn’t have a great experience, we encourage you to try again -- there are thousands of volunteers and hopefully this time will feel more helpful for you.',
          showMore8: 'We understand your concern. If you\'re concerned about career impact, one option is to seek professional help outside of the military or to use a resource that can be anonymous like the Veteran\'s Crisis Line. But, even if you use professional help in the military, most of the things you talk about with a therapist would be protected by confidentiality laws.',
          showMore9: 'Mental health is not a "one size fits all" issue. It might feel scary at first, but some people find that talking to a trained professional can really help them, even if some leaders or peers disagree.',
          nextStepMessage1: 'Remember, if you\'re in immediate physical danger and are not sure if you can keep yourself safe, please call 911.',
          nextStepMessage2: 'If you are looking for support and someone to talk you, you can always call 988 (press option 1) or text 838255',
          nextStepMessage3: 'We also recommend reaching out to your therapist if you have one. If you don’t, check out <a href="https://www.veteranscrisisline.net/get-help/local-resources" target="_blank"><u><b>local VA resources here</b></u></a>.'
        }
      },
      peerSupport: {
        title: 'This person might be going through something difficult. To learn more about what to say to peers who maybe in distress, <u>click this banner.</u>',
        modal: {
          title: '<i class="material-icons">handshake</i><p><em>Thank you for deciding to learn more about this topic</em></p><p><b>Now it\'s time to</b></p><p><h1 style="color: red">ACT</h1></p>',
          body1: '<span class="act-abbr">Acknowledge</span> <b>what your peer is going through, validating their thoughts and feelings.</b><i class="act-details">It can be difficult for someone to talk about their mental health, so we want to let them know we hear where they\'re coming from ("It sounds like you\'re really going through a rough time. I\'m sorry things are so difficult")</i>',
          body2: '<span class="act-abbr">Care</span> <b>about what your peer is telling you, listening without judgment.</b><i class="act-details">You can help other people even if you haven\'t been through the same thing. Offer to help and ask them what they need ("How can I help? I\'m here to listen if you want to tell me more")</i>',
          body3: '<span class="act-abbr">Tell</span> <b>your peer about treatment options.</b><i class="act-details">Or suggest steps they can take ("I\'ve felt this way too, and calling the Veteran\'s Crisis Line (988) was really helpful")</i>',
          body4: '<b>Try to avoid:</b><p>Going into detail about suicide methods or your own history, which can be distressing for some people.</p>'
        }
      },
      stigma: {
        header: "<strong>Hi there.</strong> We're trying to get to know our RallyPoint members better. Do you have 5 mins to answer a few questions?",
        declined: 'Thank you for letting us know, We’ll try you again next time',
        briMenu: {
          header1: '<strong>Thanks for taking the time to answer some questions.</strong> We are trying to learn about how we can help RallyPoint members who may be struggling.',
          header2: 'If you were struggling, what would prevent you from talking to your brothers and sisters in arms about it? Please select from the options below to learn more.',
          1: '1. Nothing; I would share (or already haved shared) my experiences on RallyPoint.',
          2: '2. I can handle my own problems.',
          3: '3. It might harm my career or my ability to do my job (e.g., loss of security clearance, command making me non-deployable)',
          4: "4. We're not supposed to talk about our problems (e.g., leadership told us not to say anything, or peers make it clear we aren't supposed to talk about it)",
          5: "5. I'm worried that talking about my problems will make myself or other people feel worse.",
          6: "6. It's not anyone else's business.",
          7: '7. None of these are relevant to me',
          t1: 'We’re glad you have felt comfortable opening up on RallyPoint. Feel free to click on any of the other items to learn more information. Otherwise, navigate to the bottom of this page and hit “Next.”',
          t2: 'We believe in taking care of our own, and your battle buddies are there to help shoulder the burden. Research shows that online responses to posts sharing personal struggles are usually kind, supportive, and productive, especially in specialized support communities like RallyPoint.',
          t3: 'We understand those concerns. You can post anonymously on RallyPoint if that would help you feel safer. Many other RallyPoint users have found it helpful to share on the site to connect with their peers, and they are standing by to do the same for you.',
          t4: 'Mental health is not a “one size fits all” issue. Reflecting on your experiences, and getting feedback from your peers, can help you navigate complex feelings and experiences, like it has for many of your battle buddies right here on RallyPoint.',
          t5: 'Research has shown that talking to trusted peers about one’s problems can help everyone involved feel better. You will get things off your chest and your peers will feel glad they got to help you -- it’s a win-win.',
          t6: 'You don’t have to go at this alone. Many of your peers have been where you are now and are happily and safely on the other side of it. They are likely to have useful suggestions, resources, and ideas to share with you. They may even know about resources you might not have heard about before.',
          t7: '',
          moreInfo: "You don't have to go at this alone. Many of your peers have been where you are now and are happily and safely on the other side of it. They are likely to have useful suggestions, resources, and ideas to share with you. They may even know about resources you might not have heard about before.",
          doneReading: '<strong>Done reading?</strong> Click "Next" to proceed.',
          answered: 'Thank you!'
        }
      },
      confirmModal: {
        completed: 'Thank you for taking the time answering our questions.',
        retry: 'Thank you for letting us know. We\'ll try you again another time.'
      }
    },
    newGroupDiscussion: {
      placeholders: {
        update: 'What would you like to share with the group?'
      },
      labels: {
        post: 'Post Update'
      }
    },
    profileFeed: {
      newProfileDiscussion: {
        placeholders: {
          update: 'What would you like to share with {{fullNameAndRank}}?'
        },
        labels: {
          post: 'Post Update'
        }
      },
      noRecentActivity: 'No recent activity by this member'
    },
    sponsoredPostHeader: {
      title: 'Sponsored'
    },
    headerNotifications: {
      title: 'Your Points and Notifications'
    },
    feedsList: {
      permissionModal: {
        title: "I see you're new here...",
        message: 'Sorry, you must have an Influence Score of at least 150 to start a discussion. Try responding to some questions first to earn points.'
      }
    },
    tagSearcher: {
      maxTags: 'Maximum tags reached.',
      header: 'Topics',
      searchPlaceholder: 'What is this post specifically about?',
      loading: 'Loading...',
      noResults: 'No results found',
      newTopic: 'Add new topic'
    },
    qrcImageUpload: {
      error: 'Image upload failed',
      errorTitle: 'Error'
    },
    newQuestionFlash: {
      success: 'Question successfully posted! Thank you for contributing.',
      error: 'Sorry, something went wrong. Please try again later.'
    },
    profileUpdate: {
      success: 'Profile updated',
      error: 'Sorry, something went wrong. Please try again later.'
    },
    unfollowGroup: 'Successfully unfollowed group!',
    votingNativeAlertMessage: '{{err}}',
    votingNativeAlertTitle: 'Voting error',
    votingFlash: {
      unvote: 'Vote removed and points have been reset.',
      voteUp: 'Vote successful! Thank you for contributing.',
      voteDown: 'Successfully down voted!',
      error: 'Sorry, something went wrong. Please try again later.'
    },
    responseFlash: {
      success: 'Response successfully posted! Thanks for contributing.',
      error: 'Sorry, something went wrong. Please try again later.'
    },
    commentFlash: {
      success: 'Comment successfully posted! Thanks for contributing.',
      error: 'Sorry, something went wrong. Please try again later.'
    },
    blocking: {
      universal: 'Sorry, interactions with this member are currently disabled. Please enjoy the rest of the conversations from the community.'
    },
    accessLimit: {
      unusual: 'RallyPoint has detected unusual activity. Our team has been notified and will investigate right away!',
      limit: 'You have reached your limit of usage for an unverified account.',
      mayEmail: 'You may email us at',
      ifQuestions: 'if you have any questions. Thanks!',
      verify: 'Verify',
      contactUs: 'Contact Us',
      refresh: 'Refresh'
    },
    notifications: {
      notifications: 'Notifications',
      noNotifications: 'No Notifications',
      participate: 'Start participating in discussions to receive notifications and improve your Influence Score!',
      newProfileCreatedTitle: 'Welcome {{rank}} {{name}}!',
      newProfileCreatedBody: 'Let\'s get your RallyPoint profile squared away.'
    },
    feeds: {
      successResponse: 'Response successfully posted!',
      successComment: 'Comment successfully posted!',
      seeComment: 'See comment',
      seeResponse: 'See response'
    },
    errors: {
      downVotesPerHour: 'You can only downvote 3 times per hour.'
    },
    warnings: {
      notLoggedIn: 'Please log in or create an account to have your vote count'
    },
    alerts: {
      civilian: {
        noPermitQRCActions: {
          body: 'If you would like to upgrade your account to be able to connect with military members and veterans, please contact RallyPoint for more information.',
          title: 'Civilian Error',
          voteTitle: 'Vote Error'
        }
      }
    },
    confirmations: {
      titles: {
        downVote: 'Are you sure?'
      },
      bodies: {
        downVote: 'Please confirm you want to down vote. Note that it is customary to also add a comment as to the reason you have down voted.'
      },
      cancelButton: {
        downVote: 'Cancel'
      },
      confirmButton: {
        downVote: 'Confirm'
      }
    },
    sharing: {
      subject: 'RallyPoint Discussion:',
      interest: 'Thought you might be interested in this discussion on RallyPoint:',
      latest: 'Latest on RallyPoint:'
    },
    placeholders: {
      answers: {
        respond: 'Add a comment',
        comment: 'Add a comment'
      }
    },
    home: {
      myDiscussions: 'My Discussions',
      allDiscussions: 'All Discussions',
      group: {
        followers: {
          one: '1 follower',
          other: '{{count}} followers',
          max: '5,000+ followers'
        }
      }
    },
    groups: {
      tabs: {
        answers: 'Answers',
        members: 'People'
      },
      members: {
        noMembers: 'No members listed yet. Do you know somebody who should be here?',
        groupMember: 'You are a member of this group.',
        invite: 'Invite them',
        types: {
          servicemembers: 'Servicemembers',
          veterans: 'Veterans',
          civilians: 'Civilians',
          current: 'Current',
          past: 'Past',
          future: 'Future',
          primary: 'Primary Specialty',
          additional: 'Additional Specialty'
        }
      },
      followButton: {
        whileFollowing: 'Following this group',
        whileNotFollowing: 'Follow this group',
        follow: 'Follow',
        followed: 'Followed'
      }
    },
    answers: {
      noResults: 'No results found',
      unfollow: {
        success: 'Unfollowed "{{questionTitle}}"',
        fail: 'Failed to unfollow "{{questionTitle}}"'
      },
      follow: {
        success: 'Followed "{{questionTitle}}"',
        fail: 'Failed to follow "{{questionTitle}}"'
      },
      reputation: {
        errorHeading: "I see you're new here..."
      },
      noDiscussions: 'No discussions in this group yet.',
      createOne: 'Create one',
      exploreMore: 'Explore more discussions',
      newQuestionsCount: {
        one: '1 new question.',
        other: '{{count}} new questions.'
      },
      list: {
        commandPost: 'Command Post',
        sponsoredPost: 'Sponsored',
        views: '{{count}} views',
        comments: '{{count}} comments',
        votes: '{{count}} votes',
        whatIsThis: 'What is this?',
        notFound: 'No discussions meet your search criteria.',
        upvote: 'Vote Up',
        respond: 'Respond'
      },
      response: {
        newResponses: {
          one: '1 new response.',
          other: '{{count}} new responses.'
        }
      },
      create: {
        labels: {
          category: 'Select a Category',
          questionTitle: 'Your Question/Discussion',
          description: 'More Details',
          surveyOptions: 'Survey Options',
          tags: 'Tag by Topics'
        },
        actions: {
          addSurvey: 'Add Survey Option',
          addTag: 'Add'
        },
        messages: {
          tooManyTags: 'Easy there. {{maxTags}} max!',
          defaultPlaceholder: 'Type tag and press add'
        }
      },
      comments: {
        responseTitle: 'Response',
        commentsTitle: 'Comments',
        newComments: {
          one: '1 new comment.',
          other: '{{count}} new comments.'
        }
      },
      show: {
        doesNotExist: 'Answer "{{id}}" was either removed or does not exist',
        unseenTags: '+{{count}}',
        duplicate: 'This discussion is a duplicate and the contents have been merged with the original discussion.  You can go to the active discussion here:',
        gotoOriginal: 'Go to discussion &gt;'
      },
      wizard: {
        cancel: ' Back',
        chooseCategory: {
          category: 'Choose a category...',
          title: 'Ask Question'
        },
        search: {
          continueButton: 'My Question is New',
          placeholders: {
            title: 'Type your question here. Please be specific.'
          },
          errors: {
            titleLengthShort: 'Question is too short.  Please add more details.'
          }
        },
        details: {
          askQuestion: 'Ask Question',
          confirmToggle: {
            message: 'Your Question will be changed to a Survey',
            title: 'Change Question Type?',
            ok: 'Continue',
            cancel: 'Cancel'
          },
          help: {
            guidelines: 'Answers Guidelines',
            gotIt: 'Ok, got it',
            good: {
              title: 'Good Questions:',
              'military-discussions': {
                sample1: 'How do I best prepare for recruiting duty?',
                sample2: 'Should physical fitness tests be gender neutral?',
                sample3: 'What is your best advice for a new E-5?'
              },
              'employment-transition': {
                sample1: 'How do you respond to a job interview question asking about deployment experience?',
                sample2: 'What is it like to work in the tech industry?',
                sample3: 'How long does it take to get a response from USAJOB?'
              },
              'post-military-life': {
                sample1: 'How do I change my VA disability rating?',
                sample2: 'Where can I obtain a DD 214 member copy 4?',
                sample3: 'Has your prior military service ever come across as negative to a civilian?'
              },
              'general-interest': {
                sample1: 'Do you believe the US should get military involved with Ukraine (2015)?',
                sample2: 'What is your favorite book on leadership?',
                sample3: 'What do you think should happen to those who violate Stolen Valor?'
              }
            },
            bad: {
              title: 'Bad Questions:',
              'military-discussions': {
                sample1: 'Recruiting duty',
                sample2: 'Fitness tests',
                sample3: 'I need advice'
              },
              'employment-transition': {
                sample1: 'Job interview question',
                sample2: 'Tech jobs?',
                sample3: 'Any luck with USAJOBS?'
              },
              'post-military-life': {
                sample1: 'VA rating',
                sample2: 'DD 214 question',
                sample3: 'Speaking with civilians'
              },
              'general-interest': {
                sample1: 'What would you do?',
                sample2: 'Favorite book?',
                sample3: 'Stolen Valor'
              }
            }
          },
          labels: {
            addOption: 'Add Option',
            details: 'Details',
            detailsOptional: '(optional)',
            general: 'General',
            mentions: 'Mentioned',
            noMoreOptions: 'The maximum number of survey options have been added.',
            options: 'Options',
            pictures: 'Photos',
            survey: 'Survey',
            tags: 'Tagged',
            tagIntro: 'Final Step: Please add tags to your question to help more members discover your content.'
          },
          placeholders: {
            body: 'Hint: Add a photo to get more views and votes',
            mentions: 'Search profiles...',
            profiles: 'Profiles',
            surveyOption: 'Required',
            tags: 'Search all groups and interests...',
            topics: 'Topics'
          }
        }
      }
    },
    registrations: {
      invite: {
        letsGo: 'I\'m ready.  Let\'s go!'
      },
      step0: {
        provideCivilian: 'Please Provide a Civilian Email',
        unlockWith: 'Unlock now with:',
        signUpTitle: 'See what 700k of your<br>military friends are doing now',
        joinUserTitle: 'Welcome to RallyPoint!<br>Join {{userName}}',
        withFacebook: 'Unlock with Facebook',
        withGoogle: 'Unlock with Google',
        withEmail: 'Sign up with email',
        haveAccount: 'Already Have an Account?',
        continueWith: 'Continue with'
      }
    },
    sideNavigation: {
      home: 'Home',
      answers: 'Answers',
      updates: 'Updates',
      findFriends: 'Find Friends',
      contacts: 'My Contacts',
      leaderboard: 'Leaderboard',
      jobs: 'Jobs and Education',
      share: 'Share',
      verify: 'Verify',
      singin: 'Sign In',
      signup: 'Sign Up',
      about: 'About Us',
      faq: 'FAQ',
      feedback: 'Feedback'
    },
    careers: {
      title: 'Grow Your Career <br/> on RallyPoint',
      discover: 'Discover Military Assignments',
      connect: 'Connect with Employers',
      education: 'Education Opportunities',
      availableOnDesktop: 'Available on Desktop Only:<br/>',
      rpLinkText: 'RallyPoint.com/careers'
    },
    profiles: {
      groups: {
        members: '{{count}} Members',
        header: 'Groups'
      }
    },
    notificationCenter: {
      notificationTitle: 'You earned the',
      subTitles: {
        endorsement: 'Endorsed you',
        cr: 'Is now a contact',
        profile_view: 'Viewed your profile',
        invited_to_group: 'invited you to join a private group.',
        joined_private_group: 'You joined a private group.'
      },
      badgeHelperTexts: {
        received_badge: 'earned the {{badgeName}} badge'
      },
      qrHelperTexts: {
        received_comment: 'commented on your response',
        mentioned_in_post: 'mentioned you in a post',
        received_response: 'responded to your question',
        have_QR_voted_up: 'voted you up',
        have_QR_voted_down: 'voted you down',
        new_group_discussion: 'started a discussion in <strong>{{{groups}}}</strong>'
      },
      groupHelperText: 'one of your groups'
    },
    ads: {
      partner: 'RallyPoint Partner'
    },
    networkBuilder: {
      numContacts: 'Add {{totalContacts}} contacts'
    },
    pullToRefresh: {
      pull: 'pull to refresh...',
      release: 'release to refresh'
    },
    feed: {
      deploymentAnniversary: {
        title: {
          one: ' returned from {{location}} 1 year ago this month.',
          other: ' returned from {{location}} {{count}} years ago this month.'
        },
        subtitle: {
          one: '1 year ago, {{name}} returned home from {{location}}.',
          other: '{{count}} years ago, {{name}} returned home from {{location}}.'
        },
        salute: 'Salute their service!',
        thankYou: 'Thank you! {{name}} will be notified that you saluted them.',
        missingDeployment: 'We noticed you\'re missing your deployment date.',
        goToProfile: 'Click here to update your profile.'
      },
      rpAnniversary: {
        title: {
          one: ' joined RallyPoint 1 year ago this week.',
          other: ' joined RallyPoint {{count}} years ago this week.'
        },
        sinceJoining: 'Since joining, {{name}} left ',
        commentsCount: {
          one: '1 comment, ',
          other: '{{count}} comments, '
        },
        replyCount: {
          one: 'has been replied to 1 time, ',
          other: 'has been replied to {{count}} times, '
        },
        postsCount: {
          one: 'and created 1 post.',
          other: 'and created {{count}} posts.'
        },
        celebrate: 'Salute them!',
        thankYou: 'Thank you! {{name}} will be notified that you saluted them.'
      },
      birthday: {
        wish: 'Wish ',
        happyBirthday: ' a happy birthday!',
        subtitle: 'Their birthday is {{date}}. <b>Send a salute to celebrate!</b>',
        thankYou: 'Thank you! {{name}} will be notified that you saluted them.'
      },
      jobs: {
        interested: 'Jobs recommended for you',
        viewAll: 'View More Jobs',
        newJob: 'New!',
        postAJob: 'Hiring? <a href="/registrations/step1?company_aim=2">Post a job now</a>',
        militaryCount: {
          one: 'One RP veteran or service member works here, join their team',
          other: '{{count}} RP veterans or service members work here, join their team'
        }
      },
      jobPreferences: {
        yours: 'Your job preferences',
        when: 'When would you like to be considered for employment?',
        now: 'Now',
        notSure: 'Not Sure',
        next: 'Next',
        where: 'Where would you be open to work?',
        locations: 'Locations',
        additionalLocations: 'I would also consider additional locations',
        interests: 'What are you interested in?',
        topics: 'Topics',
        interestError: 'Please add at least one interest. You can always come back and adjust or add more later.',
        thankYou: 'Thank you!',
        updated: 'Your job preferences have been updated.',
        goToJobs: 'Go to jobs',
        locationPlaceholder: 'Add city or zip',
        interestPlaceholder: 'Add interests'

      },
      emptyMessage: 'Follow some groups or connect with other members to start filling your feed. You can also explore some of our trending discussions on the right to see what the community is talking about.',
      postedALink: 'Shared a link',
      postedAQuestion: 'Asked a question',
      postedASponsoredAd: 'Sponsored',
      postedAnUpdate: 'Shared an update',
      commandPostAction: {
        article: 'Posted an Article',
        qAndA: 'Posted a Q&A',
        video: 'Posted a Video',
        podcast: 'Posted a Podcast'
      },
      views: {
        one: 'Impression',
        other: 'Impressions'
      },
      topBar: {
        askQuestion: {
          cancel: 'Cancel',
          title: 'Ask question',
          post: 'Post',
          errorTitle: 'Cannot Start Discussion',
          help: 'Get expert advice and tips for your questions.'
        },
        shareLink: {
          cancel: 'Cancel',
          title: 'Share link',
          help: 'Share news, articles, web sites, videos, and other links.'
        },
        postUpdate: {
          cancel: 'Cancel',
          title: 'Post update',
          help: 'Share pictures, personal updates, plans, or whatever you like!'
        }
      },
      link: {
        postedFrom: 'Posted from {{source}}'
      },
      question: {
        sponsoredLearnMore: 'to learn more.',
        view: '[View Discussion]',
        viewResponse: 'Read More{{comments}}',
        visitSponsor: 'Visit Sponsor'
      },
      update: {
        otherMentions: {
          one: 'and {{count}} other.',
          other: 'and {{count}} others.'
        },
        seeMoreMentions: '[See More]',
        view: '[Continue Reading]'
      },
      reason: {
        ownPost: 'You posted',
        youWereMentioned: 'You were mentioned',
        contactPostedIn: {
          one: 'Contact posted in a group you follow',
          other: 'Contact posted in {{count}} groups you follow'
        },
        contactIsAuthor: 'Contact posted',
        postedIn: {
          one: 'Posted in a group you follow',
          other: 'Posted in {{count}} groups you follow'
        },
        youPostedIn: {
          one: 'You posted in a group you follow',
          other: 'You posted in {{count}} groups you follow'
        },
        other: 'Recommended for you',
        andOthers: {
          one: 'and {{count}} other',
          other: 'and {{count}} others'
        },
        participated: 'participated',
        profilePosted: 'posted',
        profileParticipated: 'participated',
        profileMentioned: 'was mentioned',
        anonymousPosted: 'Member posted',
        anonymousParticipated: 'Member participated',
        anonymousMentioned: 'Member was mentioned'
      },
      resourceCenter: {
        moreContent: 'For similar content, visit:'
      },
      rallypointSurvey: {
        title: 'RallyPoint Survey',
        thanksForParticipating: 'Thanks for participating!',
        surveyHelpMessage: 'Your answer helps RallyPoint recommend jobs, programs, and services to your peers. We keep your answers to these questions private and confidential.'
      }
    },
    feedOtherGroups: {
      one: 'and {{count}} other group',
      other: 'and {{count}} other groups'
    },
    feedGroupHeader: {
      noImage: 'No image'
    },
    feedBottomBar: {
      comment: 'Comment',
      post: 'Post',
      share: 'Share',
      voteUp: 'Vote Up'
    },
    feedStats: {
      comments: {
        one: '{{count}} Comment',
        other: '{{count}} Comments'
      },
      upvotes: {
        one: '{{count}} Vote',
        other: '{{count}} Votes'
      }
    },
    feedMoreMenu: {
      stopShowing: 'Stop showing this post',
      seeLess: 'See less from this member',
      seeMore: 'See more from this member',
      prevent: 'Prevent future interactions',
      hideCard: 'See fewer posts like this in the future',
      hide: 'Hide this Post',
      follow: 'Follow {{name}}',
      unfollow: 'Unfollow {{name}}',
      unfollowErrorTitle: 'Unable to unfollow',
      followErrorTitle: 'Unable to follow',
      block: 'Block {{name}}',
      hideSuccess: 'Successfully hid item',
      spamSuccess: 'Successfully repoted as spam',
      flag: 'Flag {{name}}',
      flagDescription: 'Alert admins about this user',
      blockDialog: {
        title: 'Are you sure you want to block {{name}}?'
      }
    },
    searchPicker: {
      done: 'Done',
      noResults: 'No results found',
      maxResults: 'You have chosen the maximum number of results.',
      results: '{{placeholder}}',
      yourResults: 'Your {{placeholder}}'
    },
    sharedLinks: {
      wizard: {
        title: 'Share Link',
        labels: {
          details: 'Details (Optional)',
          link: 'Link',
          postedFrom: 'Posted from:',
          addTag: 'Topics'
        },
        placeholders: {
          details: 'Add additional details about your link. Use @ to notify a specific contact.',
          link: 'Share a link with the military community.',
          topics: 'Add up to 5 to notify relevant members.'
        },
        errors: {
          mentions: 'Max {{max}} mentions reached'
        }
      }
    },
    questions: {
      wizard: {
        labels: {
          details: 'Details (Optional)',
          photos: 'Photos',
          postQuestion: 'Ask Question',
          addSurvey: 'Survey',
          addImage: 'Images',
          addTag: 'Topics'
        },
        placeholders: {
          details: 'Add additional details to your question. Use @ to notify a specific contact.',
          mentions: 'Tag people you know',
          title: 'What specific question do you have for the military community?',
          topics: 'Add up to 5 to notify relevant members.'
        },
        survey: {
          options: {
            placeholder: 'Fill in Option'
          }
        },
        anonymousPost: {
          tip: 'Verified members have the privilege to post anonymously. This feature is designed to conceal your identity to the RallyPoint community. We can’t guarantee your anonymity due to hacking. See the support page for more information.'
        }
      }
    },
    statusUpdate: {
      wizard: {
        title: 'Post Update',
        anonymous: 'Post Anonymously',
        labels: {
          photos: 'Photos',
          addTag: 'Topics (optional)'
        },
        placeholders: {
          update: 'Post an update to the military community. Use @ to mention a specific contact.',
          topics: 'Add up to 5 to notify relevant members.'
        }
      }
    },
    dialogs: {
      blockWarning: 'While blocked, this member will not be able to:\n      <ul>\n        <li>Endorse you</li>\n        <li>Tag you in a post</li>\n        <li>Connect with you</li>\n        <li>Respond to your questions</li>\n        <li>Comment on your responses</li>\n        <li>Vote on any of your questions, responses, or comments</li>\n      </ul>\n      You will also not be able to perform any of the above actions.\n      If you choose to, you can unblock this member after 10 days. If this member is a contact of yours, you will have to send a new connection request if you want to be connected again.',
      flagProfile: {
        title: 'Send a report to RallyPoint',
        thisUser: 'I believe that this user may be:',
        misrepresenting: 'Misrepresenting accomplishments/profile',
        differentUnit: 'In a different unit now',
        nonEligible: 'A non-eligible RallyPoint user',
        spam: 'Sending spam or inappropriate messages',
        comments: 'Comments',
        success: 'Your report has been sent',
        successTitle: 'Successfully Sent',
        sendError: 'Could not send report',
        sendErrorTitle: 'Error'
      }
    },
    buttons: {
      cancel: 'Cancel',
      confirm: 'Confirm',
      ok: 'OK',
      send: 'Send'
    },
    sidebar: {
      jobs: {
        title: 'Companies Looking For You',
        empty: 'We couldn\'t find any jobs at the moment.',
        viewMore: 'View More Jobs'
      }
    },
    header: {
      tagLine: 'The Military Network',
      search: {
        searchMore: 'See all results for <span class="search-term">"{{term}}"</span>',
        searchCategories: 'Search Categories',
        categories: {
          all: 'All',
          profiles: 'People',
          answers: 'Answers',
          topics: 'Topics',
          groups: 'Groups',
          units: 'Units',
          companies: 'Companies'
        },
        placeholders: {
          all: 'Search for people, groups, companies and more',
          profiles: 'Search profiles',
          answers: 'Search answers',
          topics: 'Search topics',
          groups: 'Search groups',
          units: 'Search units',
          companies: 'Search companies',
          discussions: 'Search for answers'
        }
      },
      inbox: {
        seeAll: 'See all messages',
        noMessages: 'There are no messages in your Inbox.',
        goToInbox: 'See your Inbox'
      },
      cr: {
        accept: 'Accept',
        accepted: 'Contact Request Accepted',
        whoElse: 'Who else did you serve with?',
        inviteFriends: 'Invite friends to RallyPoint!',
        yourUniqueLink: 'Your unique invite link',
        ignore: 'Ignore',
        ignored: 'Contact Request Ignored',
        invitesSent: {
          one: 'Sent one invite.  Do you have any other connections to invite?',
          other: 'Sent {{count}} invites.  Do you have any other connections to invite?'
        },
        seeMore: 'see more',
        title: 'Contact Requests',
        errors: {
          couldNotAccept: 'Unable to accept contact request at this time.',
          couldNotIgnore: 'Unable to ignore contact request at this time.',
          couldNotInvite: 'Unable to send invites at this time.'
        },
        reason: {
          friend: 'We know each other',
          seekingAdvice: 'Seek advice or support',
          offeringSupport: 'Offer support or advice'
        }
      },
      loggedOut: {
        aboutUs: 'About Us',
        logIn: 'Log In',
        signUp: 'Sign Up'
      }
    },
    headerMenu: {
      home: 'Home',
      resources: 'Resources',
      business: 'Business',
      myFeed: 'My Feed',
      answers: 'Answers',
      commandPost: 'Command Post',
      jobs: 'Jobs',
      education: 'Education',
      support: 'Support',
      emergency: 'Emergency',
      realEstate: 'Real Estate',
      wellness: 'Wellness',
      finance: 'Finance',
      myProfile: 'My Profile',
      profile: 'Profile',
      notifications: 'Notifications',
      messages: 'Messages',
      myContacts: 'My Contacts',
      myGroups: 'My Groups',
      settings: 'Settings',
      admin: 'Admin',
      leaderboard: 'Leaderboard',
      logout: 'Log Out',
      logIn: 'Log In',
      signUp: 'Sign Up',
      talentFinder: 'Talent Finder',
      commandCenter: 'Command Center',
      businessServices: 'Business Services',
      marketingSolutions: 'Marketing Solutions',
      talentSolutions: 'Talent Solutions',
      aboutUs: 'About Us',
      faq: 'FAQ',
      offers: 'Offers'
    },
    menu: {
      home: 'Home',
      myProfile: 'My Profile',
      myContacts: 'My Contacts',
      answers: 'Discussions',
      jobs: 'Jobs',
      talentFinder: 'Talent Finder',
      education: 'Education',
      partner: 'Business Services',
      commandPost: 'Command Post'
    },
    addUnit: {
      search: {
        Veteran: 'Search for your unit...',
        Servicemember: 'Search for your unit...',
        Reserve: 'Search for your unit...',
        Civilian: 'Search for your employer...'
      },
      current: {
        Servicemember: 'This is my current unit',
        Reserve: 'This is my current unit',
        Veteran: 'This is my current unit',
        Civilian: 'This is my current employer'
      }
    },

    addSchool: {
      search: 'Ex: University of Delaware'
    },

    addDeployment: {
      search: 'Ex: Operation Iraqi Freedom'
    },

    invitation: {
      contactList: {
        found: "Here's everyone we found!",
        loading: 'We are still loading your connections,<br>but feel free to continue on to RallyPoint.',
        heading: 'Your Connections',
        moreAccounts: 'Want to find more friends on RallyPoint?',
        connectMoreAccounts: 'Connect More Accounts',
        finish: 'Enter RallyPoint'
      },
      header: {
        countWaiting: {
          one: 'CONTACT WAITING',
          other: 'CONTACTS WAITING'
        }
      },

      footer: {
        blurb: 'RallyPoint is backed by former Chiefs of Staff of the Army and Air Force, and the former Commander, U.S. Fleet Forces Command',
        nextButtonText: 'Continue',
        backButtonText: 'Back'
      },

      networks: {
        googleLabel: 'Gmail',
        facebookLabel: 'Facebook'
      },

      landing: {
        mainMessage: 'wants to know what you and your friends are up to.<br/>Find them all and check out their military profiles here',
        nextButtonText: 'Show Me',
        disclaimer: 'Connecting your social profiles allows RallyPoint to find your friends and help you catch up instantly! We may send invitations to your personal contacts outside of RallyPoint if we believe their presence on RallyPoint would enhance your experience.'
      },

      findfriends: {
        mainMessage: 'Your contacts are protected.<br>Log in to see who you know.',
        existingMessage: 'Your contacts are protected.<br>Let\'s see who you know.',
        existingButtonText: 'Continue',
        nextButtonText: 'Log In and Continue'
      },

      emailConnections: {
        socialMessage: 'Connect with Facebook to see<br/>what your other friends have been up to',
        allMessage: 'Connect at least one account to see what your<br/>friends have been up to on RallyPoint',
        continueButtonText: 'Continue',
        mainText: 'Sign up to see what your friends have<br/>been up to on RallyPoint'
      },

      socialConnections: {
        existingSocial: 'Connect your social accounts to find more friends',
        allSocial: 'Verify your identity before<br> accessing your friends\' profiles'
      },

      registration: {},

      militaryInfo: {},

      unitInfo: {
        recentUnit: {
          Servicemember: 'My most recent unit:',
          Civilian: 'My most recent employer:',
          Veteran: 'Add unit:'
        },

        currentUnit: {
          Servicemember: 'My current unit:',
          Civilian: 'My current employer:'
        },

        recentPosition: {
          Servicemember: 'Add position:',
          Veteran: 'Add position:',
          Civilian: 'Add position:'
        },

        currentPosition: {
          default: 'My current position:'
        },

        pastUnit: 'My past unit:',
        addUnit: 'Add unit',
        helpFind: 'Help us find even more of your contacts:',
        searchPosition: 'Search for your position...',
        contactsCounter: 'Find folks who served in your unit:',
        addUndergraduateSchool: 'Add undergraduate schools',
        addPastDeployments: 'Add past deployments',
        addPastUnit: 'Add past unit'
      }
    },

    commandPost: {
      subheader: "RallyPoint's premier content destination including blogs, Q&A's, and other curated must-see content.",

      questionSearch: {
        placeholder: 'Search for Command Post discussions...',
        resultsHeading: 'Results in Command Post',
        noResults: 'No Command Post results',
        submit: 'Search'
      },

      filter: {
        label: 'Filter by:',
        article: 'Article',
        none: 'All',
        qAndA: 'Q&A'
      }
    },

    resourceCenter: {
      rssHeader: 'Happening Now On',
      education: {
        subheader: 'Education Resource Center',
        promoSubtitle: 'Alumni Recognition',
        viewProfileButtonText: 'View Profile',
        nextButtonText: 'Next',
        resourcesHeader: 'Education Resources'
      },
      employment: {
        subheader: 'Employment Resource Center',
        resourcesHeader: 'Employment Resources'
      },
      finance: {
        subheader: 'Finance Resource Center',
        resourcesHeader: 'Finance Resources',
        nextButtonText: 'Next',
        viewProfileButtonText: 'View Profile',
        promoSubtitle: 'Relationship Managers'
      },
      'real-estate': {
        subheader: 'Real Estate Resource Center',
        resourcesHeader: 'VA Resources'
      },
      wellness: {
        subheader: 'Wellness Center',
        promoSubtitle: 'Featured Profile',
        viewProfileButtonText: 'View Profile',
        resourcesHeader: 'Wellness Resources'
      }
    },

    metaGroups: {
      education: {
        questionSearch: {
          placeholder: 'Search for Education discussions...',
          resultsHeading: 'Results in Education',
          noResults: 'No Education results',
          submit: 'Search'
        }
      },
      employment: {
        questionSearch: {
          placeholder: 'Search for Employment discussions...',
          resultsHeading: 'Results in Employment',
          noResults: 'No Employment results',
          submit: 'Search'
        }
      },
      finance: {
        questionSearch: {
          placeholder: 'Search for Finance discussions...',
          resultsHeading: 'Results in Finance',
          noResults: 'No Finance results',
          submit: 'Search'
        }
      },
      'real-estate': {
        questionSearch: {
          placeholder: 'Search for Real Estate discussions...',
          resultsHeading: 'Results in Real Estate',
          noResults: 'No Real Estate results',
          submit: 'Search'
        }
      },
      wellness: {
        questionSearch: {
          placeholder: 'Search for Wellness discussions...',
          resultsHeading: 'Results in Wellness',
          noResults: 'No Wellness results',
          submit: 'Search'
        }
      }
    },

    questionSearch: {
      placeholder: 'Search for discussions...',
      resultsHeading: 'Results in Answers',
      submit: 'Go',
      noResults: 'No discussions found'
    },

    serverMaintenance: {
      message: 'We are currently down for maintenance.  Please try again shortly.',
      reload: 'Reload'
    },

    forms: {
      charsLeft: '{{charsLeft}} characters left',
      validationErrors: {
        charLengthExceeded: 'Character limit exceeded'
      }
    },

    profileCompletionWidget: {
      update: 'Update your profile'
    },

    companyAdminWidget: {
      followers: 'Followers',
      manageAdvertisingSolutions: 'Manage Advertising Solutions',
      manageTalentSolutions: 'Manage Talent Solutions',
      yourCompanyPage: 'Your Company Page'
    },

    jobs: {
      apply: 'Apply',
      bannerAd: '{{companyName}} is hiring for: {{title}} in {{location}}.  {{learnMore}}',
      learnMore: 'Learn More',
      emailApplication: {
        header: 'Apply to {{companyName}}',
        instructions: 'Please provide the information below to contact the hiring manager for this position.',
        successMessage: 'Your application was successfully sent to a hiring manager at {{companyName}}.',
        submitError: 'There was an error submitting the application. Please try again later or contact support.'
      }
    },

    userType: {
      // Violates camelcase to map to profile.user_type
      verified: {
        Civilian: 'civilian',
        Veteran: 'verified veteran',
        Servicemember: 'verified service member'
      },
      unverified: {
        Civilian: 'civilian',
        Veteran: 'unverified veteran',
        Servicemember: 'unverified service member'
      }
    },

    contacts: {
      moreContacts: 'More Contacts',
      fewerContacts: 'Fewer Contacts',
      deleteConfirm: 'Are you sure you want to delete this contact?',
      pymk: 'Members Similar To You',
      sortBy: {
        label: 'Sort by',
        firstName: 'First Name',
        lastName: 'Last Name',
        recent: 'Recently Connected'
      },
      sentRequests: {
        title: 'Sent Requests ({{count}})',
        buttonLabel: 'Sent Requests ({{count}})',
        sent: 'Sent',
        cancel: 'Cancel'
      },
      pendingRequests: {
        title: 'Pending Requests ({{count}})',
        buttonLabel: 'Pending Requests ({{count}})',
        connect: 'Connect',
        connected: 'Connected',
        decline: 'Decline',
        declined: 'Declined',
        reasons: {
          friend: 'We know each other.',
          offering_support: 'I can offer support or mentorship.',
          seeking_for_advice: 'I am seeking advice or support.',
          other: 'Other:',
          per_your_recommendation: "Hey, I've joined RallyPoint, per your recommendation!"
        }
      },
      sidebar: {
        inviteHeading: 'Who else did you serve with?',
        linkPrompt: 'Share Your Unique Invite Link',
        sendFacebook: 'Send a Message on Facebook'
      },
      connectButton: {
        title: 'Connect to {{name}}',
        label: 'Connect',
        requestSentLabel: 'Request Sent',
        reasonLabel: 'Why would you like to connect?',
        friendReason: 'We know each other',
        wantAdviceReason: 'I am seeking advice or support',
        haveAdviceReason: 'I can offer support or mentorship',
        otherReason: 'Other',
        explanationLabel: 'Message',
        explanationPlaceholder: 'Optional. If you don\'t know this person, please describe why you would like to connect.',
        serverError: 'There was a problem sending your contact request. Please try again later.',
        success: 'Your connection request was delivered.',
        paywall: {
          message: 'Upgrade to a business account to connect with military members and veterans.',
          button: 'Take me to business services'
        }
      },
      messageUser: {
        buttonTitle: 'Message',
        messageTo: 'Message to',
        subject: 'Subject',
        message: 'Message',
        submissionErrorMessage: 'There was a problem sending this message. Please try again later or contact support.',
        missingMessageBody: 'The message field can not be empty. Please enter your message.',
        missingMessageSubject: 'The subject field can not be empty. Please enter a message subject.',
        success: 'Your message was sent.',
        cancel: 'Cancel',
        send: 'Send'
      },
      shared: {
        link: {
          one: '1 connection in common',
          other: '{{count}} connections in common'
        },
        modalTitle: {
          one: '1 Connection in Common with {{name}}',
          other: '{{count}} Connections in Common with {{name}}'
        }
      },
      invite: {
        email: 'Hey! Join me on Rallypoint, the largest US military online community!',
        emailSubject: '{{name}} thinks you\'re an important person to have on RallyPoint'
      }
    },

    // Important that these keys match mobile exactly for the contact-request model validation messages
    contactRequests: {
      newRequest: {
        error: {
          needReason: 'You must choose at least one explanation.',
          needMessage: 'Please include a message if you choose "Other".'
        }
      }
    },

    raiseAGlass: {
      header: 'Happy Veterans Day!',
      header2: 'In honor of Veterans Day this year, RallyPoint encourages you to celebrate by “raising your glass” and sending a drink to a veteran. Every veteran deserves a free drink and a toast, even if it is just virtual. Don\'t worry, the drinks you send are on us! Cheers, The RallyPoint Staff.',
      cta: 'Raise a Glass',
      statLine: {
        one: 'and {{count}} other just Raised a Glass',
        other: 'and {{count}} others just Raised a Glass'
      },
      statLineNoContacts: {
        one: '{{count}} member has Raised a Glass',
        other: '{{count}} members have Raised a Glass'
      },
      button: {
        success: 'You Raised a Glass honoring {{name}}!',
        text: 'Raise a Glass'
      },
      contactsSidebar: {
        h1: 'Happy Veterans Day!',
        h2: 'Every veteran deserves a free drink and a toast, even if it is just virtual.'
      }
    },

    feedEventInfo: {
      header: 'An event near <span class="event-location">{{location}}</span>',
      cta: 'Learn More',
      stats: {
        one: '1 other member is interested',
        other: '{{count}} other members are interested'
      }
    },

    salute: {
      success: 'Your salute was successful.',
      errors: {
        tooMany: 'Hang on there high speed, wait until your salute is returned before saluting again!',
        notConnected: 'Hang on there high speed, you have to be connected with someone before you can salute them!'
      }
    },

    inlineWizardValidations: {
      titleValidationDescription: 'Title',
      urlValidationMessage: 'Please add a valid link',
      topicValidationMessage: 'Please add at least one topic',
      statusUpdateValidationMessage: 'Please add text or a photo',
      surveyValidationMessage: 'Please add at least two options to the survey',
      questionMarkValidationMessage: 'Questions must end with a question mark.'
    },

    feedSuggestedGroups: {
      title: 'Follow groups to see posts you\'re interested in'
    },

    listGroup: {
      followersCount: '{{count}} Followers'
    },

    feedFeaturedRep: {
      header: 'Get more from RallyPoint. Connect with an expert in the industry.'
    },

    feedResponse: {
      voteUp: 'Vote Up',
      reply: 'Comment',
      readMore: '[Read More]'
    },

    photoFeedItem: {
      votes: 'Votes',
      comments: 'Comments'
    },

    photosFeed: {
      addNew: 'Upload your photo',
      noPhotos: 'No photos in this group yet.',
      uploadSuccess: 'Upload successful'
    },

    commandPostSubmissionCTA: {
      title: 'We\'re looking for writers!',
      body: 'Email us if you\'d like to contribute your own Command Post.',
      emailSubject: 'How do I become a writer for a RallyPoint Command Post?'
    },

    listFeedBottomBar: {
      shareVia: 'Share via:'
    },
    offers: {
      intro: 'This page contains affiliate links to products. RallyPoint may receive a commission for purchases made.',
      title: 'RallyPoint Offers'
    }
  };
});