define('desktop-frontend/components/stigma-reduction-bri-menu/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var EmberObject = Ember.Object;
  var Component = Ember.Component;
  var service = Ember.inject.service;
  exports.default = Component.extend({
    classNames: ['interventions-banner'],
    i18n: service(),
    apiService: service(),
    answered: false,
    submitting: false,

    init: function init() {
      this._super.apply(this, arguments);

      this.set('options', []);
      var nOptions = 7;

      for (var i = 1; i <= nOptions; i++) {
        var label = this.get('i18n').t('nocklabs.stigma.briMenu.' + i);
        var tip = i < nOptions ? this.get('i18n').t('nocklabs.stigma.briMenu.t' + i) : null;

        this.get('options').pushObject(EmberObject.create({
          value: i,
          selected: false,
          label: label,
          tip: tip
        }));
      }
    },
    didRender: function didRender() {
      this._super.apply(this, arguments);
      if (this.get('lastSelectedOption') >= 4) {
        var container = this.$('#stigma-briopts').get(0);
        container.scrollTo(0, container.scrollHeight);
      }
    },


    actions: {
      done: function done() {
        var _this = this;

        this.set('submitting', true);
        var answer = this.get('options').filterBy('selected', true).map(function (option) {
          return option.value;
        });
        this.get('apiService').postV2Request('interventions/stigma_reduction', { answer: answer }).then(function () {
          _this.set('submitting', false);
          _this.set('answered', true);
        });
      },
      toggleOption: function toggleOption(i) {
        var opt = this.get('options')[i];
        if (!opt.get('selected')) {
          this.set('lastSelectedOption', i);
        }
      }
    }
  });
});