define('desktop-frontend/components/feed-more-menu/component', ['exports', 'ember-concurrency'], function (exports, _emberConcurrency) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var bool = Ember.computed.bool;
  var guidFor = Ember.guidFor;
  var Component = Ember.Component;
  var $ = Ember.$;
  var set = Ember.set;
  var get = Ember.get;
  var computed = Ember.computed;
  var service = Ember.inject.service;
  var run = Ember.run;
  exports.default = Component.extend({
    tagName: 'ul',
    classNames: ['feed-more-menu'],
    i18n: service(),

    listProfile: null,
    fullModel: null,

    blockingService: service(),
    followProfile: service(),
    store: service(),
    ui: service(),
    pageContext: service(),

    blockWarningVisible: false,
    flagModalVisible: false,

    showHidePost: bool('pageContext.onHomePage'),

    warning: computed('listProfile.{id,name}', function () {
      return get(this, 'store').createRecord('warning', {
        id: guidFor(Math.random()),
        profile_id: get(this, 'listProfile.id'),
        profile_name: get(this, 'listProfile.fullNameAndRank')
      });
    }),

    didInsertElement: function didInsertElement() {
      var _this = this;

      this._super.apply(this, arguments);
      run.next(function () {
        $('body').on('click.feed-more-menu', _this.onBodyClick.bind(_this));
      });
    },
    willDestroyElement: function willDestroyElement() {
      run.next(function () {
        $('body').off('click.feed-more-menu');
      });
      this._super.apply(this, arguments);
    },
    onBodyClick: function onBodyClick(evt) {
      var $target = $(evt.target);
      var OK_CLICKABLE = ['.feed-more-menu', '.more-button', '.modal-popup', '.x-icon-menu'].join(',');

      if ($target.is(OK_CLICKABLE) || $target.parents(OK_CLICKABLE).length) {
        return;
      }

      get(this, 'close')();
    },
    click: function click(evt) {
      evt.stopPropagation();
    },


    unfollow: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function _callee() {
      var ui;
      return regeneratorRuntime.wrap(function _callee$(_context) {
        while (1) {
          switch (_context.prev = _context.next) {
            case 0:
              ui = get(this, 'ui');
              _context.prev = 1;
              _context.next = 4;
              return this.get('setFollow').perform(false);

            case 4:
              _context.next = 9;
              break;

            case 6:
              _context.prev = 6;
              _context.t0 = _context['catch'](1);

              ui.nativeAlert(_context.t0, get(this, 'i18n').t('feedMoreMenu.unfollowErrorTitle'));

            case 9:
              get(this, 'close')();

            case 10:
            case 'end':
              return _context.stop();
          }
        }
      }, _callee, this, [[1, 6]]);
    })),

    follow: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function _callee2() {
      var ui;
      return regeneratorRuntime.wrap(function _callee2$(_context2) {
        while (1) {
          switch (_context2.prev = _context2.next) {
            case 0:
              ui = get(this, 'ui');
              _context2.prev = 1;
              _context2.next = 4;
              return this.get('setFollow').perform(true);

            case 4:
              _context2.next = 9;
              break;

            case 6:
              _context2.prev = 6;
              _context2.t0 = _context2['catch'](1);

              ui.nativeAlert(_context2.t0, get(this, 'i18n').t('feedMoreMenu.followErrorTitle'));

            case 9:
              get(this, 'close')();

            case 10:
            case 'end':
              return _context2.stop();
          }
        }
      }, _callee2, this, [[1, 6]]);
    })),

    setFollow: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function _callee3(follow) {
      var listProfile, followProfile;
      return regeneratorRuntime.wrap(function _callee3$(_context3) {
        while (1) {
          switch (_context3.prev = _context3.next) {
            case 0:
              listProfile = get(this, 'listProfile');
              followProfile = get(this, 'followProfile');

              if (!follow) {
                _context3.next = 8;
                break;
              }

              _context3.next = 5;
              return followProfile.follow(listProfile);

            case 5:
              return _context3.abrupt('return', _context3.sent);

            case 8:
              _context3.next = 10;
              return followProfile.unfollow(listProfile);

            case 10:
              return _context3.abrupt('return', _context3.sent);

            case 11:
            case 'end':
              return _context3.stop();
          }
        }
      }, _callee3, this);
    })),

    flagProfile: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function _callee4() {
      var warning;
      return regeneratorRuntime.wrap(function _callee4$(_context4) {
        while (1) {
          switch (_context4.prev = _context4.next) {
            case 0:
              _context4.prev = 0;
              warning = get(this, 'warning');
              _context4.next = 4;
              return warning.save();

            case 4:
              warning.unloadRecord();
              get(this, 'ui').nativeAlert(get(this, 'i18n').t('dialogs.flagProfile.success'), get(this, 'i18n').t('dialogs.flagProfile.successTitle'));
              _context4.next = 11;
              break;

            case 8:
              _context4.prev = 8;
              _context4.t0 = _context4['catch'](0);

              get(this, 'ui').nativeAlert(get(this, 'i18n').t('dialogs.flagProfile.sendError'), get(this, 'i18n').t('dialogs.flagProfile.sendErrorTitle'));

            case 11:

              get(this, 'close')();

            case 12:
            case 'end':
              return _context4.stop();
          }
        }
      }, _callee4, this, [[0, 8]]);
    })),

    actions: {
      blockProfile: function blockProfile() {
        get(this, 'blockingService').block(get(this, 'listProfile.id')).then(function () {
          window.location.reload();
        });
      },
      flagProfile: function flagProfile() {
        get(this, 'flagProfile').perform();
      },
      hideFromFeed: function hideFromFeed() {
        get(this, 'fullModel').then(function (fullModel) {
          return fullModel.hideFromUpdates(true);
        }).then(function () {
          window.location.reload();
        });
      },
      cancelBlock: function cancelBlock() {
        set(this, 'blockWarningVisible', false);
        get(this, 'close')();
      },
      cancelFlag: function cancelFlag() {
        get(this, 'warning').rollbackAttributes();
        set(this, 'flagModalVisible', false);
        get(this, 'close')();
      },
      showBlockWarning: function showBlockWarning() {
        set(this, 'blockWarningVisible', true);
      },
      showFlagModal: function showFlagModal() {
        set(this, 'flagModalVisible', true);
      }
    }
  });
});