define('desktop-frontend/components/survey-form-option/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var gt = Ember.computed.gt;
  var Component = Ember.Component;
  var get = Ember.get;


  var MIN_OPTIONS_TO_REMOVE = 2;
  exports.default = Component.extend({
    tagName: 'li',
    classNames: 'survey-option',
    idx: null,
    option: null,
    numOptions: 0,
    canRemoveOptions: gt('numOptions', MIN_OPTIONS_TO_REMOVE),
    actions: {
      removeOption: function removeOption() {
        var idx = get(this, 'idx');
        this.attrs.removeOption(idx);
      }
    }
  });
});