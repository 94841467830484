define('desktop-frontend/mixins/components/ember-infinity', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var service = Ember.inject.service;
  var Mixin = Ember.Mixin;
  var $ = Ember.$;
  var set = Ember.set;
  var get = Ember.get;
  exports.default = Mixin.create({
    perPage: 10,
    store: service(),
    modelName: 'list-feed',
    modelPath: 'feeds',
    _loadingInfinity: false,
    _currentPage: 0,

    _infinityModel: function _infinityModel() {
      return get(this, get(this, 'modelPath'));
    },


    actions: {
      infinityLoad: function infinityLoad() {
        var _this = this;

        if (get(this, '_loadingInfinity')) {
          return;
        }

        set(this, '_loadingInfinity', true);
        this.incrementProperty('_currentPage');
        var modelName = get(this, 'modelName');
        var store = get(this, 'store');
        var queryOptions = {
          page: this.get('_currentPage'),
          per_page: this.get('perPage')
        };

        queryOptions = $.extend(queryOptions, this.get('infinityOptions'));

        store.query(modelName, queryOptions).then(function (records) {
          _this.send('infinityModelUpdated', records);
          // let modelPath = `${this.get('modelPath')}`;
          // this.get(modelPath).pushObjects(result.get('content'));
        });
      },
      infinityModelUpdated: function infinityModelUpdated(records) {
        this._super.apply(this, arguments);
        if (get(records, 'length') === 0 || get(records, 'meta.total_pages') <= get(this, '_currentPage')) {
          this.send('reachedInfinity');
        }

        set(this, '_loadingInfinity', false);
      },
      reachedInfinity: function reachedInfinity() {
        set(this, get(this, 'modelPath') + '.reachedInfinity', true);
      }
    }
  });
});