define('desktop-frontend/command-post/index/controller', ['exports', 'rp-common/services/trackers/qrc', 'rp-common/services/trackers/job', 'rp-common/components/google-ad/component', 'rp-common/models/list-question', 'desktop-frontend/mixins/with-qlu-sidebar'], function (exports, _qrc, _job, _component, _listQuestion, _withQluSidebar) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Controller = Ember.Controller;
  var alias = Ember.computed.alias;
  var set = Ember.set;
  var service = Ember.inject.service;


  var COMMAND_POST_ARTICLE = _listQuestion.COMMAND_POST_TYPES.indexOf('article');
  var COMMAND_POST_Q_AND_A = _listQuestion.COMMAND_POST_TYPES.indexOf('qAndA');

  var SORT_OPTIONS = [{ label: 'Date Published', value: 'newdiscussions' }, { label: 'Trending Now', value: 'trending' }, { label: 'Most Popular', value: 'popularity' }, { label: 'Recent Activity', value: 'recent' }];

  exports.default = Controller.extend(_withQluSidebar.default, {
    queryParams: ['o', 'q', 'type'],
    o: 'newdiscussions',
    q: '',
    type: '',
    location: _qrc.COMMAND_POST_FEED,

    currentUser: service(),
    router: service(),

    bannerAdSize: _component.BANNER_SLOT_SIZE,
    bottomAdSizes: _component.SIDEBAR_WITH_LONG_SLOT_SIZE,
    commandPostArticle: String(COMMAND_POST_ARTICLE),
    commandPostQAndA: String(COMMAND_POST_Q_AND_A),
    sidebarJobsListLocation: _job.JOB_SIDE_COMMAND_POST,

    commandPosts: alias('model'),
    sortOrder: alias('o'),
    searchTerm: alias('q'),
    filterBy: alias('type'),
    sortOptions: SORT_OPTIONS,

    actions: {
      changeSort: function changeSort(sort) {
        set(this, 'sortOrder', sort);
      },
      changeFilter: function changeFilter(value) {
        set(this, 'filterBy', value);
      },
      onSearch: function onSearch(term) {
        set(this, 'searchTerm', term);
      },


      // For command posts we don't care about this action, so throw it away
      goToLink: function goToLink() {}
    }
  });
});