define('desktop-frontend/resource-center/index/route', ['exports', 'ember-infinity/mixins/route', 'desktop-frontend/mixins/reset-scroll-route', 'rp-common/services/trackers/qrc', 'rp-common/mixins/discussion-tracking-route'], function (exports, _route, _resetScrollRoute, _qrc, _discussionTrackingRoute) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var service = Ember.inject.service;
  var $ = Ember.$;
  var Route = Ember.Route;
  var get = Ember.get;
  var RSVP = Ember.RSVP;
  exports.default = Route.extend(_route.default, _resetScrollRoute.default, _discussionTrackingRoute.FeedDiscussionTrackingMixin, {
    impressionLocation: _qrc.GROUP_DISCUSSIONS_FEED,
    tag: null,

    queryParams: {
      o: {
        refreshModel: true
      },
      q: {
        refreshModel: true
      }
    },

    apiService: service(),

    model: function model(params) {
      var tag = this.modelFor('resource-center');

      params = $.extend(params, {
        group_id: get(tag, 'group_id'),
        perPage: 10,
        startingPage: 1,
        modelPath: 'controller.model.discussions'
      });
      var discussions = this.infinityModel('list-discussion', params);
      return RSVP.hash({ tag: tag, discussions: discussions });
    },
    resetController: function resetController(controller, isExiting) {
      if (isExiting) {
        controller.set('q', '');
        controller.set('o', 'recent');
      }
    }
  });
});