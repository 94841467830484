define('desktop-frontend/components/contacts-header/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var set = Ember.set;
  var get = Ember.get;
  var alias = Ember.computed.alias;
  var service = Ember.inject.service;
  exports.default = Component.extend({
    classNames: ['contacts-header'],
    pendingContactsOpen: false,
    sentRequestsOpen: false,
    contactRequests: null,
    sentRequests: null,

    controlCenter: service(),
    controlCenterRequestCount: alias('controlCenter.pendingContactRequests'),

    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      if (get(this, 'contactRequests.length')) {
        this.send('openPendingContacts');
      }
    },


    actions: {
      closePendingContacts: function closePendingContacts() {
        var _this = this;

        set(this, 'pendingContactsOpen', false);

        // After model is closed we need a funky cleanup step because we
        // don't want to remove CR's while the modal is open, but we don't
        // want accepted/ignored ones to be rerendered if the modal is reopened.
        var contactRequestsCopy = get(this, 'contactRequests').slice(0);
        contactRequestsCopy.map(function (cr) {
          if (get(cr, 'accepted') || get(cr, 'ignored')) {
            _this.sendAction('hideContactRequest', cr);
          }
        });
      },
      closeSentRequests: function closeSentRequests() {
        set(this, 'sentContactsOpen', false);
      },
      openPendingContacts: function openPendingContacts() {
        set(this, 'pendingContactsOpen', true);
      },
      openSentContacts: function openSentContacts() {
        set(this, 'sentContactsOpen', true);
      }
    }
  });
});