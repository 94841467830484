define('desktop-frontend/command-post/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Route = Ember.Route;
  var service = Ember.inject.service;
  var set = Ember.set;
  exports.default = Route.extend({
    pageLayout: service(),

    activate: function activate() {
      set(this, 'pageLayout.isFullWidth', true);
    },
    deactivate: function deactivate() {
      set(this, 'pageLayout.isFullWidth', false);
    }
  });
});