define('desktop-frontend/components/peer-support-banner/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var readOnly = Ember.computed.readOnly;
  var service = Ember.inject.service;
  exports.default = Component.extend({
    classNames: ['peer-support-banner'],
    nocklabsIntervention: service('nocklabs-intervention'),
    qrcContent: null,
    fullContent: null,
    listProfile: readOnly('qrcContent.list_profile'),
    highRisk: readOnly('fullContent.high_risk'),
    currentUser: service(),
    router: service(),
    apiService: service(),
    showBanner: false,
    showSupportModal: false,
    connected: false,
    singleActionButton: service('single-action-button-visibility'),
    isInterventionMember: false,
    peerSupportCompleted: true,

    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);
      this.showPeerSupportBanner();
    },
    showPeerSupportBanner: function showPeerSupportBanner() {
      var listProfileId = this.get('listProfile.id');
      var currentUserId = this.get('currentUser.profile.id');

      if (currentUserId !== listProfileId) {
        var isHighRisk = this.get('highRisk');
        var completed = this.get('peerSupportCompleted');
        var showBanner = isHighRisk && !completed;

        this.set('showBanner', showBanner);

        if (showBanner) {
          window.nlDataLayer.push({
            'event': 'PeerSupport_Viewed',
            'banner_type': 'peer_support',
            'qrc_id': this.get('qrcContent.id')
          });
        }
      }
    },
    trackOpened: function trackOpened() {
      window.nlDataLayer.push({
        event: 'PeerSupport_Opened',
        banner_type: 'peer_support',
        qrc_id: this.get('qrcContent.id'),
        time: new Date().getTime()
      });
    },
    trackCompleted: function trackCompleted() {
      window.nlDataLayer.push({
        event: 'PeerSupport_Closed',
        banner_type: 'peer_support',
        qrc_id: this.get('qrcContent.id'),
        time: new Date().getTime()
      });
    },

    actions: {
      openModal: function openModal() {
        this.toggleProperty('showSupportModal');
        this.get('singleActionButton').hide();
        this.trackOpened();
      },
      closeModal: function closeModal() {
        this.toggleProperty('showBanner');
        this.toggleProperty('showSupportModal');
        this.get('singleActionButton').show();
        this.trackCompleted();
      }
    }
  });
});