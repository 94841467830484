define('desktop-frontend/catch-all/route', ['exports', 'desktop-frontend/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Route = Ember.Route;
  exports.default = Route.extend({
    beforeModel: function beforeModel() {
      if (_environment.default.APP.IS_ISLANDS) {
        return;
      }

      return this.transitionTo('home');
    }
  });
});