define('desktop-frontend/components/flag-profile-modal/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var not = Ember.computed.not;
  var set = Ember.set;
  var get = Ember.get;
  exports.default = Component.extend({
    listProfile: null,
    warning: null,

    showErrors: false,

    hasErrors: not('warning.validations.isValid'),

    actions: {
      sendWarning: function sendWarning() {
        set(this, 'showErrors', true);

        if (get(this, 'hasErrors')) {
          return;
        }

        this.attrs.confirmFlag();
      }
    }
  });
});