define('desktop-frontend/components/qrc-image-upload/component', ['exports', 'desktop-frontend/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var $ = Ember.$;
  var get = Ember.get;
  var computed = Ember.computed;
  var service = Ember.inject.service;
  var run = Ember.run;
  var Logger = Ember.Logger;
  exports.default = Component.extend({
    inactive: false,
    classNames: ['photo-upload-container'],
    allowMultiple: false,
    dataUrl: '/answers/image_upload',
    ui: service(),
    i18n: service(),

    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      this.setupFileUpload();
    },


    labelId: computed(function () {
      return get(this, 'elementId').concat('-input');
    }),

    // Override this method if you need a more customized file upload
    setupFileUpload: function setupFileUpload() {
      var csrf = $('meta[name="csrf-token"]').attr('content') || _environment.default.APP.CSRF;
      if (!csrf) {
        throw 'CSRF token not specified. Check your config.';
      }
      var dataUrl = this.get('dataUrl');
      var self = this;
      var formData = {
        authenticity_token: csrf
      };

      this.$().fileupload({
        dataType: 'json',
        url: dataUrl,
        formData: formData,
        done: function done(e, data) {
          if (data && data.result && data.result.errors) {
            self.send('onUploadFailed', e, data);
          } else {
            self.send('onUploadComplete', e, data);
          }
        },
        start: function start(e) {
          self.send('onUploadStart', e);
        },
        fail: function fail(e, data) {
          self.send('onUploadFailed', e, data);
        },
        stop: function stop(e) {
          run(function () {
            if (get(self, 'onAllFinished')) {
              get(self, 'onAllFinished')(e);
            }
          });
        },
        always: function always(e) {
          self.send('onUploadFinished', e);
        }
      });
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
      try {
        this.$().fileupload('destroy');
      } catch (e) {
        Ember.Logger.error('Error destroying fileupload: ', e);
      }
    },


    actions: {
      onUploadFailed: function onUploadFailed(e, data) {
        this.get('ui').nativeAlert(get(this, 'i18n').t('qrcImageUpload.error'), get(this, 'i18n').t('qrcImageUpload.errorTitle'));
        Logger.error(e);
        Logger.error(data);
      },
      onUploadComplete: function onUploadComplete(e, data) {
        this.sendAction('photoUploaded', e, data);
      },
      uploadImage: function uploadImage() {
        if (!this.get('inactive')) {
          this.$('input').click();
        }
      },
      onUploadStart: function onUploadStart() {
        $().closest('input').find('.pictures-area').append('<div class="loading"></div>');
        this.sendAction('onUploadStart');
      },
      onUploadFinished: function onUploadFinished() {
        $().closest('input').find('.pictures-area .loading').remove();
        this.sendAction('onUploadFinished');
      }
    }
  });
});