define('desktop-frontend/components/feeds-container/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  var service = Ember.inject.service;
  var Component = Ember.Component;
  var Logger = Ember.Logger;
  exports.default = Component.extend({
    classNames: ['feeds-container'],
    store: service(),
    localStorage: service(),
    feeds: [],
    employmentPrefs: null,
    educationalPrefs: null,
    openWizard: '',
    showReasons: false,

    actions: {
      goToLink: function goToLink() {
        for (var _len = arguments.length, routeArgs = Array(_len), _key = 0; _key < _len; _key++) {
          routeArgs[_key] = arguments[_key];
        }

        var _routeArgs = _slicedToArray(routeArgs, 2),
            route = _routeArgs[0],
            id = _routeArgs[1];

        var responseId = void 0;
        var groupType = void 0;
        var url = void 0;

        switch (route) {
          case 'profile.index':
            url = '../profiles/' + id;
            break;

          case 'status-updates.show.index':
            url = '/status-updates/' + id;
            break;

          case 'shared-links.show.index':
            url = '/shared-links/' + id;
            break;

          case 'answers.show.index':
            url = '/answers/' + id;
            break;

          case 'answers.show.comments':
            var _routeArgs2 = _slicedToArray(routeArgs, 3);

            responseId = _routeArgs2[2];

            url = '/answers/' + id + '?urlhash=' + responseId;
            break;

          case 'status-updates.show.comments':
            var _routeArgs3 = _slicedToArray(routeArgs, 3);

            responseId = _routeArgs3[2];

            url = '/status-updates/' + id + '?urlhash=' + responseId;
            break;

          case 'shared-links.show.comments':
            var _routeArgs4 = _slicedToArray(routeArgs, 3);

            responseId = _routeArgs4[2];

            url = '/shared-links/' + id + '?urlhash=' + responseId;
            break;

          case 'groups.show':
            var _routeArgs5 = _slicedToArray(routeArgs, 3);

            groupType = _routeArgs5[1];
            id = _routeArgs5[2];

            url = '/' + groupType + '/' + id;
            break;

          default:
            return;
        }

        Logger.warn('Tried to open with goToLink ' + url);
      }
    }
  });
});