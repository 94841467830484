define('desktop-frontend/models/session', ['exports', 'ember-data', 'rp-common/models/session', 'desktop-frontend/utility/intersect-any'], function (exports, _emberData, _session, _intersectAny) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var gt = Ember.computed.gt;
  var belongsTo = _emberData.default.belongsTo,
      attr = _emberData.default.attr;
  exports.default = _session.default.extend({
    list_profile: belongsTo('list-profile', { async: true }),
    profile: belongsTo('profile', { async: true }),

    roles: attr('array'),
    completeness: attr('number'),
    company_admin_group_ids: attr('array'),

    reputation: attr('number'),
    reputation_percentile: attr('number'),
    reputation_rank: attr('number'),
    reputation_base: attr('number'),
    reputation_paygrade: attr('string'),
    reputation_activity_count: attr('number'),
    reputation_change: attr('number'),
    has_ribbon: attr('boolean'),

    adminRoles: ['admin', 'manager', 'business_development'],
    siteAdminRoles: ['admin'],
    isAdmin: (0, _intersectAny.default)('roles', 'adminRoles'),
    isSiteAdmin: (0, _intersectAny.default)('roles', 'siteAdminRoles'),

    recruiterRoles: ['basic_recruiter', 'silver_recruiter', 'gold_recruiter', 'platinum_recruiter', 'trial_recruiter', 'employer_searcher', 'recruiter_searcher', 'super_recruiter', 'premier_recruiter'],
    isRecruiter: (0, _intersectAny.default)('roles', 'recruiterRoles'),

    jobSeekerRoles: ['employer_searcher', 'premier_recruiter', 'recruiter_searcher', 'super_recruiter', 'admin'],
    isJobSeeker: (0, _intersectAny.default)('roles', 'jobSeekerRoles'),

    commandPostRoles: ['command_post'],
    canWriteCommandPost: (0, _intersectAny.default)('roles', 'commandPostRoles'),

    isCompanyAdmin: gt('company_admin_group_ids.length', 0)
  });
});