define('desktop-frontend/login/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Route = Ember.Route;
  var service = Ember.inject.service;
  var get = Ember.get;
  exports.default = Route.extend({
    currentSession: service(),

    beforeModel: function beforeModel() {
      if (get(this, 'currentSession.isSignedIn')) {
        this.transitionTo('home');
      }
    },
    model: function model() {
      return this.store.createRecord('session');
    }
  });
});