define('desktop-frontend/components/status-update-wizard/details/component', ['exports', 'desktop-frontend/config/environment', 'desktop-frontend/mixins/has-images', 'desktop-frontend/content-creation/service', 'desktop-frontend/mixins/mention-limit', 'ember-cp-validations'], function (exports, _environment, _hasImages, _service, _mentionLimit, _emberCpValidations) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var alias = Ember.computed.alias;
  var gt = Ember.computed.gt;
  var service = Ember.inject.service;
  var run = Ember.run;
  var set = Ember.set;
  var get = Ember.get;
  var computed = Ember.computed;


  var UpdateValidations = (0, _emberCpValidations.buildValidations)({
    'statusUpdate.body': {
      messageKey: 'inlineWizardValidations.statusUpdateValidationMessage', /* ember-i18n-cp-validations */
      validators: [(0, _emberCpValidations.validator)('presence', { presence: true, ignoreBlank: true, disabled: gt('model.pictures.length', 0) })]
    }
  });

  var MAX_BODY_LENGTH = get(_environment.default, 'qrcContent.maxBodyLength');
  var MAX_PICTURES = 10;
  var MAX_TAG_AMOUNT = 5;

  exports.default = Component.extend(UpdateValidations, _hasImages.default, _mentionLimit.default, {
    classNames: ['status-update-wizard'],
    i18n: service(),
    mentions: computed(function () {
      return [];
    }),

    saving: false,
    showErrors: false,
    nextActive: false,
    maxTagAmount: MAX_TAG_AMOUNT,
    maxPictures: MAX_PICTURES,
    statusLengthLimit: MAX_BODY_LENGTH,
    hasPictures: gt('pictures.length', 0),
    pictures: alias('statusUpdate.pictures'),
    showTagDisplay: gt('statusUpdate.tags.length', 0),

    store: service(),
    contentCreation: service(),

    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      this.focusInput();

      this.onFocusInput = this.focusInput.bind(this);
      get(this, 'contentCreation').on(_service.CONTENT_CREATION_EVENTS.CREATE_UPDATE, this, this.onFocusInput);
    },
    willDestroyElement: function willDestroyElement() {
      get(this, 'contentCreation').off(_service.CONTENT_CREATION_EVENTS.CREATE_UPDATE, this, this.onFocusInput);
      this.onFocusInput = null;
      this._super.apply(this, arguments);
    },
    focusInput: function focusInput() {
      this.$('.status-update-body').focus();
    },


    loadingPicture: computed(function () {
      var store = get(this, 'store');
      return store.createRecord('picture', { url: 'assets/images/spinner-star-gray.gif' });
    }),

    statusUpdate: computed(function () {
      var store = get(this, 'store');
      return store.createRecord('status-update');
    }),

    init: function init() {
      this._super.apply(this, arguments);
      this.initStatusUpdate();
    },
    initStatusUpdate: function initStatusUpdate() {
      var statusUpdate = get(this, 'store').createRecord('status-update');
      set(this, 'statusUpdate', statusUpdate);
    },


    actions: {
      onUploadStart: function onUploadStart() {
        get(this, 'pictures').pushObject(get(this, 'loadingPicture'));
      },
      onUploadFinished: function onUploadFinished() {
        get(this, 'pictures').removeObject(this.get('loadingPicture'));
        set(this, 'pictures', get(this, 'pictures').slice(0, MAX_PICTURES));
      },
      addTag: function addTag(tag) {
        var _this = this;

        var edTag = this.get('store').createRecord('tag', {
          name: tag.value,
          imagePath: tag.image_path,
          group_id: tag.id
        });

        get(this, 'statusUpdate.tags').pushObject(edTag);

        run.next(function () {
          _this.$('.tag-search-input').trigger('focus');
        });
      },
      hidingMention: function hidingMention() {
        if (!get(this, '_manualToggle')) {
          return;
        }

        set(this, '_manualToggle', false);

        var $body = this.$('.status-update-body');
        var body = get(this, 'statusUpdate.body');
        var idx = $body.get(0).selectionStart || body.length;

        if (body[idx - 1] === '@') {
          set(this, 'statusUpdate.body', '' + body.substr(0, idx - 1) + body.substr(idx));
        }
      },
      addImage: function addImage() {
        this.$('.photo-upload-container input[type=file]').trigger('click');
      },
      postUpdate: function postUpdate() {
        var _this2 = this;

        if (!get(this, 'validations.isValid')) {
          set(this, 'showErrors', true);
          return;
        }

        set(this, 'saving', true);

        get(this, 'statusUpdate').save().then(function (statusUpdate) {
          _this2.sendAction('statusUpdateCreated', statusUpdate);
          _this2.initStatusUpdate();
        }).catch(function (error) {
          /* eslint-disable no-console */
          console.error(error.stack);
          /* eslint-enable no-console */
          get(_this2, 'flashMessages').danger(get(_this2, 'i18n').t('newQuestionFlash.error'));
        }).finally(function () {
          set(_this2, 'saving', false);
        });
      }
    }
  });
});