define('desktop-frontend/adapters/list-feed', ['exports', 'rp-common/adapters/list-feed'], function (exports, _listFeed) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _listFeed.default;
    }
  });
});