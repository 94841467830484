define('desktop-frontend/services/ui', ['exports', 'rp-common/services/ui', 'desktop-frontend/config/environment'], function (exports, _ui, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var setProperties = Ember.setProperties;
  exports.default = _ui.default.extend({
    message: '',
    title: '',
    showNativeAlert: false,

    nativeAlert: function nativeAlert(message, title) {
      if (!_environment.default.APP.EMBER_ISLANDS.bypass) {
        window.alert(message);
      } else {
        setProperties(this, {
          message: message,
          title: title,
          showNativeAlert: true
        });
      }
    }
  });
});