define('desktop-frontend/components/comment-button/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var get = Ember.get;
  exports.default = Component.extend({
    tagName: 'button',
    classNames: ['comment-button-ui'],
    classNameBindings: ['hasCommented', 'commentOpen'],

    click: function click() {
      get(this, 'toggleComment')();
    }
  });
});