define('desktop-frontend/components/site-header-wrapper/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var filter = Ember.computed.filter;
  var service = Ember.inject.service;
  var get = Ember.get;
  var set = Ember.set;
  exports.default = Component.extend({
    classNames: ['ember-islands', 'site-header-wrapper'],
    islands: true,

    store: service(),

    incomingContactRequests: filter('contactRequests.@each.id', function (cr) {
      return get(cr, 'sender.id') !== get(this, 'currentSession.profile.id');
    }),

    didInsertElement: function didInsertElement() {
      var notifications = get(this, 'store').peekAll('notification');
      var contactRequests = get(this, 'store').peekAll('contact-request');
      var conversations = get(this, 'store').peekAll('list-conversation');
      set(this, 'notifications', notifications);
      set(this, 'conversations', conversations);
      set(this, 'contactRequests', contactRequests);
    }
  });
});