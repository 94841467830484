define('desktop-frontend/components/follow-group-button/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var service = Ember.inject.service;
  var get = Ember.get;
  var computed = Ember.computed;
  var alias = computed.alias;
  exports.default = Component.extend({
    classNames: ['follow-group-btn'],
    classNameBindings: ['followingGroup'],
    group: null,
    i18n: service(),
    followGroup: service(),
    followingGroup: alias('group.following'),
    click: function click() {
      if (get(this, 'group.following')) {
        get(this, 'followGroup').unfollow(get(this, 'group'));
      } else {
        get(this, 'followGroup').follow(get(this, 'group'));
      }
    },

    buttonText: computed('group.following', function () {
      return get(this, 'group.following') ? get(this, 'i18n').t('groups.followButton.whileFollowing') : get(this, 'i18n').t('groups.followButton.whileNotFollowing');
    })
  });
});