define('desktop-frontend/components/header-search/question-result/component', ['exports', 'desktop-frontend/components/header-search/base-result/component'], function (exports, _component) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var readOnly = Ember.computed.readOnly;
  var get = Ember.get;
  var computed = Ember.computed;
  exports.default = _component.default.extend({
    listQuestion: readOnly('listResult'),

    author: readOnly('listQuestion.list_profile'),
    href: readOnly('questionUrl'),

    questionUrl: computed('listQuestion.slug', function () {
      return '/answers/' + get(this, 'listQuestion.slug');
    })
  });
});