define('desktop-frontend/components/stigma-reduction/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  exports.default = Component.extend({
    showBRIMenu: false,

    actions: {
      hideBRIMenu: function hideBRIMenu() {
        this.set('showBRIMenu', false);
      },
      hideStigmaBanner: function hideStigmaBanner() {
        this.set('showStigmaBanner', false);
      },
      showBRIMenu: function showBRIMenu() {
        this.send('hideStigmaBanner');
        this.set('showBRIMenu', true);
        return false;
      }
    }
  });
});