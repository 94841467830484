define('desktop-frontend/utility/api-helper', ['exports', 'desktop-frontend/config/environment', 'ember-network/fetch'], function (exports, _environment, _fetch) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var $ = Ember.$;
  var isEmpty = Ember.isEmpty;
  var typeOf = Ember.typeOf;
  var EmberPromise = Ember.RSVP.Promise;
  var Em = Ember;


  function getHeaders() {
    var headers = {};
    if (_environment.default.APP.SESSION_TOKEN) {
      headers['RP-Access-Token'] = _environment.default.APP.SESSION_TOKEN;
    }
    headers['RP-Client-Id'] = _environment.default.APP.SECRET_KEY;
    if (_environment.default.APP.VERSION) {
      headers['RP-Version'] = _environment.default.APP.VERSION;
    }
    return headers;
  }

  function requestSettings() {
    return {
      method: 'GET',
      headers: getHeaders()
    };
  }

  function postRequestSettings() {
    var postHeaders = getHeaders();
    postHeaders['Content-Type'] = 'application/json';
    return {
      method: 'POST',
      headers: postHeaders
    };
  }

  // Treat 404 as an error
  // Consider wrapping in one Em.RSVP.Promise call
  function handleResponse(response) {
    if (response.status >= 400) {
      var errorMessage = response.statusText;
      return new EmberPromise(function (resolve, reject) {
        response.json().then(function (response) {
          // Error messages are conveyed differently throughout the system
          // we should fix this in API/V2
          if (response.message) {
            errorMessage = response.message;
          }

          if (response.data) {
            if (response.data.errors) {
              errorMessage = response.data.errors;
            }
          }
          reject(errorMessage);
        }, function () {
          reject(errorMessage);
        });
      });
    }
    return new EmberPromise(function (resolve) {
      response.json().then(function (response) {
        resolve(response);
      }, function () {
        resolve({});
      });
    });
  }

  function getApiPath(url, namespace) {
    if (!namespace.endsWith('/')) {
      namespace += '/';
    }
    if (namespace.indexOf('/') !== 0) {
      namespace = '/' + namespace;
    }
    return '' + namespace + url;
  }

  exports.default = {
    sendRequest: function sendRequest(url, successCallback, errorCallback) {
      var _this = this;

      var method = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : 'GET';
      (false && !(!isEmpty(successCallback) && typeOf(successCallback) === 'function') && Ember.assert('successCallback is required and should be a function', !isEmpty(successCallback) && typeOf(successCallback) === 'function'));

      var settings = requestSettings();
      settings.method = method;

      var fullPath = _environment.default.APP.ENDPOINT + '/api/v1/' + url;
      return (0, _fetch.default)(fullPath, settings).then(handleResponse).then(successCallback).catch(function (error) {
        Em.Logger.error(error);
        if (!isEmpty(errorCallback) && typeOf(errorCallback) === 'function') {
          errorCallback.call(_this, error);
        }
      });
    },


    // Basic post request with headers
    postRequest: function postRequest(url) {
      var data = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      var namespace = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 'api/v1';

      var settings = $.extend({}, postRequestSettings(), {
        body: JSON.stringify(data)
      });
      return (0, _fetch.default)(getApiPath(url, namespace), settings).then(handleResponse);
    },
    postV2Request: function postV2Request(url) {
      var data = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

      return this.postRequest(url, data, 'api/v2');
    },


    // Basic get requset with headers
    getRequest: function getRequest(url) {
      var namespace = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'api/v1';

      return (0, _fetch.default)(getApiPath(url, namespace), requestSettings()).then(handleResponse);
    },


    // V2 Get Request
    getV2Request: function getV2Request(url) {
      return this.getRequest(url, 'api/v2');
    }
  };
});