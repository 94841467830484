define('desktop-frontend/components/takeover-container/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var readOnly = Ember.computed.readOnly;
  var service = Ember.inject.service;
  exports.default = Component.extend({
    classNames: ['takeover-container'],
    classNameBindings: ['takeoverClass'],

    takeover: service(),

    activeTakeover: readOnly('takeover.activeTakeover'),
    takeoverClass: readOnly('activeTakeover.cssClass'),
    preContentComponent: readOnly('activeTakeover.preContentComponent')
  });
});