define('desktop-frontend/models/list-group', ['exports', 'rp-common/models/list-group', 'ember-data'], function (exports, _listGroup, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var alias = Ember.computed.alias;
  var attr = _emberData.default.attr;
  exports.default = _listGroup.default.extend({
    groupable_route_path: attr('string'),
    groupableLink: alias('groupable_route_path')
  });
});