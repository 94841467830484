define('desktop-frontend/services/invitation-contact-checker', ['exports', 'desktop-frontend/utility/api-helper'], function (exports, _apiHelper) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var later = Ember.run.later;
  var $ = Ember.$;
  var Service = Ember.Service;
  var service = Ember.inject.service;


  var DEFAULT_POLL_LIMIT = 10;
  var INITIAL_POLL_DELAY = 3000;
  var INTERVAL_POLL_DELAY = 1000;

  exports.default = Service.extend({

    localStorage: service('local-storage'),
    queueActive: false,

    _pollCount: 0,
    _pollLimit: null,
    _defaultStatus: {
      'facebook': 'ignored',
      'google': 'ignored'
    },
    _accountStatus: {},
    invitationToken: null,

    totalFound: 1,
    contactCount: 1,
    updatingCount: false,

    incrementCountDisplay: function () {
      var _this = this;

      var totalFound = +this.get('totalFound');
      var contactCount = +this.get('contactCount');
      var updatingCount = this.get('updatingCount');
      if (!updatingCount && totalFound > contactCount) {
        $('.cont-on .one-img').removeClass('throbbing');
        this.set('updatingCount', true);
        later(function () {
          $('.cont-on .one-img').addClass('throbbing');
        }, 50);
        this.incrementProperty('contactCount');
        later(function () {
          _this.incrementCountDisplay();
        }, 1000);
      } else {
        this.set('updatingCount', false);
      }
    }.observes('totalFound', 'contactCount').on('init'),

    _successCallback: function _successCallback() {
      var resultData = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

      Ember.Logger.debug(resultData);
      (false && !(false) && Ember.assert('You need to define a success callback of your own for the invitation contact checker.'));
    },
    _updateQueueStatus: function _updateQueueStatus(json) {
      for (var accountType in json) {
        this._accountStatus[accountType] = 'received';
      }
      this.get('localStorage').setItem('contactCheckerStatus', JSON.stringify(this.get('_accountStatus')));
    },
    initialize: function initialize() {
      var savedStatus = this.get('localStorage.data.contactCheckerStatus');
      var totalFound = this.get('localStorage.data.totalFound');
      if (savedStatus) {
        var parsedStatus = JSON.parse(savedStatus);
        this.set('_accountStatus', parsedStatus);
      }

      if (totalFound) {
        this.set('totalFound', +totalFound);
      }
    },
    resetStatus: function resetStatus() {
      this.set('_accountStatus', this.get('_defaultStatus'));
      this.get('localStorage').setItem('totalFound', 1);
    },
    setToken: function setToken(token) {
      this.set('invitationToken', token);
    },
    queueAccountCheck: function queueAccountCheck(accountType, token, redirectUrl) {
      var uid = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : null;

      this.set('queueActive', true);
      this._accountStatus[accountType] = 'requested';
      var url = 'invitations/' + this.invitationToken + '/social_invite'; // calls google plus api
      var postBody = 'accountType=' + accountType + '&authorizationCode=' + token + '&redirectUrl=' + redirectUrl + '&uid=' + uid;
      return _apiHelper.default.postV2Request(url, postBody);
    },
    requestsQueued: function requestsQueued() {
      for (var accountType in this._accountStatus) {
        var value = this._accountStatus[accountType];
        if (value === 'requested') {
          return true;
        }
      }
      return false;
    },
    startPolling: function startPolling(token) {
      var limit = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : DEFAULT_POLL_LIMIT;

      if (this.requestsQueued()) {
        this._pollCount = 0;
        this._pollLimit = limit;
        later(this, this.pollService, INITIAL_POLL_DELAY);
      } else if (this.get('queueActive')) {
        this.set('queueActive', false);
      }
    },
    pollService: function pollService() {
      var _this2 = this;

      _apiHelper.default.getV2Request('invitations/' + this.invitationToken + '/social_invite_status').then(function (data) {
        return _this2.handleResponse(data);
      }).catch(Ember.Logger.error);
    },
    handleResponse: function handleResponse(json) {
      this._updateQueueStatus(json);
      this.set('totalFound', json.total);
      this.get('localStorage').setItem('totalFound', json.total);
      if (this._pollCount < this._pollLimit) {
        later(this, this.pollService, INTERVAL_POLL_DELAY);
        this.incrementProperty('_pollCount');
      }
    }
  });
});