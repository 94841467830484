define('desktop-frontend/components/header-contact-requests/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var gt = Ember.computed.gt;
  var Component = Ember.Component;
  var set = Ember.set;
  exports.default = Component.extend({
    classNames: ['header-contact-requests'],

    contactRequests: null,
    unreadCount: 0,

    showPopup: false,
    loadedOnce: false, // Used to prevent prefetching requests until user hovers

    hasUnread: gt('unreadCount', 0),

    mouseEnter: function mouseEnter() {
      set(this, 'showPopup', true);
      set(this, 'loadedOnce', true);
    },
    mouseLeave: function mouseLeave() {
      set(this, 'showPopup', false);
    }
  });
});