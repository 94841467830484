define('desktop-frontend/components/contacts-list/profile-item-wrapper/component', ['exports', 'desktop-frontend/mixins/row-item'], function (exports, _rowItem) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  exports.default = Component.extend(_rowItem.default, {
    classNames: ['contacts-list-profile-item']
  });
});