define('desktop-frontend/mixins/social-connectedness', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var readOnly = Ember.computed.readOnly;
  var notEmpty = Ember.computed.notEmpty;
  var or = Ember.computed.or;
  var and = Ember.computed.and;
  var Mixin = Ember.Mixin;
  exports.default = Mixin.create({
    gmailConnectedExisting: readOnly('currentSession.user.has_gmail'),
    facebookConnectedExisting: readOnly('currentSession.user.has_facebook'),

    gmailConnectedNew: notEmpty('registration.google_token'),
    facebookConnectedNew: notEmpty('registration.facebook_token'),

    gmailConnected: or('gmailConnectedNew', 'gmailConnectedExisting'),
    facebookConnected: or('facebookConnectedNew', 'facebookConnectedExisting'),

    emailFilledIn: notEmpty('registration.email'),
    firstNameFilledIn: notEmpty('registration.first_name'),
    lastNameFilledIn: notEmpty('registration.last_name'),
    sociallyAuthenticated: or('gmailConnectedNew', 'facebookConnectedNew'),
    autoRegister: and('sociallyAuthenticated', 'emailFilledIn', 'firstNameFilledIn', 'lastNameFilledIn'),
    atLeastOneConnectedNetwork: or('gmailConnected', 'facebookConnected'),
    allConnected: and('gmailConnected', 'facebookConnected')
  });
});