define('desktop-frontend/adapters/hide-feed-item-ticket', ['exports', 'rp-common/adapters/hide-feed-item-ticket'], function (exports, _hideFeedItemTicket) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _hideFeedItemTicket.default;
    }
  });
});