define('desktop-frontend/services/control-center', ['exports', 'rp-common/services/base-control-center'], function (exports, _baseControlCenter) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var set = Ember.set;
  var get = Ember.get;
  exports.default = _baseControlCenter.default.extend({
    shownMessages: null,

    flashMessageWrapper: function flashMessageWrapper(method, message) {
      this._super(method, message, { sticky: true });
    },


    // This first time this is called, it notes the reputation messages that
    // exist, marking them as shown. Subsequently it removes all remembered messages and
    // marks all new ones as shown. Designed to be called only once per page
    // transition. It is designed to allow frequent and unpredictable changes to reputation
    // messages while still removing seen messages in a timely manner.
    swapOutShownReputationMessages: function swapOutShownReputationMessages() {
      if (!get(this, 'shownMessages')) {
        set(this, 'shownMessages', get(this, 'reputationMessages').slice(0));
        return;
      }
      get(this, 'reputationMessages').removeObjects(get(this, 'shownMessages'));
      get(this, 'shownMessages').map(function (m) {
        return m.unloadRecord();
      });
      get(this, 'shownMessages').clear().pushObjects(get(this, 'reputationMessages'));
    }
  });
});