define('desktop-frontend/components/feed-mention-profile-name/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var computed = Ember.computed;
  exports.default = Component.extend({
    tagName: 'a',
    classNames: ['mention-name'],
    attributeBindings: ['href', 'target'],
    mention: null,

    href: computed('mention.slug', function () {
      return '/profiles/' + this.get('mention.slug');
    })
  });
});