define('desktop-frontend/components/header-inbox/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var gt = Ember.computed.gt;
  var Component = Ember.Component;
  var service = Ember.inject.service;
  exports.default = Component.extend({
    classNames: ['header-inbox', 'js-header-drop-down-wrapper'],
    conversations: null,
    unreadCount: 0,

    showConversations: false,
    loadedOnce: false, // Used to prevent prefetching conversations until user hovers

    headerMenu: service(),

    hasUnread: gt('unreadCount', 0)
  });
});