define('desktop-frontend/components/simple-date-picker/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed;
  var Component = Ember.Component;
  exports.default = Component.extend({
    classNames: ['date-picker'],
    months: [{ value: '', label: 'Month' }, { value: 1, label: 'Jan' }, { value: 2, label: 'Feb' }, { value: 3, label: 'Mar' }, { value: 4, label: 'Apr' }, { value: 5, label: 'May' }, { value: 6, label: 'Jun' }, { value: 7, label: 'Jul' }, { value: 8, label: 'Aug' }, { value: 9, label: 'Sep' }, { value: 10, label: 'Oct' }, { value: 11, label: 'Nov' }, { value: 12, label: 'Dec' }],

    years: computed(function () {
      var currentYear = new Date().getFullYear();
      var startYear = currentYear - 100;
      var allYears = [{ value: '', label: 'Year' }];

      for (var year = currentYear; year >= startYear; year--) {
        allYears.push({ value: year, label: year });
      }

      return allYears;
    })
  });
});