define('desktop-frontend/components/message-user-modal/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var alias = Ember.computed.alias;
  var service = Ember.inject.service;
  var set = Ember.set;
  var get = Ember.get;
  var computed = Ember.computed;
  exports.default = Component.extend({
    conversation: null,
    profile: null,
    serverError: null,
    submitAttempted: false,
    i18n: service(),
    store: service(),
    flashMessages: service(),
    controlCenter: service(),

    isValid: alias('conversation.validations.isValid'),
    validationMessages: alias('conversation.validations.messages'),

    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      set(this, 'conversation', get(this, 'store').createRecord('conversation', {
        profile: get(this, 'currentSession.profile'),
        initialRecipients: [get(this, 'profile.id')]
      }));
    },
    willDestroy: function willDestroy() {
      get(this, 'conversation').rollbackAttributes();
      this._super.apply(this, arguments);
    },


    modalTitle: computed('profile.rank', 'profile.name', function () {
      return 'Message to ' + get(this, 'profile.rank') + ' ' + get(this, 'profile.full_name');
    }),

    errorMessage: computed('isValid', 'validationMessages.[]', 'serverError', function () {
      var serverError = get(this, 'serverError');
      if (serverError) {
        return serverError;
      }
      if (!get(this, 'isValid') && get(this, 'validationMessages.length')) {
        return get(this, 'validationMessages.firstObject');
      }
      return null;
    }),

    actions: {
      sendMessage: function sendMessage() {
        var _this = this;

        set(this, 'submitAttempted', true);
        if (!get(this, 'isValid')) {
          var validationError = get(this, 'validationMessages.firstObject');
          set(this, 'errorMessage', validationError);
          return;
        }
        get(this, 'conversation').save().then(function () {
          get(_this, 'flashMessages').success(get(_this, 'i18n').t('contacts.messageUser.success'));
          get(_this, 'controlCenter').update();
          _this.sendAction('closeMessageUserModal');
        }).catch(function (err) {
          var submissionError = get(_this, 'i18n').t('contacts.messageUser.submissionErrorMessage');
          set(_this, 'serverError', submissionError);
          _this.reportError(err);
        });
      },
      cancel: function cancel() {
        this.sendAction('closeMessageUserModal');
      }
    }
  });
});