define('desktop-frontend/components/header-inbox/inbox-popout/component', ['exports', 'desktop-frontend/mixins/components/ember-infinity', 'ember-awesome-macros', 'ember-awesome-macros/raw'], function (exports, _emberInfinity, _emberAwesomeMacros, _raw) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var lt = Ember.computed.lt;
  var sort = Ember.computed.sort;
  var get = Ember.get;
  exports.default = Component.extend(_emberInfinity.default, {
    classNames: ['inbox-popout'],
    classNameBindings: ['noMessages'],

    conversations: null,
    modelName: 'list-conversation',
    modelPath: 'conversations',
    perPage: 8,

    loadMorePages: lt('_currentPage', 1),
    noMessages: (0, _emberAwesomeMacros.conditional)('_loadingInfinity', false, (0, _emberAwesomeMacros.lte)('conversations.length', 0)),
    seeAllKey: (0, _emberAwesomeMacros.conditional)('noMessages', (0, _raw.default)('header.inbox.goToInbox'), (0, _raw.default)('header.inbox.seeAll')),

    sortedConversations: sort('conversations', function (a, b) {
      if (get(a, 'unread') !== get(b, 'unread')) {
        return get(b, 'unread') - get(a, 'unread');
      }
      return get(b, 'updated_at') - get(a, 'updated_at');
    })
  });
});