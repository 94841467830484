define('desktop-frontend/components/drop-down/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var set = Ember.set;
  var get = Ember.get;
  exports.default = Component.extend({
    tagName: 'span',
    classNames: ['drop-down'],
    classNameBindings: ['showPlaceholder:placeholder'],

    placeholder: 'Select one...',
    options: [],
    value: null,

    showPlaceholder: true,

    init: function init() {
      this._super.apply(this, arguments);
      set(this, 'showPlaceholder', get(this, 'value') === null);
    },


    actions: {
      onChange: function onChange() {
        var $select = this.$('select');
        set(this, 'showPlaceholder', $select.get(0).selectedIndex === 0);
        this.attrs.onChange($select.val());
      }
    }
  });
});