define('desktop-frontend/mixins/google-pageview', ['exports', 'desktop-frontend/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    pageviewToGA: function () {
      if (Ember.get(_environment.default, 'googleAnalytics.webPropertyId') != null) {
        var trackerType = Ember.getWithDefault(_environment.default, 'googleAnalytics.tracker', 'analytics.js');

        if (trackerType === 'analytics.js') {
          var globalVariable = Ember.getWithDefault(_environment.default, 'googleAnalytics.globalVariable', 'ga');

          window[globalVariable]('send', 'pageview', {
            page: this.get('url'),
            title: this.get('url')
          });
        } else if (trackerType === 'ga.js') {
          window._gaq.push(['_trackPageview']);
        }
      }
    }.on('didTransition')
  });
});