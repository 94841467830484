define('desktop-frontend/components/header-inbox/conversation-item/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var readOnly = Ember.computed.readOnly;
  var get = Ember.get;
  var computed = Ember.computed;
  exports.default = Component.extend({
    tagName: 'li',
    classNames: ['conversation-item', 'header-generic-message'],
    classNameBindings: ['unread', 'withPremium'],

    conversation: null,

    lastRecipient: readOnly('conversation.recipientsExceptCurrent.lastObject'),
    unread: readOnly('conversation.unread'),
    withPremium: readOnly('conversation.withPremium'),

    conversationUrl: computed('conversation.hash', 'conversations.latest_message_receipt_hash', function () {
      return '/conversations/' + get(this, 'conversation.hash') + '/' + get(this, 'conversation.latest_message_receipt_hash');
    })
  });
});