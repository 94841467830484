define('desktop-frontend/components/site-header/component', ['exports', 'desktop-frontend/header-menu/service', 'ember-concurrency'], function (exports, _service, _emberConcurrency) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var $ = Ember.$;
  var Component = Ember.Component;
  var service = Ember.inject.service;
  var alias = Ember.computed.alias;
  var get = Ember.get;
  var set = Ember.set;
  exports.default = Component.extend({
    classNames: ['site-header'],
    tagName: 'header',

    companyUrl: null,
    loadedLogo: null,
    homeMenu: _service.homeIsOpen,
    resourcesMenu: _service.resourcesIsOpen,
    offersMenu: _service.offersIsOpen,
    profileMenu: _service.profileIsOpen,
    businessMenu: _service.businessIsOpen,
    notificationsMenu: _service.notificationsIsOpen,
    messagesMenu: _service.messagesIsOpen,

    controlCenter: service(),
    headerMenu: service(),
    store: service(),
    imageLoader: service(),
    environment: service(),

    isIsland: alias('environment.isIsland'),

    didInsertElement: function didInsertElement() {
      this.commandCenterLink();
      this._super.apply(this, arguments);
    },
    init: function init() {
      get(this, 'loadHeaderLogo').perform();
      this._super.apply(this, arguments);
    },


    commandCenterPath: alias('companyUrl'),
    unreadConversations: alias('controlCenter.unreadMessages'),
    unreadNotifications: alias('controlCenter.unreadNotifications'),
    unreadContactRequests: alias('controlCenter.pendingContactRequests'),
    unreadCommandPosts: alias('controlCenter.unreadCommandPosts'),

    loadHeaderLogo: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function _callee() {
      var loadedImg;
      return regeneratorRuntime.wrap(function _callee$(_context) {
        while (1) {
          switch (_context.prev = _context.next) {
            case 0:
              _context.next = 2;
              return get(this, 'imageLoader').loadInBackground('assets/images/white-logo.png', { 'aria-hidden': true });

            case 2:
              loadedImg = _context.sent;

              set(this, 'loadedLogo', loadedImg);

            case 4:
            case 'end':
              return _context.stop();
          }
        }
      }, _callee, this);
    })),

    commandCenterLink: function commandCenterLink() {
      var _this = this;

      var adminableCompanyId = get(this, 'currentSession.session.company_admin_group_ids.firstObject');
      return adminableCompanyId && get(this, 'store').findRecord('list-group', adminableCompanyId).then(function (company) {
        set(_this, 'companyUrl', get(company, 'url') + '/admin/jobs');
      });
    },


    actions: {
      toggleMenu: function toggleMenu(menu, _eVar62, evt) {
        var $target = $(evt.target);
        if (get(this, 'headerMenu.' + menu) && !($target.is('.js-no-menu-close') || $target.parents('.js-no-menu-close').length)) {
          get(this, 'headerMenu').hideAll();
        } else {
          get(this, 'headerMenu').showMenu(menu);
        }
      }
    }
  });
});