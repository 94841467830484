define('desktop-frontend/components/group-sort-options/sort-option/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var get = Ember.get;
  var computed = Ember.computed;
  var alias = Ember.computed.alias;
  exports.default = Component.extend({
    tagName: 'li',
    classNameBindings: ['active', 'sortValue'],
    sortOption: null,
    activeSortOrder: null,

    sortValue: alias('sortOption.value'),

    active: computed('sortOption.value', 'activeSortOrder', function () {
      return get(this, 'sortOption.value') === get(this, 'activeSortOrder');
    })
  });
});