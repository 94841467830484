define('desktop-frontend/components/contacts-sidebar/component', ['exports', 'desktop-frontend/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var get = Ember.get;
  var computed = Ember.computed;
  var service = Ember.inject.service;
  exports.default = Component.extend({
    classNames: ['contacts-sidebar-wrapper'],
    environment: service(),
    invitationLink: service(),

    profileInviteUrl: computed('currentUser.profile.slug', function () {
      return get(this, 'invitationLink').create(get(this, 'currentSession.profile'));
    }),

    facebookUrl: computed('profileInviteUrl', function () {
      var facebookAppId = get(_environment.default, 'torii.providers.facebook-connect.appId');
      return 'https://www.facebook.com/dialog/send?app_id=' + facebookAppId + '&link=' + get(this, 'profileInviteUrl') + '&redirect_uri=' + window.location.href;
    })
  });
});