define('desktop-frontend/components/grey-to-blue-button/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  exports.default = Component.extend({
    classNames: ['grey-to-blue-button'],
    classNameBindings: ['beBlue'],
    tagName: 'button',
    beBlue: false,

    click: function click() {
      this.sendAction('clickAction');
    }
  });
});