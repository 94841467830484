define('desktop-frontend/contacts/index/controller', ['exports', 'desktop-frontend/contacts/index/route'], function (exports, _route) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Controller = Ember.Controller;
  var set = Ember.set;
  var get = Ember.get;
  var filter = Ember.computed.filter;
  var and = Ember.computed.and;
  var equal = Ember.computed.equal;
  var not = Ember.computed.not;
  var service = Ember.inject.service;
  exports.default = Controller.extend({
    queryParams: ['sort', 'search'],
    sort: 'recent',
    search: '',
    contactsPageSize: _route.CONTACTS_PAGE_SIZE,
    infinityContactsReached: false, /* set by route action */
    loadingMoreContacts: false, /* set by route action */
    contactsListCollapsed: false, /* reset by route on sort/filter */
    contactsListHasCollapsed: false, /* reset by route on sort/filter */
    loadingFirstContacts: false, /* set by route loading event */

    oneContactPage: equal('model.contacts.meta.total_pages', 1),
    multipleContactPages: not('oneContactPage'),
    notReachedInfinityContacts: not('infinityContactsReached'),
    canLoadMoreContacts: and('multipleContactPages', 'notReachedInfinityContacts'),

    currentSession: service(),

    incomingContactRequests: filter('model.contactRequests.@each.id', function (cr) {
      return get(cr, 'sender.id') !== get(this, 'currentSession.profile.id');
    }),

    actions: {
      contactsSorted: function contactsSorted(sort) {
        set(this, 'sort', sort);
      }
    }
  });
});