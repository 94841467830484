define('desktop-frontend/mixins/caret-adjustment', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Mixin = Ember.Mixin;
  exports.default = Mixin.create({
    setCaret: function setCaret(ele, idx) {
      if (document.selection) {
        var range = ele.createTextRange();
        range.move('character', idx);
        range.select();
      } else {
        ele.setSelectionRange(idx, idx);
      }
    }
  });
});