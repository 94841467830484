define('desktop-frontend/components/header-search/group-result/component', ['exports', 'desktop-frontend/components/header-search/base-result/component'], function (exports, _component) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var readOnly = Ember.computed.readOnly;
  var get = Ember.get;
  var computed = Ember.computed;
  exports.default = _component.default.extend({
    hasImage: true,

    listGroupSearchResult: readOnly('listResult'),
    listGroup: readOnly('listGroupSearchResult.list_group'),
    groupUrl: readOnly('listGroup.groupableLink'),
    href: readOnly('groupUrl'),

    groupImage: computed('listGroupSearchResult.image', function () {
      var src = get(this, 'listGroupSearchResult.image');
      if (!src || /default.group_image_medium/.test(src)) {
        return false;
      }
      return src;
    }),

    groupName: computed('listGroupSearchResult.full_name', 'listGroup.name', function () {
      if (get(this, 'listGroupSearchResult.full_name')) {
        return get(this, 'listGroupSearchResult.full_name');
      }

      return get(this, 'listGroup.name');
    })
  });
});