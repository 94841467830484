define('desktop-frontend/components/new-group-discussion/component', ['exports', 'desktop-frontend/components/new-custom-discussion/component'], function (exports, _component) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var bool = Ember.computed.bool;
  var notEmpty = Ember.computed.notEmpty;
  var service = Ember.inject.service;
  var set = Ember.set;
  var get = Ember.get;
  var run = Ember.run;
  exports.default = _component.default.extend({
    classNames: ['new-group-discussion', 'container-card'],
    tag: null,
    group: null,

    privateGroup: bool('group.is_private'),
    isVisible: notEmpty('permission.newGroupDiscussionRecord'),

    permission: service(),
    localStorage: service(),

    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      this.checkForUrlToShare();
    },


    actions: {
      focusIn: function focusIn() {
        if (get(this, 'currentSession.session.reputation') < get(this, 'permission.groupDiscussionPoints')) {
          get(this, 'ui').nativeAlert(get(this, 'permission.groupDiscussionError'), get(this, 'i18n').t('answers.reputation.errorHeading'));
          return;
        }

        set(this, 'expanded', true);
      },
      postUpdate: function postUpdate() {
        var _this = this;

        if (!get(this, 'statusValid') || get(this, 'saving')) {
          return;
        }
        var store = get(this, 'store');
        var statusUpdate = store.createRecord('status-update', get(this, 'statusUpdate'));
        set(this, 'statusUpdate.body', '');
        set(this, 'statusUpdate.pictures', []);
        set(this, 'saving', true);

        var tag = get(this, 'tag');
        statusUpdate.get('tags').pushObject(tag);

        statusUpdate.save().then(function (statusUpdate) {
          _this.statusUpdateCreated(statusUpdate);
        }).catch(function () {
          _this.removeEncodedMentions();
        }).finally(function () {
          set(_this, 'saving', false);
        });
      }
    },

    checkForUrlToShare: function checkForUrlToShare() {
      var _this2 = this;

      if (get(this, 'localStorage').getItem('urlToShare')) {
        set(this, 'statusUpdate.body', get(this, 'localStorage').getItem('urlToShare'));
        get(this, 'localStorage').removeItem('urlToShare');
        run.next(function () {
          _this2.$('textarea').focus().val(get(_this2, 'statusUpdate.body'));
        });
      }
    }
  });
});