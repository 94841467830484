define('desktop-frontend/answers/index/route', ['exports', 'ember-infinity/mixins/route', 'rp-common/mixins/infinite-scroll-analytics', 'rp-common/services/trackers/qrc', 'rp-common/mixins/discussion-tracking-route'], function (exports, _route, _infiniteScrollAnalytics, _qrc, _discussionTrackingRoute) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Route = Ember.Route;
  exports.default = Route.extend(_route.default, _infiniteScrollAnalytics.default, _discussionTrackingRoute.FeedDiscussionTrackingMixin, {
    impressionLocation: _qrc.ANSWERS_FEED,

    model: function model() {
      var _paramsFor = this.paramsFor('answers'),
          order = _paramsFor.order;

      return this.infinityModel('list-question-feed', {
        perPage: 10,
        startingPage: 1,
        order: order
      });
    }
  });
});