define('desktop-frontend/components/validated-input/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var and = Ember.computed.and;
  var not = Ember.computed.not;
  var Component = Ember.Component;
  exports.default = Component.extend({
    classNames: ['validated-input'],
    classNameBindings: ['showErrorMessage:has-error', 'hasFocus:focused', 'loudErrors:hilight-error'],
    valid: true,
    blurred: false,
    hasFocus: false,
    loudErrors: and('blurred', 'invalid'),
    invalid: not('valid'),
    showErrorMessage: and('invalid', 'validationMessage'),
    validationMessage: null,
    didInsertElement: function didInsertElement() {
      var _this = this;

      this.$('input').focus(function () {
        _this.toggleProperty('hasFocus');
      }).blur(function () {
        _this.toggleProperty('hasFocus');
        _this.set('blurred', true);
      });
    }
  });
});