define('desktop-frontend/components/feed-response-ui/component', ['exports', 'desktop-frontend/mixins/mention-limit'], function (exports, _mentionLimit) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var run = Ember.run;
  var set = Ember.set;
  var get = Ember.get;
  var alias = Ember.computed.alias;
  var gt = Ember.computed.gt;
  exports.default = Component.extend(_mentionLimit.default, {
    classNames: ['feed-response-ui'],
    formInvalid: false,

    mentionBody: alias('body'),
    hasPictures: gt('pictures.length', 0),

    actions: {
      toggleMention: function toggleMention() {
        var _this = this;

        var bodyEle = this.$('.response-body').get(0);
        var body = get(this, 'body');
        var idx = bodyEle.selectionStart || body.length;
        var spaceNeeded = body[idx - 2] !== ' ';

        if (body[idx - 1] !== '@') {
          set(this, 'body', '' + body.substr(0, idx) + (spaceNeeded ? ' ' : '') + '@' + body.substr(idx));
        }

        run.scheduleOnce('afterRender', function () {
          bodyEle.focus();
          bodyEle.selectionStart = bodyEle.selectionEnd = idx + (spaceNeeded ? 2 : 1);
          _this.$('.response-body').trigger('mentions:toggle');
        });

        set(this, '_manualToggle', true);
      },
      hidingMention: function hidingMention() {
        if (!this.get('_manualToggle')) {
          return;
        }

        this.set('_manualToggle', false);

        var $body = this.$('.response-body');
        var body = this.get('body');
        var idx = $body.get(0).selectionStart || body.length;

        if (body[idx - 1] === '@') {
          this.set('body', '' + body.substr(0, idx - 1) + body.substr(idx));
        }
      }
    }
  });
});