define('desktop-frontend/components/header-logged-out-sign-up/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  exports.default = Component.extend({
    classNames: ['logged-out-sign-up']
  });
});