define('desktop-frontend/components/sidebar-jobs-list/job-item/component', ['exports', 'desktop-frontend/components/feed-jobs-list/job-item/component'], function (exports, _component) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var set = Ember.set;
  exports.default = _component.default.extend({
    actions: {
      openJobApplicationForm: function openJobApplicationForm() {
        set(this, 'jobApplicationOpen', true);
      },
      applicationClosed: function applicationClosed() {
        set(this, 'jobApplicationOpen', false);
      }
    }
  });
});