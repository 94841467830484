define('desktop-frontend/components/group-resources/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var service = Ember.inject.service;
  var Component = Ember.Component;
  var computed = Ember.computed;
  var get = Ember.get;
  exports.default = Component.extend({
    classNames: ['container-card', 'group-resources'],
    i18n: service(),
    resources: null,
    resourceCenter: null,
    headerText: computed('resourceCenter', function () {
      return get(this, 'i18n').t('resourceCenter.' + get(this, 'resourceCenter') + '.resourcesHeader');
    })
  });
});