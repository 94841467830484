define('desktop-frontend/offers/index/route', ['exports', 'ember-infinity/mixins/route', 'desktop-frontend/mixins/reset-scroll-route'], function (exports, _route, _resetScrollRoute) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Route = Ember.Route;
  var service = Ember.inject.service;
  exports.default = Route.extend(_route.default, _resetScrollRoute.default, {
    tracker: service('trackers/generic'),

    model: function model() {
      return this.infinityModel('offer', {
        perPage: 9,
        startingPage: 1
      });
    }
  });
});