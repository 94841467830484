define('desktop-frontend/components/sidebar-container/component', ['exports', 'rp-common/components/google-ad/component'], function (exports, _component) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  exports.default = Component.extend({
    classNames: ['sidebar-container'],
    model: null,

    bottomAdSizes: _component.SIDEBAR_WITH_LONG_SLOT_SIZE
  });
});