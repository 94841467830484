define('desktop-frontend/components/header-messages/component', ['exports', 'rp-common/services/trackers/aggregator'], function (exports, _aggregator) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _asyncToGenerator(fn) {
    return function () {
      var gen = fn.apply(this, arguments);
      return new Promise(function (resolve, reject) {
        function step(key, arg) {
          try {
            var info = gen[key](arg);
            var value = info.value;
          } catch (error) {
            reject(error);
            return;
          }

          if (info.done) {
            resolve(value);
          } else {
            return Promise.resolve(value).then(function (value) {
              step("next", value);
            }, function (err) {
              step("throw", err);
            });
          }
        }

        return step("next");
      });
    };
  }

  var Component = Ember.Component;
  var get = Ember.get;
  var set = Ember.set;
  var alias = Ember.computed.alias;
  var service = Ember.inject.service;
  exports.default = Component.extend({
    tagName: '',
    location: '',
    lastLocation: '',
    controlCenter: service(),
    genericTracker: service('trackers/generic'),

    announcements: alias('controlCenter.announcements'),
    reputationMessages: alias('controlCenter.reputationMessages'),

    actions: {
      dismissAnnouncement: function dismissAnnouncement(announcement) {
        get(this, 'announcements').removeObject(announcement);
        announcement.destroyRecord();
      },
      dismissRepMessage: function dismissRepMessage(message) {
        get(this, 'reputationMessages').removeObject(message);
        message.unloadRecord();
      },
      trackClick: function () {
        var _ref = _asyncToGenerator(regeneratorRuntime.mark(function _callee(announcement) {
          return regeneratorRuntime.wrap(function _callee$(_context) {
            while (1) {
              switch (_context.prev = _context.next) {
                case 0:
                  _context.next = 2;
                  return get(this, 'genericTracker').trackClick(_aggregator.ANNOUNCEMENT, announcement.get('id'), get(this, 'location'));

                case 2:
                case 'end':
                  return _context.stop();
              }
            }
          }, _callee, this);
        }));

        function trackClick(_x) {
          return _ref.apply(this, arguments);
        }

        return trackClick;
      }()
    },

    didRender: function didRender() {
      this._super.apply(this, arguments);
      // this is an anti-pattern, however, this seems to be the
      // only hook where we can reliably observe changes to the
      // announcements property.
      var loc = get(this, 'location');
      var ploc = get(this, 'lastLocation');
      if (!loc || loc === ploc) {
        return;
      }

      var tracker = get(this, 'genericTracker');
      get(this, 'announcements').forEach(function (item) {
        tracker.trackImpression(_aggregator.ANNOUNCEMENT, item.get('id'), loc);
      });
      set(this, 'lastLocation', loc);
    }
  });
});