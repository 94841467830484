define('desktop-frontend/components/takeovers/afr-pre/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var get = Ember.get;
  var service = Ember.inject.service;


  var AFR_URL = 'https://AFReserve.com/rallp';

  exports.default = Component.extend({
    classNames: ['afr-pre'],

    takeoverTracker: service('trackers/takeover'),

    actions: {
      goToAFR: function goToAFR(source) {
        get(this, 'takeoverTracker').trackClick('afr', source);
        window.open(AFR_URL, '_blank');
      }
    }
  });
});