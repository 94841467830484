define('desktop-frontend/components/header-notifications/notification-item/component', ['exports', 'rp-common/utils/qrc-utils', 'desktop-frontend/utility/notification-helper'], function (exports, _qrcUtils, _notificationHelper) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var gte = Ember.computed.gte;
  var equal = Ember.computed.equal;
  var or = Ember.computed.or;
  var readOnly = Ember.computed.readOnly;
  var empty = Ember.computed.empty;
  var and = Ember.computed.and;
  var isEmpty = Ember.isEmpty;
  var service = Ember.inject.service;
  var computed = Ember.computed;
  var get = Ember.get;
  var Component = Ember.Component;
  exports.default = Component.extend({
    tagName: 'a',
    attributeBindings: ['href', 'style', 'target'],
    classNames: ['notification-item', 'header-generic-message'],
    classNameBindings: ['unread', 'notificationClass'],

    note: null,
    index: 0,
    fallbackPostText: '',

    i18n: service(),
    sponsoredPostTracker: service('trackers/sponsored-post'),
    qrcTracker: service('trackers/qrc'),

    bigPoints: gte('note.points', 100),
    newGroupDiscussion: equal('note.type', 'new_group_discussion'),
    inviteMessage: equal('note.type', 'invited_to_group'),
    joinMessage: equal('note.type', 'joined_private_group'),
    privateGroupRelated: or('inviteMessage', 'joinMessage'),
    groupRelated: or('privateGroupRelated', 'newGroupDiscussion'),
    listModel: readOnly('note.list_discussion'),
    fullModel: readOnly('listModel.full_model'),
    civilianCompany: readOnly('note.list_discussion.sponsored_post_group'),
    sponsoredPostImage: readOnly('civilianCompany.logo_image'),
    sponsoredPostCompanyName: readOnly('civilianCompany.name'),
    sponsoredPostUrl: readOnly('civilianCompany.url'),
    externalCTAUrl: readOnly('note.list_discussion.sponsored_post_url'),
    noListCommentDiscussionTitle: empty('note.list_comment_discussion.title'),
    noListDiscussionTitle: empty('note.list_discussion.title'),
    noListResponseDiscussionTitle: empty('note.list_response_discussion.title'),
    noTitle: and('noListCommentDiscussionTitle', 'noListDiscussionTitle', 'noListResponseDiscussionTitle'),

    notificationUrl: computed('discussionUrl', 'note.{type,list_profile.profileLink}', function () {
      if (get(this, 'discussionUrl')) {
        return get(this, 'discussionUrl');
      }

      var type = get(this, 'note.type');
      if (type === 'new_poke' || type === 'new_salute') {
        return get(this, 'note.list_profile.profileLink');
      }

      return null;
    }),

    groupUpdateMessage: computed('fullModel.body', 'fullModel.link_data.title', 'listModel.title', function () {
      if (this.get('fullModel.link_data.title.length') && /^http/.test(this.get('fullModel.body'))) {
        return this.get('fullModel.link_data.title');
      }

      return this.get('listModel.title') || this.formatMentions(this.get('fullModel.body'));
    }),

    notificationClass: computed('note.general', function () {
      return 'notification-' + get(this, 'note.general');
    }),

    showPoints: computed('note.points', 'note.type', function () {
      return this.get('note.points') && this.get('note.type') !== 'new_group_discussion';
    }),

    discussionRoute: function discussionRoute(discussion) {
      if ((0, _qrcUtils.isListQuestion)(get(discussion, 'content'))) {
        return 'answers';
      } else if ((0, _qrcUtils.isListSharedLink)(get(discussion, 'content'))) {
        return 'shared-links';
      } else if ((0, _qrcUtils.isListStatusUpdate)(get(discussion, 'content'))) {
        return 'status-updates';
      }

      return 'answers';
    },


    discussionUrl: computed('note.{list_comment_discussion.slug,list_discussion.slug,list_response_discussion.slug,list_response.id,list_comment.id,group_link_type,group_link_id,type}', function () {
      var commentDiscussion = get(this, 'note.list_comment_discussion');
      var listDiscussion = get(this, 'note.list_discussion');
      var listResponseDiscussion = get(this, 'note.list_response_discussion');
      var discussion = null;
      var noteType = get(this, 'note.type');
      var urlhash = '';

      // Unfortunately this code is different on mobile because there is no ember route
      // for private groups on desktop yet.
      if (noteType === 'invited_to_group' || noteType === 'joined_private_group') {
        return '/' + this.get('note.group_link_type') + '/' + this.get('note.group_link_id');
      }

      if (commentDiscussion && get(commentDiscussion, 'slug')) {
        discussion = commentDiscussion;
        urlhash = '?urlhash=' + get(this, 'note.list_comment.id');
      } else if (listDiscussion && get(listDiscussion, 'slug')) {
        discussion = listDiscussion;
      } else if (listResponseDiscussion && get(listResponseDiscussion, 'slug')) {
        discussion = listResponseDiscussion;
        urlhash = '?urlhash=' + get(this, 'note.list_response.id');
      }

      if (discussion !== null) {
        var route = this.discussionRoute(discussion);
        var slug = get(discussion, 'slug');
        return '/' + route + '/' + slug + urlhash;
      }
    }),

    badgeName: computed('note.type', function () {
      return get(this, 'note.type').replace('_', ' ');
    }),

    notificationImage: computed('note.type', function () {
      switch (get(this, 'note.type')) {
        case 'new_poke':
          return 'assets/images/notifications/raise-a-glass.png';
        case 'new_salute':
          return 'assets/images/notifications/salute.png';
      }

      return null;
    }),

    groupHelperText: computed('note.groups.[]', function () {
      var groups = this.get('note.groups');

      if (isEmpty(groups)) {
        return get(this, 'i18n').t('notificationCenter.groupHelperText');
      } else {
        return groups.join(', ');
      }
    }),

    helperText: computed('note.general', 'note.type', 'groupHelperText', 'badgeName', function () {
      var helper = '';
      switch (this.get('note.general')) {
        case 'badge':
          helper = _notificationHelper.default.getBadgeHelperText(get(this, 'badgeName'));
          break;

        case 'qr':
          helper = _notificationHelper.default.getQRHelperText(this.get('note.type'), this.get('groupHelperText'));
          break;

        case 'poke':
          helper = _notificationHelper.default.getSubtitle(this.get('note.type'));
          break;

        default:
          helper = _notificationHelper.default.getSubtitle(this.get('note.general'));
          break;
      }

      return helper;
    }),

    statusUpdateQuote: or('note.list_discussion.full_status_update.body', 'note.list_response_discussion.full_status_update.body', 'note.list_comment_discussion.full_status_update.body', 'fallbackPostText'),

    statusUpdateQuoteFormatted: computed('statusUpdateQuote', function () {
      return this.formatMentions(this.get('statusUpdateQuote'));
    }),

    formatMentions: function formatMentions(body) {
      var match = void 0;
      /* eslint-disable no-cond-assign */
      while (match = /\[~(?:\d+):([^\]]*)\]/g.exec(body)) {
        if (match.length !== 2) {
          continue;
        }
        body = body.replace(match[0], match[1]);
      }
      return body;
      /* eslint-enable no-cond-assign */
    },


    href: computed('notificationUrl', 'note.{general,type,list_profile.slug}', 'sponsoredPostUrl', function () {
      var notificationUrl = get(this, 'notificationUrl');
      var isQR = get(this, 'note.general') === 'qr';
      var isBadge = get(this, 'note.general') === 'badge';
      var isSalute = get(this, 'note.type') === 'new_salute';
      var isSponsoredWithExternalCTA = get(this, 'note.general') === 'sponsored';
      var externalCTAUrl = get(this, 'externalCTAUrl');

      if (!externalCTAUrl) {
        externalCTAUrl = '/answers/' + get(this, 'note.list_discussion.id');
      }

      if (notificationUrl && (isQR || isBadge || isSalute)) {
        return notificationUrl;
      } else if (isSponsoredWithExternalCTA && externalCTAUrl) {
        return externalCTAUrl;
      } else if (get(this, 'sponsoredPostUrl')) {
        return get(this, 'sponsoredPostUrl');
      } else if (get(this, 'note.list_profile')) {
        return '/profiles/' + get(this, 'note.list_profile.slug');
      }
    }),

    target: computed('note.{general}', function () {
      var isSponsoredWithExternalCTA = get(this, 'note.general') === 'sponsored';
      return isSponsoredWithExternalCTA ? '_blank' : undefined;
    }),

    click: function click() {
      if (get(this, 'note.general') === 'sponsored') {
        get(this, 'sponsoredPostTracker').trackClick(get(this, 'listModel'));
      }
      return true;
    },
    didInsertElement: function didInsertElement() {
      var _this = this;

      this._super.apply(this, arguments);
      if (get(this, 'note.general') === 'sponsored') {
        get(this, 'note').get('list_discussion').then(function () {
          get(_this, 'qrcTracker').trackImpression(get(_this, 'fullModel'), 'notification_feed', get(_this, 'index'));
        });
      }
    }
  });
});