define('desktop-frontend/components/tag-display/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var get = Ember.get;
  var set = Ember.set;
  exports.default = Component.extend({
    classNames: ['tag-display'],
    tags: null,

    actions: {
      removeTag: function removeTag(tag) {
        var newTags = get(this, 'tags').rejectBy('name', tag.get('name'));
        set(this, 'tags', newTags);
      }
    }
  });
});