define('desktop-frontend/components/stigma-reduction-banner/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  exports.default = Component.extend({
    classNames: ['interventions-banner'],
    declined: false,
    actions: {
      no: function no() {
        this.set('declined', true);
      }
    }
  });
});