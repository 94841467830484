define('desktop-frontend/components/dots-menu/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var get = Ember.get;
  var computed = Ember.computed;
  exports.default = Component.extend({
    steps: computed('step', function () {
      var step = this.get('step');
      var steps = get(this, 'currentSession.isSignedIn') ? [1, 2] : [1, 2, 3, 4];
      return steps.map(function (number) {
        return number === step;
      });
    })
  });
});