define('desktop-frontend/application/controller', ['exports', 'desktop-frontend/config/environment', 'rp-common/services/trackers/qrc'], function (exports, _environment, _qrc) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  var get = Ember.get;
  var set = Ember.set;
  var Controller = Ember.Controller;
  var service = Ember.inject.service;
  var $ = Ember.$;


  var pathToLocationMap = {
    'home': _qrc.HOME_FEED,
    'answers': _qrc.ANSWERS_FEED
  };

  var loadingRegex = /loading/g;

  exports.default = Controller.extend({
    ui: service(),
    serverMaintenance: service(),
    isIslandsMode: !_environment.default.APP.EMBER_ISLANDS.bypass,
    pageLayout: service(),
    currentSession: service(),
    confettiMachine: service(),
    headerMenu: service(),
    location: '',

    init: function init() {
      this._super.apply(this, arguments);
      $(document).on('click', this.onBodyClick.bind(this));
    },


    currentPathDidChange: function () {
      var _get$split = get(this, 'currentPath').split('.'),
          _get$split2 = _slicedToArray(_get$split, 2),
          path = _get$split2[0],
          template = _get$split2[1];

      if (template.match(loadingRegex)) {
        return;
      }
      var match = pathToLocationMap[path];
      if (match) {
        set(this, 'location', match);
      }
    }.observes('currentPath'),

    onBodyClick: function onBodyClick(evt) {
      get(this, 'headerMenu').onBodyClick(evt, this);
    },


    actions: {
      ok: function ok() {
        this.set('ui.showNativeAlert', false);
      },
      reloadApplication: function reloadApplication() {
        window.location.reload();
      }
    }
  });
});