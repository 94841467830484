define('desktop-frontend/utils/group-route-resolver', ['exports', 'rp-common/utils/group-route-resolver'], function (exports, _groupRouteResolver) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _groupRouteResolver.default;
    }
  });
  Object.defineProperty(exports, 'AVAILABLE_GROUP_TYPES', {
    enumerable: true,
    get: function () {
      return _groupRouteResolver.AVAILABLE_GROUP_TYPES;
    }
  });
  Object.defineProperty(exports, 'GROUPABLE_ROUTE_MAP', {
    enumerable: true,
    get: function () {
      return _groupRouteResolver.GROUPABLE_ROUTE_MAP;
    }
  });
  Object.defineProperty(exports, 'ROUTE_TO_MODEL_MAP', {
    enumerable: true,
    get: function () {
      return _groupRouteResolver.ROUTE_TO_MODEL_MAP;
    }
  });
  Object.defineProperty(exports, 'groupType', {
    enumerable: true,
    get: function () {
      return _groupRouteResolver.groupType;
    }
  });
});