define('desktop-frontend/components/question-search/component', ['exports', 'ember-concurrency'], function (exports, _emberConcurrency) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var and = Ember.computed.and;
  var Component = Ember.Component;
  var service = Ember.inject.service;
  var EmberObject = Ember.Object;
  var setProperties = Ember.setProperties;
  var set = Ember.set;
  var get = Ember.get;
  var computed = Ember.computed;


  var DEBOUNCE_MS = 250;
  var MAX_SKIPS = 3;
  var MIN_TERM = 2;
  var KEY_UP = 38;
  var KEY_DOWN = 40;
  var KEY_ESC = 27;

  exports.default = Component.extend({
    classNames: ['question-search'],
    placeholder: computed(function () {
      return get(this, 'i18n').t('questionSearch.placeholder');
    }),
    results: null,
    term: null,
    resultTitleField: 'label',
    resultType: 'questions',
    i18n: service(),
    resultsHeading: computed(function () {
      return get(this, 'i18n').t('questionSearch.resultsHeading');
    }),
    noResultsMessage: computed(function () {
      return get(this, 'i18n').t('questionSearch.noResults');
    }),
    buttonText: computed(function () {
      return get(this, 'i18n').t('questionSearch.submit');
    }),
    initialTerm: computed({
      set: function set(key, value) {
        setProperties(this, {
          query: value,
          results: null,
          term: null
        });

        if (value && value.length) {
          get(this, 'updateResults').perform(value);
        }
      }
    }),

    questionFetcher: service('question-fetchers/question'),

    _skipCount: 0,
    queryHasFocus: false,
    query: null,

    showResults: and('queryHasFocus', 'term.length'),

    updateResults: (0, _emberConcurrency.task)(_emberConcurrency.enqueue, (0, _emberConcurrency.maxConcurrency)(2), regeneratorRuntime.mark(function _callee(term) {
      var results, objResults;
      return regeneratorRuntime.wrap(function _callee$(_context) {
        while (1) {
          switch (_context.prev = _context.next) {
            case 0:
              _context.next = 2;
              return get(this, 'questionFetcher').query(term);

            case 2:
              results = _context.sent;
              objResults = results[get(this, 'resultType')].map(function (r) {
                return EmberObject.create(r);
              });

              objResults.setEach('selected', false);
              set(this, 'results', objResults);
              set(this, 'term', term);

            case 7:
            case 'end':
              return _context.stop();
          }
        }
      }, _callee, this);
    })),

    queueUpdate: (0, _emberConcurrency.task)(_emberConcurrency.restartable, regeneratorRuntime.mark(function _callee2(term) {
      return regeneratorRuntime.wrap(function _callee2$(_context2) {
        while (1) {
          switch (_context2.prev = _context2.next) {
            case 0:
              this.incrementProperty('_skipCount');
              _context2.next = 3;
              return (0, _emberConcurrency.timeout)(DEBOUNCE_MS);

            case 3:
              _context2.next = 5;
              return get(this, 'updateResults').perform(term);

            case 5:
            case 'end':
              return _context2.stop();
          }
        }
      }, _callee2, this);
    })),

    setQueryFocusOut: (0, _emberConcurrency.task)(_emberConcurrency.restartable, regeneratorRuntime.mark(function _callee3(hasFocus) {
      return regeneratorRuntime.wrap(function _callee3$(_context3) {
        while (1) {
          switch (_context3.prev = _context3.next) {
            case 0:
              if (hasFocus) {
                _context3.next = 3;
                break;
              }

              _context3.next = 3;
              return (0, _emberConcurrency.timeout)(500);

            case 3:

              set(this, 'queryHasFocus', hasFocus);

            case 4:
            case 'end':
              return _context3.stop();
          }
        }
      }, _callee3, this);
    })),

    handleArrows: function handleArrows() {
      var moveDown = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : true;

      var results = get(this, 'results');
      if (!results || !results.length) {
        return;
      }

      var idx = results.findIndex(function (r) {
        return get(r, 'selected');
      });

      if (idx >= 0) {
        set(results[idx], 'selected', false);
      }

      if (moveDown) {
        idx++;
      } else {
        idx--;
      }

      if (idx > results.length - 1) {
        idx = 0;
      } else if (idx < 0) {
        idx = results.length - 1;
      }

      set(results[idx], 'selected', true);
    },


    actions: {
      onQueryFocusIn: function onQueryFocusIn() {
        get(this, 'setQueryFocusOut').perform(true);
      },
      onQueryFocusOut: function onQueryFocusOut() {
        get(this, 'setQueryFocusOut').perform(false);
      },
      onQueryKeyUp: function onQueryKeyUp(term, evt) {
        if (evt.which && [KEY_UP, KEY_DOWN].includes(evt.which)) {
          this.handleArrows(evt.which === KEY_DOWN);
          return;
        }

        if (evt.which === KEY_ESC) {
          this.$('.search-box').blur();
          return;
        }

        if (!term || term.length < MIN_TERM) {
          return;
        }

        var queueUpdate = get(this, 'queueUpdate');

        if (get(this, '_skipCount') > MAX_SKIPS) {
          set(this, '_skipCount', 0);
          queueUpdate.cancelAll();
          get(this, 'updateResults').perform(term);
        } else {
          queueUpdate.perform(term);
        }
      },
      onSelectOrSearch: function onSelectOrSearch() {
        var results = get(this, 'results');

        if (results && results.length) {
          var idx = results.findIndex(function (r) {
            return get(r, 'selected');
          });

          if (idx >= 0) {
            this.$('.search-results li:eq(' + idx + ') a').get(0).click();
            return;
          }
        }

        return this.send('onSubmitSearch');
      },
      onSubmitSearch: function onSubmitSearch() {
        this.sendAction('search', get(this, 'query'));
        this.$('.search-box').blur();
      }
    }
  });
});