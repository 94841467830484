define('desktop-frontend/components/sticky-google-ad/component', ['exports', 'ember-lifeline'], function (exports, _emberLifeline) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var get = Ember.get;
  var set = Ember.set;
  var Component = Ember.Component;


  var AD_REFRESH_SCROLL_Y_PX = 5000;

  exports.default = Component.extend({
    adSlots: null,
    previousAdSlotPos: 0,

    didInsertElement: function didInsertElement() {
      var _this = this;

      this._super.apply(this, arguments);
      (0, _emberLifeline.addEventListener)(this, window, 'scroll', function () {
        if (!(window.pageYOffset - get(_this, 'previousAdSlotPos') > AD_REFRESH_SCROLL_Y_PX)) {
          return;
        }
        if (get(_this, 'adSlots')) {
          googletag.pubads().refresh(get(_this, 'adSlots'), { changeCorrelator: false });
          set(_this, 'previousAdSlotPos', window.pageYOffset);
        }
      });
    },
    destroy: function destroy() {
      (0, _emberLifeline.runDisposables)(this);
      this._super.apply(this, arguments);
    },


    actions: {
      didInsertAdSlots: function didInsertAdSlots(adSlots) {
        set(this, 'adSlots', adSlots);
      }
    }
  });
});