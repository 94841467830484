define('desktop-frontend/mixins/control-center-manager', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Mixin = Ember.Mixin;
  var get = Ember.get;
  var service = Ember.inject.service;
  exports.default = Mixin.create({
    controlCenter: service(),

    didTransition: function didTransition() {
      this._super.apply(this, arguments);
      get(this, 'controlCenter').swapOutShownReputationMessages();
    }
  });
});