define('desktop-frontend/components/feed-company/component', ['exports', 'rp-common/components/feed-company/component'], function (exports, _component) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var get = Ember.get;
  exports.default = _component.default.extend({
    click: function click() {
      window.location.href = '/organizations/' + get(this, 'listQuestion.sponsored_post_group.slug');
    }
  });
});