define('desktop-frontend/components/base-suggest-sidebar/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var service = Ember.inject.service;
  var Component = Ember.Component;
  var $ = Ember.$;
  var run = Ember.run;
  var get = Ember.get;
  var set = Ember.set;
  exports.default = Component.extend({
    classNameBindings: ['animating', 'reverse'],
    store: service(),
    animating: false,
    reverse: false,

    runLater: function runLater(cb) {
      if (window.requestAnimationFrame) {
        window.requestAnimationFrame(cb);
      } else {
        run.later(cb, 50);
      }
    },


    actions: {
      slidingOffCurrent: function slidingOffCurrent() {/* noop */},


      // The onAnimate callback should update the next "card".
      // components that extend this one should overwrite it,
      onAnimate: function onAnimate() {/* noop */},


      // The starts the animation and calls onAnimate and slidingOffCurrent
      swapBuffers: function swapBuffers() {
        var _this = this;

        var reverse = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;

        this.$('.cloned').remove();
        set(this, 'animating', false);

        this.runLater(function () {
          var cfe = get(_this, 'cloneFromElement');
          var clone = $('<div class="cloned"/>').append(_this.$(cfe).clone());
          _this.$(cfe).parent().append(clone);
          _this.runLater(function () {
            set(_this, 'animating', true);
            set(_this, 'reverse', reverse);
            _this.send('onAnimate');
            _this.send('slidingOffCurrent');
          });
        });
      }
    }
  });
});