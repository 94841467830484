define('desktop-frontend/home/index/controller', ['exports', 'rp-common/services/trackers/qrc'], function (exports, _qrc) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Controller = Ember.Controller;
  var computed = Ember.computed;
  var alias = Ember.computed.alias;
  exports.default = Controller.extend({
    queryParams: ['openWizard', 'pinnedContentID', 'openNLBanner'],
    openWizard: null,
    pinnedContentID: null,
    openNLBanner: null,
    location: _qrc.HOME_FEED,

    feed: alias('model.allFeedItems.feed'),
    pinnedContent: alias('model.pinnedContent'),
    job: alias('model.allFeedItems.job'),
    stigmaReductionStatus: alias('model.stigmaReductionStatus'),
    listProfile: alias('model.listProfile'),

    showStigmaReductionBanner: computed('stigmaReductionStatus', 'listProfile', function () {
      return this.get('listProfile.is_intervention_group_member') && !this.get('stigmaReductionStatus').completed;
    }),

    startStigmaReduction: computed('openNLBanner', function () {
      return this.get('openNLBanner') === 'stigma';
    })
  });
});