define('desktop-frontend/components/question-search/search-result/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var readOnly = Ember.computed.readOnly;
  var get = Ember.get;
  var computed = Ember.computed;
  exports.default = Component.extend({
    tagName: 'li',
    classNameBindings: ['selected'],
    result: null,
    query: null,
    titleField: null,

    selected: readOnly('result.selected'),

    questionUrl: computed('result.id', function () {
      return '/answers/' + get(this, 'result.id');
    }),

    title: computed('titleField', function () {
      return get(this, 'result.' + get(this, 'titleField'));
    })
  });
});