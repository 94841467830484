define('desktop-frontend/components/header-contact-requests/cr-item/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var readOnly = Ember.computed.readOnly;
  var gt = Ember.computed.gt;
  var service = Ember.inject.service;
  var set = Ember.set;
  var get = Ember.get;
  var computed = Ember.computed;


  var MAX_EXPLANATION_LENGTH = 60;

  exports.default = Component.extend({
    tagName: 'li',
    classNames: ['cr-item'],

    contactRequest: null,

    sending: false,

    ui: service(),
    i18n: service(),
    controlCenter: service(),

    reasonTruncated: gt('contactRequest.explanation_text.length', MAX_EXPLANATION_LENGTH),
    sender: readOnly('contactRequest.sender'),

    reason: computed('contactRequest.{friend,seeking_for_advice,offering_support,other,explanation_text}', function () {
      if (get(this, 'contactRequest.friend')) {
        return get(this, 'i18n').t('header.cr.reason.friend');
      } else if (get(this, 'contactRequest.seeking_for_advice')) {
        return get(this, 'i18n').t('header.cr.reason.seekingAdvice');
      } else if (get(this, 'contactRequest.offering_support')) {
        return get(this, 'i18n').t('header.cr.reason.offeringSupport');
      } else if (get(this, 'contactRequest.other')) {
        return get(this, 'contactRequest.explanation_text').substr(0, MAX_EXPLANATION_LENGTH);
      }

      return '';
    }),

    senderUrl: computed('sender.slug', function () {
      return '/profiles/' + get(this, 'sender.slug');
    }),

    actions: {
      accept: function accept() {
        var _this = this;

        set(this, 'sending', true);
        get(this, 'contactRequest').accept().then(function () {
          return get(_this, 'controlCenter').update();
        }).catch(function () {
          return get(_this, 'ui').nativeAlert(get(_this, 'i18n').t('header.cr.errors.couldNotAccept'));
        }).finally(function () {
          return set(_this, 'sending', false);
        });
      },
      ignore: function ignore() {
        var _this2 = this;

        set(this, 'sending', true);
        get(this, 'contactRequest').ignore().then(function () {
          return get(_this2, 'controlCenter').update();
        }).catch(function () {
          return get(_this2, 'ui').nativeAlert(get(_this2, 'i18n').t('header.cr.errors.couldNotIgnore'));
        }).finally(function () {
          return set(_this2, 'sending', false);
        });
      }
    }
  });
});