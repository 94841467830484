define('desktop-frontend/components/message-user-button/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var set = Ember.set;
  exports.default = Component.extend({
    profile: null,

    actions: {
      openMessageUserModal: function openMessageUserModal() {
        set(this, 'messageUserOpen', true);
      },
      closeMessageUserModal: function closeMessageUserModal() {
        set(this, 'messageUserOpen', false);
      }
    }
  });
});