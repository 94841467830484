define('desktop-frontend/components/tag-searcher/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var service = Ember.inject.service;
  var empty = Ember.computed.empty;
  var Component = Ember.Component;
  var EmberObject = Ember.Object;
  var set = Ember.set;
  var get = Ember.get;
  var computed = Ember.computed;
  var run = Ember.run;
  var Logger = Ember.Logger;


  var MAX_TAGS = 5;

  exports.default = Component.extend({
    // Tags you've added
    tags: null,
    // Raw JSON of tags you're searching for
    searchResults: [],
    apiService: service(),
    maxTags: computed('tags.length', function () {
      var reachedMax = get(this, 'tags.length') >= MAX_TAGS;
      if (reachedMax) {
        set(this, 'showResults', false);
      }
      return reachedMax;
    }),
    store: service(),
    term: null,
    noTerm: empty('term'),
    showResults: false,
    tagsAutocomplete: service(),
    classNames: ['tag-searcher'],
    loading: false,
    addingTag: false,

    hideNewTag: computed('term', 'noTerm', 'searchTags', function () {
      var noTerm = get(this, 'noTerm');
      var term = get(this, 'term');
      var searchTags = get(this, 'searchTags');
      var existing = searchTags.any(function (searchTag) {
        return get(searchTag, 'name') === term;
      });
      return noTerm || existing;
    }),

    newTag: computed('term', function () {
      var term = get(this, 'term');
      return {
        id: null,
        value: term,
        name: term,
        image_path: null,
        image: null,
        sub_name: '',
        taggable: true
      };
    }),

    tagsFilter: function tagsFilter(tag) {
      var tags = get(this, 'tags');
      var tagName = get(tag, 'name');
      var alreadyAdded = tags.findBy('name', tagName);
      var isTaggable = get(tag, 'taggable');
      return isTaggable && !alreadyAdded;
    },


    displayedTags: computed('searchTags.[]', 'tags.[]', 'term', function () {
      var term = get(this, 'term');
      var resultTags = term ? get(this, 'searchTags') : [];
      return resultTags.filter(this.tagsFilter.bind(this));
    }),

    searchTags: computed('searchResults.[]', function () {
      var searchResults = get(this, 'searchResults');

      return searchResults.map(function (group) {
        return EmberObject.create({
          id: group.id,
          value: get(group, 'group_name'),
          name: get(group, 'group_name'),
          image_path: get(group, 'image_path'),
          active: false,
          image: get(group, 'image_path'),
          sub_name: get(group, 'groupable_type'),
          taggable: true
        });
      });
    }),

    actions: {
      addTag: function addTag(tag) {
        set(this, 'term', null);
        set(this, 'addingTag', true);
        set(tag, 'active', false);
        this.sendAction('addTag', tag);
      },
      focusIn: function focusIn() {
        set(this, 'showResults', get(this, 'term.length'));
      },
      focusOut: function focusOut() {
        set(this, 'showResults', false);
        if (this.attrs.afterBlur) {
          this.attrs.afterBlur();
        }
      },
      searchForTags: function searchForTags() {
        var _this = this;

        var term = get(this, 'term');
        var tagsAutocomplete = get(this, 'tagsAutocomplete');

        run.scheduleOnce('afterRender', function () {
          set(_this, 'loading', true);
          tagsAutocomplete.getAutoCompletes(term).then(function (results) {
            if (term === get(_this, 'term')) {
              set(_this, 'showResults', true);
              set(_this, 'searchResults', results);
              if (get(_this, 'term.length') === 0) {
                set(_this, 'showResults', false);
              }
            }
          }).catch(function (error) {
            Logger.error(error);
          }).finally(function () {
            set(_this, 'loading', false);
          });
        });
      },


      // Logic for keyboard access to the tags list
      // -----------------------------------------------
      selectTag: function selectTag(tag) {
        var activeTag = get(this, 'displayedTags').findBy('active', true);
        if (activeTag) {
          set(activeTag, 'active', false);
        }
        set(tag, 'active', true);
      },
      keyDown: function keyDown(target, e) {
        var KEY_DOWN = 40;
        var KEY_UP = 38;
        var KEY_ENTER = 13;

        if (![KEY_DOWN, KEY_UP, KEY_ENTER].contains(e.keyCode)) {
          return;
        }

        var downArrow = e.keyCode === KEY_DOWN;
        var idx = get(this, 'displayedTags').findIndex(function (t) {
          return get(t, 'active');
        });
        var maxLen = get(this, 'displayedTags.length');

        if (e.keyCode === KEY_ENTER) {
          if (idx >= 0) {
            this.send('addTag', get(this, 'displayedTags')[idx]);
          }
          return;
        }

        if (idx >= 0) {
          get(this, 'displayedTags')[idx].set('active', false);
        }

        idx = idx + (downArrow ? 1 : -1);

        if (idx < 0) {
          idx = maxLen - 1;
        } else if (idx >= maxLen) {
          idx = 0;
        }

        var selectedTag = get(this, 'displayedTags')[idx];
        set(selectedTag, 'active', true);
      }
      // -----------------------------------------------

    }
  });
});