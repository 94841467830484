define('desktop-frontend/components/share-button/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  exports.default = Component.extend({
    tagName: 'button',
    classNames: ['share-component'],
    attributeBindings: ['ariaLabel'],
    ariaLabel: 'Share',
    imageSrc: 'assets/images/share-button/share.png'
  });
});