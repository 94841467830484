define('desktop-frontend/services/takeover', ['exports', 'desktop-frontend/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var get = Ember.get;
  var computed = Ember.computed;
  var Service = Ember.Service;
  var service = Ember.inject.service;


  var POSSIBLE_TAKEOVERS = ['penfed', 'afr'];

  exports.default = Service.extend({
    penfed: service('takeovers/penfed'),
    afr: service('takeovers/afr'),

    possibleTakeovers: computed(function () {
      var _this = this;

      return POSSIBLE_TAKEOVERS.map(function (key) {
        return get(_this, key);
      });
    }),

    activeTakeover: computed('possibleTakeovers.@each.active', function () {
      if (!_environment.default.APP.TAKEOVER_ACTIVE) {
        return null;
      }
      return get(this, 'possibleTakeovers').findBy('active');
    })
  });
});