define('desktop-frontend/home/index/route', ['exports', 'ember-data', 'ember-infinity/mixins/route', 'rp-common/mixins/infinite-scroll-analytics', 'rp-common/services/trackers/qrc', 'rp-common/utils/utils-base', 'rp-common/mixins/discussion-tracking-route'], function (exports, _emberData, _route, _infiniteScrollAnalytics, _qrc, _utilsBase, _discussionTrackingRoute) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Route = Ember.Route;
  var RSVP = Ember.RSVP;
  var service = Ember.inject.service;
  var set = Ember.set;
  var get = Ember.get;


  var LOGIN_PATH = '/login';

  exports.default = Route.extend(_route.default, _infiniteScrollAnalytics.default, _discussionTrackingRoute.FeedDiscussionTrackingMixin, {
    impressionLocation: _qrc.HOME_FEED,

    pageContext: service(),
    currentSession: service(),
    nocklabsInterventions: service('nocklabs-intervention'),

    activate: function activate() {
      this._super.apply(this, arguments);
      set(this, 'pageContext.onHomePage', true);
    },
    deactivate: function deactivate() {
      this._super.apply(this, arguments);
      set(this, 'pageContext.onHomePage', false);
    },
    beforeModel: function beforeModel() {
      if (!get(this, 'currentSession.isSignedIn')) {
        _utilsBase.default.browserRedirect(LOGIN_PATH);
      }
    },
    model: function model(params, transition) {
      var profileId = get(this, 'currentSession.profile.id');
      var listProfile = this.store.findRecord('list-profile', profileId);
      var adminableCompanyId = get(this, 'currentSession.session.company_admin_group_ids.firstObject');
      var stigmaReductionStatus = get(this, 'nocklabsInterventions').getStigmaReductionStatus();
      var company = adminableCompanyId ? this.store.findRecord('list-group', adminableCompanyId) : null;
      return RSVP.hash({
        company: company,
        listProfile: listProfile,
        stigmaReductionStatus: stigmaReductionStatus,
        pinnedContent: this.getPinnedContent(params),
        allFeedItems: this.getAllFeedItems(params, transition)
      });
    },
    getPinnedContent: function getPinnedContent(params) {
      if (params.pinnedContentID) {
        return this.get('store').find('list-discussion', params.pinnedContentID).catch(function () {
          return null;
        });
      }
    },
    getAllFeedItems: function getAllFeedItems(params, transition) {
      var allFeedItems = _emberData.default.RecordArray.create({ content: [] });
      var resetPage = transition.queryParams.resetPage;


      if (resetPage) {
        this.set('savedScrollPosition', 0);
        this.store.unloadAll('list-feed');
        // mpape: This is so that when we go 'back' using transition.retry, this param is reset
        transition.intent.queryParams.resetPage = false;
      } else {
        // Push any newly created items first (user just created them)
        var cachedItems = this.store.peekAll('list-feed').get('content');
        allFeedItems.pushObjects(cachedItems.filter(function (fi) {
          return fi.isNew();
        }).reverse());
        allFeedItems.pushObjects(cachedItems.filter(function (fi) {
          return !fi.isNew();
        }));
      }

      if (!this.get('forceRefresh') && !resetPage && allFeedItems.get('content').length) {
        return RSVP.hash({
          feed: allFeedItems
        });
      } else {
        return RSVP.hash({
          feed: this.infinityModel('list-feed', {
            perPage: 10,
            startingPage: 1,
            modelPath: 'controller.model.allFeedItems.feed'
          }).catch(function () {
            allFeedItems.set('reachedInfinity', true);
            return allFeedItems;
          })
        });
      }
    }
  });
});