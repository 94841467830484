define('desktop-frontend/components/survey-form/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var service = Ember.inject.service;
  var lt = Ember.computed.lt;
  var Component = Ember.Component;
  var run = Ember.run;
  var get = Ember.get;
  var alias = Ember.computed.alias;


  var MAX_OPTIONS = 5;

  exports.default = Component.extend({
    classNames: ['survey-form'],
    survey: null,
    store: service(),
    options: alias('survey.survey_options'),
    canAddOption: lt('options.length', MAX_OPTIONS),

    didInsertElement: function didInsertElement() {
      var _this = this;

      this._super.apply(this, arguments);
      run.scheduleOnce('afterRender', function () {
        _this.$('.survey-option:eq(0) input').focus();
      });
    },


    actions: {
      addOption: function addOption() {
        var _this2 = this;

        var newOption = get(this, 'store').createRecord('survey-option');
        get(this, 'survey.survey_options').pushObjects([newOption]);
        run.scheduleOnce('afterRender', function () {
          _this2.$('.survey-option input').last().focus();
        });
      },
      removeOption: function removeOption(index) {
        get(this, 'options').removeAt(index);
      }
    }
  });
});