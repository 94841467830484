define('desktop-frontend/components/site-visitor-header/component', ['exports', 'rp-common/utils/utils-base', 'desktop-frontend/header-menu/service', 'ember-concurrency'], function (exports, _utilsBase, _service, _emberConcurrency) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var service = Ember.inject.service;
  var get = Ember.get;
  var set = Ember.set;
  var $ = Ember.$;


  var REG_LOGIN_URL_REGEX = /(registrations\/step)|(\/login)|(\/users\/sign_in)|(users\/password\/new)/;

  exports.default = Component.extend({
    classNames: ['site-visitor-header'],
    classNameBindings: ['showLoginSignUpLinks:with-login-signup'],
    tagName: 'header',

    headerMenu: service(),
    imageLoader: service(),

    loadedLogo: null,

    businessMenu: _service.businessIsOpen,

    didInsertElement: function didInsertElement() {
      this.checkLoginSignUp();
      this._super.apply(this, arguments);
    },
    init: function init() {
      get(this, 'loadHeaderLogo').perform();
      this._super.apply(this, arguments);
    },
    checkLoginSignUp: function checkLoginSignUp() {
      set(this, 'showLoginSignUpLinks', !REG_LOGIN_URL_REGEX.test(_utilsBase.default.getCurrentURL()));
    },


    loadHeaderLogo: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function _callee() {
      var loadedImg;
      return regeneratorRuntime.wrap(function _callee$(_context) {
        while (1) {
          switch (_context.prev = _context.next) {
            case 0:
              _context.next = 2;
              return get(this, 'imageLoader').loadSrcsetInBackground('assets/images/rp-header-white.png', { 'aria-hidden': true });

            case 2:
              loadedImg = _context.sent;

              set(this, 'loadedLogo', loadedImg);

            case 4:
            case 'end':
              return _context.stop();
          }
        }
      }, _callee, this);
    })),

    actions: {
      toggleMenu: function toggleMenu(menu, eVar62, evt) {
        var $target = $(evt.target);
        if (get(this, 'headerMenu.' + menu) && !($target.is('.js-no-menu-close') || $target.parents('.js-no-menu-close').length)) {
          get(this, 'headerMenu').hideAll();
        } else {
          get(this, 'headerMenu').showMenu(menu);
        }
      }
    }
  });
});