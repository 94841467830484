define('desktop-frontend/question-fetchers/base-autocomplete', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var RSVP = Ember.RSVP;
  var computed = Ember.computed;
  var get = Ember.get;
  var Service = Ember.Service;
  var service = Ember.inject.service;
  exports.default = Service.extend({
    apiService: service(),
    autoCompleteType: null,
    additionalParams: null,

    additionalParamString: computed('additionalParams', function () {
      var params = get(this, 'additionalParams');
      if (params && Object.keys(params).length > 0) {
        var paramSet = [];
        for (var name in params) {
          var value = params[name];
          paramSet.push(encodeURIComponent(name) + '=' + encodeURIComponent(value));
        }
        return '&' + paramSet.join('&');
      } else {
        return '';
      }
    }),

    query: function query(term) {
      var _this = this;

      return new RSVP.Promise(function (resolve) {
        var type = get(_this, 'autoCompleteType');
        var cleanTerm = encodeURIComponent(term);
        var additionalParams = get(_this, 'additionalParamString');
        var url = 'autocompletes/' + type + '?term=' + cleanTerm + additionalParams;
        get(_this, 'apiService').getRequest(url).then(resolve);
      });
    }
  });
});