define('desktop-frontend/components/login-form/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var service = Ember.inject.service;
  var get = Ember.get;
  exports.default = Component.extend({
    classNames: ['login-form'],
    session: null,

    currentSession: service(),
    setupSession: service(),
    routing: service(),

    actions: {
      login: function login() {
        var _this = this;

        if (!get(this, 'session.isNew')) {
          console.error('Session already exists');
          get(this, 'routing').transitionTo('home');
          return;
        }

        get(this, 'session').save().then(function () {
          get(_this, 'setupSession').handlePostAuthentication();
        }).then(function () {
          get(_this, 'routing').transitionTo('home');
        }).catch(function (err) {
          console.error(err);
        });
      }
    }
  });
});