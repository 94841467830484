define('desktop-frontend/services/deferred-transition', ['exports', 'rp-common/services/deferred-transition'], function (exports, _deferredTransition) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _deferredTransition.default;
    }
  });
});