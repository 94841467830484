define('desktop-frontend/services/default-search-picker-fetcher', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var EmberPromise = Ember.RSVP.Promise;
  var Service = Ember.Service;
  var service = Ember.inject.service;
  exports.default = Service.extend({
    cache: {},

    apiService: service(),

    query: function query(term, searchUrl) {
      var _this = this;

      if (this.get('cache')[searchUrl] && this.get('cache')[searchUrl][term]) {
        return new EmberPromise(function (resolve) {
          return resolve(_this.get('cache')[searchUrl][term]);
        });
      }

      return this.get('apiService').getRequest(searchUrl).then(function (results) {
        var cache = _this.get('cache')[searchUrl] || {};
        cache[term] = results;
        _this.get('cache')[searchUrl] = cache;
        return results;
      }).catch(function (err) {
        _this.reportError(err);
      });
    }
  });
});