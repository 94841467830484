define('desktop-frontend/components/header-search/result-section/component', ['exports', 'desktop-frontend/components/header-search/component'], function (exports, _component) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var readOnly = Ember.computed.readOnly;
  var equal = Ember.computed.equal;
  var gt = Ember.computed.gt;
  var get = Ember.get;
  var computed = Ember.computed;
  exports.default = Component.extend({
    tagName: 'section',
    classNames: ['header-search-result-section'],

    section: null,
    results: null,
    term: null,

    sectionKey: readOnly('section.key'),
    title: readOnly('section.title'),
    isProfileResult: equal('sectionKey', 'profiles'),
    isQuestionResult: equal('sectionKey', 'answers'),
    isTopicResult: equal('sectionKey', 'topics'),
    isGroupResult: equal('sectionKey', 'groups'),
    isUnitResult: equal('sectionKey', 'units'),
    isCompanyResult: equal('sectionKey', 'companies'),
    isVisible: gt('sectionResults.length', 0),

    resultComponent: computed(function () {
      switch (get(this, 'sectionKey')) {
        case 'profiles':
          return 'header-search/profile-result';

        case 'answers':
          return 'header-search/question-result';

        case 'topics':
          return 'header-search/topic-result';

        case 'groups':
          return 'header-search/group-result';

        case 'units':
          return 'header-search/unit-result';

        case 'companies':
          return 'header-search/company-result';
      }
    }),

    sectionIconSvg: computed('sectionKey', function () {
      return 'assets/images/' + _component.ICON_MAP[get(this, 'sectionKey')] + '.svg';
    }),

    sectionResults: computed('results', 'sectionKey', function () {
      return get(get(this, 'results'), get(this, 'sectionKey'));
    })
  });
});