define('desktop-frontend/components/feed-author-image/component', ['exports', 'rp-common/components/feed-author-image/component', 'desktop-frontend/components/feed-author-image/template'], function (exports, _component, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed;
  exports.default = _component.default.extend({
    layout: _template.default,
    authorUrl: computed('listProfile.slug', function () {
      return '/profiles/' + this.get('listProfile.slug');
    })
  });
});