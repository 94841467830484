define('desktop-frontend/services/takeovers/base-takeover', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Service = Ember.Service;
  exports.default = Service.extend({
    cssClass: null,
    preContentComponent: null,
    active: false
  });
});