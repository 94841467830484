define('desktop-frontend/models/profile', ['exports', 'rp-common/models/profile'], function (exports, _profile) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var get = Ember.get;
  var computed = Ember.computed;
  exports.default = _profile.default.extend({
    profileLink: computed('slug', function () {
      return '/profiles/' + get(this, 'slug');
    })
  });
});