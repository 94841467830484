define('desktop-frontend/components/list-feed-question/component', ['exports', 'ember-awesome-macros', 'desktop-frontend/mixins/nocklab-validations'], function (exports, _emberAwesomeMacros, _nocklabValidations) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var computed = Ember.computed;
  var alias = Ember.computed.alias;
  var readOnly = Ember.computed.readOnly;
  var service = Ember.inject.service;
  exports.default = Component.extend(_nocklabValidations.default, {
    feedItem: null,
    showReason: false,
    reasonProfile: null,
    listFeed: null,
    showCommandPostReason: false,

    store: service(),
    pageContext: service(),
    currentSession: service(),

    listQuestion: readOnly('feedItem.list_question'),
    listFeedItem: alias('listQuestion'),
    listProfile: alias('listQuestion.list_profile'),
    fullQuestion: readOnly('feedItem.list_question.full_question'),
    fullQrc: alias('fullQuestion'),
    listGroup: readOnly('pageContext.listGroup'),
    commandPost: readOnly('listQuestion.isCommandPost'),
    sponsored: readOnly('listQuestion.sponsored_post'),
    notOwnPost: (0, _emberAwesomeMacros.neq)('feedItem.reason', (0, _emberAwesomeMacros.raw)('own_post')),
    useXIconMenu: (0, _emberAwesomeMacros.and)('currentSession.isSignedIn', (0, _emberAwesomeMacros.not)('sponsored'), 'notOwnPost', (0, _emberAwesomeMacros.not)('fullQuestion.isLoading')),
    showQLUReason: (0, _emberAwesomeMacros.and)('showReason', (0, _emberAwesomeMacros.not)('sponsored'), (0, _emberAwesomeMacros.or)('showCommandPostReason', (0, _emberAwesomeMacros.not)('commandPost'))),

    questionUrl: computed('listQuestion.slug', function () {

      return '/answers/' + this.get('listQuestion.slug');
    }),

    actions: {
      afterPostResponse: function afterPostResponse(responseId) {
        var _this = this;

        var store = this.get('store');
        this.set('fullQuestion.has_responded', true);
        store.find('list-response', responseId).then(function (response) {
          _this.get('feedItem.responses').unshiftObject(response);
        });
      },
      goToAuthor: function goToAuthor(authorId) {
        this.sendAction('goToLink', 'profile.index', authorId);
      },
      goToResponse: function goToResponse(listResponse) {
        this.attrs.goToLink('answers.show.comments', this.get('listQuestion.slug'), listResponse.get('id'));
      }
    },

    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);
      this.checkIfInterventionMember();
    }
  });
});