define('desktop-frontend/components/search-picker/component', ['exports', 'ember-concurrency', 'ember-awesome-macros'], function (exports, _emberConcurrency, _emberAwesomeMacros) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  var and = Ember.computed.and;
  var gt = Ember.computed.gt;
  var or = Ember.computed.or;
  var Component = Ember.Component;
  var service = Ember.inject.service;
  var $ = Ember.$;
  var set = Ember.set;
  var get = Ember.get;
  var computed = Ember.computed;


  var SEARCH_DEBOUNCE_LENGTH = 250;
  var CLEAR_QUERY_TIMEOUT = 300;

  var UP_ARROW = 38;
  var DOWN_ARROW = 40;
  var ESCAPE_KEY = 27;
  var RETURN_KEY = 13;

  var RESULTS_LIST_SELECTOR = '.results-list';
  var RESULT_SELECTOR = '.results-list .result-item';

  exports.default = Component.extend({
    remote: '',
    classNames: ['search-picker'],
    placeholder: 'Search for items',
    searchPlaceholder: null,
    defaultResults: computed(function () {
      return [];
    }),
    results: computed(function () {
      return [];
    }),
    params: null,
    searchQuery: '',
    maxMultiple: 0,

    fetcherService: service('default-search-picker-fetcher'),
    environment: service(),

    queryEntered: gt('searchQuery.length', 0),
    showResults: and('moreSelectionsAvailable', 'results.length'),
    interiorPlaceholder: or('searchPlaceholder', 'placeholder'),
    moreSelectionsAvailable: (0, _emberAwesomeMacros.lt)('selectedResults.length', 'maxMultiple'),

    searchUrl: computed('remote', 'searchQuery', function () {
      return get(this, 'remote') + '?term=' + get(this, 'searchQuery');
    }),

    reset: function reset() {
      set(this, 'searchQuery', '');
      set(this, 'results', []);
      get(this, 'selectedResults').clear();
    },


    runQuery: (0, _emberConcurrency.task)(_emberConcurrency.restartable, regeneratorRuntime.mark(function _callee() {
      var _this = this;

      var query, url, params, results;
      return regeneratorRuntime.wrap(function _callee$(_context) {
        while (1) {
          switch (_context.prev = _context.next) {
            case 0:
              if (get(this, 'searchQuery.length')) {
                _context.next = 3;
                break;
              }

              set(this, 'results', []);
              return _context.abrupt('return');

            case 3:
              _context.next = 5;
              return (0, _emberConcurrency.timeout)(SEARCH_DEBOUNCE_LENGTH);

            case 5:
              _context.prev = 5;
              query = get(this, 'searchQuery');
              url = get(this, 'searchUrl');
              params = get(this, 'params');
              _context.next = 11;
              return get(this, 'fetcherService').query(query, url, params);

            case 11:
              results = _context.sent;


              results = results.filter(function (r) {
                return !get(_this, 'selectedResults').findBy('id', r.id);
              });

              set(this, 'results', results);
              _context.next = 19;
              break;

            case 16:
              _context.prev = 16;
              _context.t0 = _context['catch'](5);

              this.reportError(_context.t0);

            case 19:
            case 'end':
              return _context.stop();
          }
        }
      }, _callee, this, [[5, 16]]);
    })),

    navigateResults: function navigateResults(direction) {
      var currentSelected = get(this, 'selectedItem');
      var nextSelected = null;
      if (currentSelected === null) {
        set(this, 'selectedItem', 0);
        this.highlightSelectedSearchResult();
        return;
      }

      if (direction === 'down') {
        if (currentSelected === -1) {
          // At the last item, nowhere else to go.
          return;
        }
        nextSelected = currentSelected + 1;
      } else if (direction === 'up') {
        if (currentSelected === 0) {
          return; // At the top
        } else if (currentSelected === -1) {
          // Move back to the last selected item.
          nextSelected = this.$(RESULT_SELECTOR).length - 1;
        } else {
          nextSelected = currentSelected - 1;
        }
      } else {
        throw direction + ' is not a valid direction.';
      }
      set(this, 'selectedItem', nextSelected);
      this.highlightSelectedSearchResult();
    },
    highlightSelectedSearchResult: function highlightSelectedSearchResult() {
      var selectedItem = get(this, 'selectedItem');
      var $resultItems = this.$(RESULT_SELECTOR);
      $resultItems.removeClass('selected');
      if (selectedItem > $resultItems.length - 1) {
        // End of results
        set(this, 'selectedItem', -1);
        return;
      } else {
        $resultItems.eq(selectedItem).addClass('selected');

        var _$ = $(RESULTS_LIST_SELECTOR),
            _$2 = _slicedToArray(_$, 1),
            list = _$2[0];

        var _$resultItems$eq = $resultItems.eq(selectedItem),
            _$resultItems$eq2 = _slicedToArray(_$resultItems$eq, 1),
            targetli = _$resultItems$eq2[0];

        list.scrollTop = targetli.offsetTop - 50;
      }
    },


    clearQueryTask: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function _callee2() {
      return regeneratorRuntime.wrap(function _callee2$(_context2) {
        while (1) {
          switch (_context2.prev = _context2.next) {
            case 0:
              _context2.next = 2;
              return (0, _emberConcurrency.timeout)(CLEAR_QUERY_TIMEOUT);

            case 2:
              // Needed to not clear the list of results before the user is able to select an item
              set(this, 'searchQuery', '');
              set(this, 'results', get(this, 'defaultResults').copy());

            case 4:
            case 'end':
              return _context2.stop();
          }
        }
      }, _callee2, this);
    })),

    actions: {
      clearQuery: function clearQuery() {
        get(this, 'clearQueryTask').perform();
        return true;
      },
      handleNavigationKeys: function handleNavigationKeys(key, evt) {
        switch (evt.which) {
          case UP_ARROW:
            this.navigateResults('up');
            evt.preventDefault();
            return false;
          case DOWN_ARROW:
            this.navigateResults('down');
            evt.preventDefault();
            return false;
          case RETURN_KEY:
            // click the item that is selected
            $(RESULT_SELECTOR.concat('.selected')).click();
            return false;
          case ESCAPE_KEY:
            return false;
          default:
            set(this, 'selectedItem', null);
            return false;
        }
      },
      updateResults: function updateResults(query) {
        set(this, 'selecting', false);
        set(this, 'searchQuery', query);
        get(this, 'runQuery').perform();
      },
      selectItem: function selectItem(data) {
        set(this, 'selecting', true);
        get(this, 'selectedResults').unshiftObject(data);
        set(this, 'searchQuery', '');
        set(this, 'results', []);
        this.$('.search-query-input input').focus();
      }
    }
  });
});