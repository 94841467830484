define('desktop-frontend/offers/index/controller', ['exports', 'rp-common/components/google-ad/component'], function (exports, _component) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Controller = Ember.Controller;
  var readOnly = Ember.computed.readOnly;
  var service = Ember.inject.service;
  exports.default = Controller.extend({
    currentUser: service(),

    offers: readOnly('model'),

    bannerAdSize: _component.BANNER_SLOT_SIZE,
    bottomAdSizes: _component.SIDEBAR_WITH_LONG_SLOT_SIZE
  });
});