define('desktop-frontend/components/feed-response/component', ['exports', 'rp-common/utils/qrc-utils', 'rp-common/utils/group-route-resolver', 'rp-common/utils/reputation-library'], function (exports, _qrcUtils, _groupRouteResolver, _reputationLibrary) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var gt = Ember.computed.gt;
  var readOnly = Ember.computed.readOnly;
  var alias = Ember.computed.alias;
  var Component = Ember.Component;
  var get = Ember.get;
  var computed = Ember.computed;
  var service = Ember.inject.service;
  exports.default = Component.extend({
    classNames: ['feed-response'],
    response: null,
    commentFormOpen: false,
    readOnly: false,

    i18n: service(),
    flashMessages: service(),
    pageContext: service(),

    responseTruncated: gt('response.full_response.body.length', 100),
    hasComments: gt('response.full_response.comment_count', 0),
    showReadMore: alias('responseTruncated'),
    listGroup: readOnly('pageContext.listGroup'),

    moreText: computed('response.full_response.comment_count', function () {
      var comments = this.get('response.full_response.comment_count');
      var commentParens = comments > 0 ? ' (' + comments + ')' : '';
      return get(this, 'i18n').t('feed.question.viewResponse', { comments: commentParens });
    }),

    responseUrl: computed('pageContext.onGroupPage', 'listGroup.{groupable_type,slug}', 'response.{id,list_parent.slug}', function () {
      var parent = this.get('response.list_parent.content');
      var urlType = 'answers';

      if ((0, _qrcUtils.isListSharedLink)(parent)) {
        urlType = 'shared-links';
      } else if ((0, _qrcUtils.isListStatusUpdate)(parent)) {
        urlType = 'status-updates';
      }

      if (this.get('pageContext.onGroupPage') && this.get('listGroup.slug')) {
        return '/' + (0, _groupRouteResolver.groupType)(this.get('listGroup.groupable_type')) + '/' + this.get('listGroup.slug') + '/' + urlType + '/' + this.get('response.list_parent.slug') + '?urlhash=' + this.get('response.id');
      }

      return '/' + urlType + '/' + this.get('response.list_parent.slug') + '?urlhash=' + this.get('response.id');
    }),

    actions: {
      toggleCommentForm: function toggleCommentForm() {
        this.toggleProperty('commentFormOpen');
      },
      afterNewCommentSave: function afterNewCommentSave() {
        get(this, 'flashMessages').success(get(this, 'i18n').t('commentFlash.success', {
          points: _reputationLibrary.default.post_comment
        }));
        this.toggleProperty('commentFormOpen');
      },
      noop: function noop() {/* provide to new-response-from to skip some default work we dont want */}
    }
  });
});