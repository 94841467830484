define('desktop-frontend/components/list-feed-shared-link/component', ['exports', 'rp-common/utils/group-route-resolver', 'ember-awesome-macros', 'desktop-frontend/mixins/nocklab-validations'], function (exports, _groupRouteResolver, _emberAwesomeMacros, _nocklabValidations) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var service = Ember.inject.service;
  var readOnly = Ember.computed.readOnly;
  var alias = Ember.computed.alias;
  var Component = Ember.Component;
  var get = Ember.get;
  var computed = Ember.computed;
  exports.default = Component.extend(_nocklabValidations.default, {
    feedItem: null,
    showReason: false,
    reasonProfile: null,
    listFeed: null,

    store: service(),
    pageContext: service(),
    currentSession: service(),

    listSharedLink: readOnly('feedItem.list_shared_link'),
    listFeedItem: alias('listSharedLink'),
    listProfile: alias('listSharedLink.list_profile'),
    fullSharedLink: readOnly('feedItem.list_shared_link.full_shared_link'),
    fullQrc: alias('fullSharedLink'),
    linkData: readOnly('fullSharedLink.link_data'),
    listGroup: readOnly('pageContext.listGroup'),
    notOwnPost: (0, _emberAwesomeMacros.neq)('feedItem.reason', (0, _emberAwesomeMacros.raw)('own_post')),
    useXIconMenu: (0, _emberAwesomeMacros.and)('currentSession.isSignedIn', 'notOwnPost', (0, _emberAwesomeMacros.not)('fullSharedLink.isLoading')),

    sharedLinkUrl: computed('pageContext.onGroupPage', 'listGroup.{groupable_type,slug}', 'listSharedLink.slug', function () {
      if (this.get('pageContext.onGroupPage') && this.get('listGroup.slug')) {
        var thisGroupType = (0, _groupRouteResolver.groupType)(this.get('listGroup.groupable_type'));
        var groupSlug = this.get('listGroup.slug');
        var sharedLinkSlug = this.get('listSharedLink.slug');
        return '/' + thisGroupType + '/' + groupSlug + '/shared-links/' + sharedLinkSlug;
      }

      return '/shared-links/' + get(this, 'listSharedLink.slug');
    }),

    actions: {
      afterPostResponse: function afterPostResponse(responseId) {
        var _this = this;

        this.set('fullSharedLink.has_responded', true);
        get(this, 'store').find('list-response', responseId).then(function (response) {
          get(_this, 'feedItem.responses').unshiftObject(response);
        });
      },
      goToArticle: function goToArticle() {
        // noop
      },
      goToGroup: function goToGroup() {
        this.attrs.goToLink('groups.show', (0, _groupRouteResolver.groupType)(get(this, 'feedItem.group.groupable_type')), this.get('feedItem.group.slug'));
      },
      goToResponse: function goToResponse(listResponse) {
        this.attrs.goToLink('shared-links.show.comments', get(this, 'listSharedLink.slug'), listResponse.get('id'));
      }
    },
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);
      this.checkIfInterventionMember();
    }
  });
});