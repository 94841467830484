define('desktop-frontend/components/shared-link-wizard/details/component', ['exports', 'desktop-frontend/config/environment', 'desktop-frontend/mixins/editable-link-data', 'desktop-frontend/utils/link-helper', 'desktop-frontend/content-creation/service', 'desktop-frontend/mixins/mention-limit', 'ember-cp-validations'], function (exports, _environment, _editableLinkData, _linkHelper, _service, _mentionLimit, _emberCpValidations) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var gt = Ember.computed.gt;
  var alias = Ember.computed.alias;
  var readOnly = Ember.computed.readOnly;
  var service = Ember.inject.service;
  var run = Ember.run;
  var set = Ember.set;
  var get = Ember.get;
  var computed = Ember.computed;


  var MAX_BODY_LENGTH = get(_environment.default, 'qrcContent.maxBodyLength');
  var MAX_TAG_AMOUNT = 5;

  var SharedLinkValidations = (0, _emberCpValidations.buildValidations)({
    'editable_link_data.url': {
      messageKey: 'inlineWizardValidations.urlValidationMessage', /* ember-i18n-cp-validations */
      validators: [(0, _emberCpValidations.validator)('presence', { presence: true, ignoreBlank: true })]
    },
    'sharedLink.tags.firstObject.name': {
      messageKey: 'inlineWizardValidations.topicValidationMessage', /* ember-i18n-cp-validations */
      validators: [(0, _emberCpValidations.validator)('presence', { presence: true, ignoreBlank: true })]
    }
  });

  exports.default = Component.extend(SharedLinkValidations, _editableLinkData.default, _mentionLimit.default, {
    classNames: ['shared-link-wizard'],
    saving: false,
    sharedLink: null,
    bodyLengthLimit: MAX_BODY_LENGTH,
    maxTagAmount: MAX_TAG_AMOUNT,
    editableTextAreaClass: '.link-url',

    i18n: service(),
    store: service(),
    contentCreation: service(),

    mentionBody: readOnly('sharedLink.body'),
    pictures: alias('sharedLink.pictures'),
    showTagDisplay: gt('sharedLink.tags.length', 0),
    editable_body: readOnly('sharedLink.url'),
    editable_link_data: computed(function () {
      return {};
    }),

    init: function init() {
      this._super.apply(this, arguments);
      this.initSharedLink();
    },
    initSharedLink: function initSharedLink() {
      var sharedLink = get(this, 'store').createRecord('shared-link');
      set(this, 'sharedLink', sharedLink);
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      this.focusInput();
      this.onFocusInput = this.focusInput.bind(this);
      get(this, 'contentCreation').on(_service.CONTENT_CREATION_EVENTS.CREATE_LINK, this, this.onFocusInput);
    },
    willDestroyElement: function willDestroyElement() {
      get(this, 'contentCreation').off(_service.CONTENT_CREATION_EVENTS.CREATE_LINK, this, this.onFocusInput);
      this.onFocusInput = null;
      this._super.apply(this, arguments);
    },
    focusInput: function focusInput() {
      this.$('.link-url').focus();
    },


    linkSource: computed('sharedLink.url', function () {
      return _linkHelper.default.hostFromUrl(this.get('sharedLink.url'));
    }),

    actions: {
      checkForLinks: function checkForLinks() {
        this.checkBodyForLinks(this.get('editable_body'), true);
      },
      onSiteScraperStart: function onSiteScraperStart() {
        this.set('scraping', true);
      },
      onSiteScraperEnd: function onSiteScraperEnd() {
        this.set('scraping', false);
      },
      addTag: function addTag(tag) {
        var _this = this;

        var edTag = this.get('store').createRecord('tag', {
          name: tag.value,
          imagePath: tag.image_path,
          group_id: tag.id
        });

        get(this, 'sharedLink.tags').pushObject(edTag);

        run.next(function () {
          _this.$('.tag-search-input').trigger('focus');
        });
      },
      hidingMention: function hidingMention() {
        if (!get(this, '_manualToggle')) {
          return;
        }
        set(this, '_manualToggle', false);

        var $body = this.$('.link-body');
        var body = get(this, 'sharedLink.body');
        var idx = $body.get(0).selectionStart || body.length;

        if (body[idx - 1] === '@') {
          set(this, 'sharedLink.body', '' + body.substr(0, idx - 1) + body.substr(idx));
        }
      },
      shareLink: function shareLink() {
        var _this2 = this;

        if (!get(this, 'validations.isValid')) {
          set(this, 'showErrors', true);
          return;
        }

        set(this, 'saving', true);

        this.get('sharedLink').save().then(function (sharedLink) {
          return _this2.get('store').find('list-shared-link', sharedLink.get('id'));
        }).then(function (sharedLink) {
          _this2.sendAction('sharedLinkCreated', sharedLink);
          _this2.initSharedLink();
        }).catch(function (error) {
          /* eslint-disable no-console */
          console.error(error.stack);
          /* eslint-enable no-console */
          _this2.get('flashMessages').danger(get(_this2, 'i18n').t('newQuestionFlash.error'));
        }).finally(function () {
          set(_this2, 'saving', false);
        });
      }
    }
  });
});