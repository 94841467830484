define('desktop-frontend/components/feed-add-response/component', ['exports', 'desktop-frontend/mixins/has-images', 'desktop-frontend/mixins/mention-limit'], function (exports, _hasImages, _mentionLimit) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var service = Ember.inject.service;
  var not = Ember.computed.not;
  var gt = Ember.computed.gt;
  var and = Ember.computed.and;
  var readOnly = Ember.computed.readOnly;
  var notEmpty = Ember.computed.notEmpty;
  var or = Ember.computed.or;
  var Component = Ember.Component;
  var computed = Ember.computed;
  var get = Ember.get;
  var set = Ember.set;
  var run = Ember.run;
  var alias = computed.alias;
  exports.default = Component.extend(_hasImages.default, _mentionLimit.default, {
    classNames: ['feed-add-response'],
    listModel: null,
    fullModel: null,
    newResponse: null,
    uploading: false,

    store: service(),

    currentProfile: alias('currentSession.listProfile'),
    notUploading: not('uploading'),
    rootModel: alias('listModel'),
    pictures: alias('newResponse.pictures'),
    hasPictures: gt('pictures.length', 0),
    hasValidPicture: and('hasPictures', 'notUploading'),
    blocked: readOnly('listProfile.blocked'),
    bodyValid: notEmpty('newResponse.body'),
    formValid: or('bodyValid', 'hasValidPicture'),
    formInvalid: not('formValid'),
    mentionBody: readOnly('newResponse.body'),

    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      this.$('textarea').focus();
      this.generateNewResponse();
    },


    loadingPicture: computed(function () {
      var store = get(this, 'store');
      return store.createRecord('picture', { url: 'assets/images/spinner-star-gray.gif' });
    }),

    generateNewResponse: function generateNewResponse() {
      var store = get(this, 'store');
      set(this, 'newResponse', store.createRecord('response', {
        parent: get(this, 'listModel')
      }));
    },
    willDestroyElement: function willDestroyElement() {
      get(this, 'newResponse').unloadRecord();
      this._super.apply(this, arguments);
    },


    actions: {
      closeSelf: function closeSelf() {
        this.sendAction('close');
      },
      postComment: function postComment() {
        var _this = this;

        get(this, 'newResponse').save().then(function (response) {
          _this.sendAction('afterPostResponse', response.get('id'));
        });
        this.generateNewResponse();
      },
      toggleMention: function toggleMention() {
        var _this2 = this;

        var bodyEle = this.$('.response-body').get(0);
        var body = get(this, 'newResponse.body');
        var idx = bodyEle.selectionStart || body.length;
        var spaceNeeded = body[idx - 2] !== ' ';

        if (body[idx - 1] !== '@') {
          set(this, 'newResponse.body', '' + body.substr(0, idx) + (spaceNeeded ? ' ' : '') + '@' + body.substr(idx));
        }

        run.scheduleOnce('afterRender', function () {
          bodyEle.focus();
          bodyEle.selectionStart = bodyEle.selectionEnd = idx + (spaceNeeded ? 2 : 1);
          _this2.$('.response-body').trigger('mentions:toggle');
        });

        set(this, '_manualToggle', true);
      },
      hidingMention: function hidingMention() {
        if (!get(this, '_manualToggle')) {
          return;
        }

        set(this, '_manualToggle', false);

        var $body = this.$('.response-body');
        var body = get(this, 'newResponse.body');
        var idx = $body.get(0).selectionStart || body.length;

        if (body[idx - 1] === '@') {
          set(this, 'newResponse.body', '' + body.substr(0, idx - 1) + body.substr(idx));
        }
      },
      onUploadStart: function onUploadStart() {
        set(this, 'uploading', true);
        get(this, 'pictures').pushObject(get(this, 'loadingPicture'));
      },
      onUploadFinished: function onUploadFinished() {
        set(this, 'uploading', false);
        get(this, 'pictures').removeObject(get(this, 'loadingPicture'));
      }
    }
  });
});