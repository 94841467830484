define('desktop-frontend/components/feed-job-preferences/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var get = Ember.get;
  var set = Ember.set;
  var computed = Ember.computed;
  var service = Ember.inject.service;
  var alias = Ember.computed.alias;


  var STEPS = ['onWhen', 'onWhere', 'onIndustries', 'onThankYou'];

  exports.default = Component.extend({
    classNames: ['feed-job-preferences'],
    classNameBindings: ['onCard'],
    civilianPreference: null,
    selectOneInterestError: false,
    onCardIndex: 0,
    maxOptionsAllowed: 10,

    job_interests: alias('civilianPreference.job_interests'),
    geo_locations: alias('civilianPreference.geo_locations'),

    store: service(),
    flashMessages: service(),
    i18n: service(),

    init: function init() {
      this._super.apply(this, arguments);
      var defaultGeoLocation = get(this, 'feedItem.user_closest_duty_station');
      var geoLocationsArray = defaultGeoLocation ? [defaultGeoLocation] : [];
      var defaultJobPreferences = get(this, 'feedItem.popular_job_interests');
      var jobInterestsArray = defaultJobPreferences ? defaultJobPreferences : [];
      set(this, 'civilianPreference', get(this, 'store').createRecord('civilian-preference', {
        geo_locations: geoLocationsArray,
        job_interests: jobInterestsArray
      }));
    },
    willDestroyElement: function willDestroyElement() {
      get(this, 'civilianPreference').unloadRecord();
      this._super.apply(this, arguments);
    },


    onCard: computed('onCardIndex', function () {
      return STEPS[get(this, 'onCardIndex')];
    }),

    actions: {
      savePreference: function savePreference() {
        var _this = this;

        var geoLocationsArray = get(this, 'geo_locations').map(function (location) {
          return { latitude: location.latitude, longitude: location.longitude, formatted_address: location.label };
        });
        set(this, 'civilianPreference.geo_locations', geoLocationsArray);

        var interestsArray = get(this, 'job_interests').mapBy('label');
        if (interestsArray.length) {
          set(this, 'civilianPreference.job_interests', interestsArray);
          get(this, 'civilianPreference').save().then(function () {
            return _this.send('goToNext');
          }).catch(function () {
            return get(_this, 'flashMessages').danger(get(_this, 'i18n').t('general.errors.unknown'));
          });
        } else {
          set(this, 'selectOneInterestError', true);
        }
      },
      goToNext: function goToNext() {
        this.incrementProperty('onCardIndex');
      },
      setTimeAsNow: function setTimeAsNow() {
        set(this, 'nowSelected', true);
        set(this, 'notSureSelected', false);
        var now = new Date();
        set(this, 'civilianPreference.availabilityDateYear', now.getUTCFullYear());
        set(this, 'civilianPreference.availabilityDateMonth', now.getMonth() + 1);
      },
      setTimeAsNotSure: function setTimeAsNotSure() {
        set(this, 'nowSelected', false);
        set(this, 'notSureSelected', true);
        set(this, 'civilianPreference.notDecided', true);
      }
    }
  });
});