define('desktop-frontend/components/tag-search-input/tag-search-result/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var empty = Ember.computed.empty;
  var readOnly = Ember.computed.readOnly;
  var Component = Ember.Component;
  exports.default = Component.extend({
    classNames: ['tag-search-result'],
    classNameBindings: ['active'],
    // Tag is a 'fake' tag object
    tag: null,
    noImage: empty('tag.image'),
    noSubname: empty('tag.sub_name'),
    active: readOnly('tag.active')
  });
});