define('desktop-frontend/components/shared-contacts-modal/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var alias = Ember.computed.alias;
  exports.default = Component.extend({
    count: alias('contacts.length'),

    branchSpecialityTruncateLength: 120
  });
});