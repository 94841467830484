define('desktop-frontend/services/image-loader', ['exports', 'rp-common/services/image-loader'], function (exports, _imageLoader) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _imageLoader.default;
    }
  });
});