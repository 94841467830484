define('desktop-frontend/components/header-search/profile-result/component', ['exports', 'desktop-frontend/components/header-search/base-result/component'], function (exports, _component) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var readOnly = Ember.computed.readOnly;
  var or = Ember.computed.or;
  var get = Ember.get;
  var computed = Ember.computed;
  exports.default = _component.default.extend({
    classNames: ['header-search-profile-result'],
    hasImage: true,

    listProfileSearchResult: readOnly('listResult'),
    dutyStation: readOnly('listProfileSearchResult.duty_station'),
    dutyStationName: or('dutyStation.name', 'listProfileSearchResult.duty_station_name'),
    dutyStationState: readOnly('dutyStation.state'),
    listProfile: readOnly('listProfileSearchResult.list_profile'),
    href: readOnly('profileUrl'),

    profileImage: computed('listProfile.image', function () {
      var src = get(this, 'listProfile.image');
      if (!src || /default.avatar_feed/.test(src)) {
        return false;
      }
      return src;
    }),

    dutyStationUrl: computed('dutyStation.id', function () {
      return '/locations/' + get(this, 'dutyStation.id');
    }),
    profileUrl: computed('listProfile.slug', function () {
      return '/profiles/' + get(this, 'listProfile.slug');
    })
  });
});