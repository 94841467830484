define('desktop-frontend/components/upvote-button/component', ['exports', 'rp-common/mixins/voting'], function (exports, _voting) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  exports.default = Component.extend(_voting.default, {
    tagName: 'button',
    classNames: ['upvote-button-ui'],
    classNameBindings: ['upVoted'],
    voteableModel: null,

    click: function click() {
      this.send('vote', 'up');
    }
  });
});