define('desktop-frontend/components/header-notifications/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var gt = Ember.computed.gt;
  var Component = Ember.Component;
  var service = Ember.inject.service;
  exports.default = Component.extend({
    classNames: ['header-notifications', 'js-header-drop-down-wrapper'],
    showNote: false,
    notifications: [],
    unreadCount: 0,
    hasUnread: gt('unreadCount', 0),
    loadedOnce: false,

    headerMenu: service()
  });
});