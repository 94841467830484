define('desktop-frontend/services/career-preferences', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var EmberPromise = Ember.RSVP.Promise;
  var Service = Ember.Service;
  var service = Ember.inject.service;
  var get = Ember.get;


  var monthsForward = 6;
  var yearsBack = 1;

  exports.default = Service.extend({
    store: service(),

    userHasFreshPreferences: function userHasFreshPreferences() {
      var _this = this;

      return _emberData.default.PromiseObject.create({
        promise: this.careerPreferences().then(function (empPrefs) {
          return _this.availabilityDateIsCurrent(empPrefs);
        }).catch(function () {
          return false;
        })
      });
    },
    careerPreferences: function careerPreferences() {
      var _this2 = this;

      return new EmberPromise(function (resolve, reject) {
        var prefs = _this2.get('store').peekAll('civilian-preference');

        if (prefs && prefs.get('firstObject')) {
          resolve(prefs.get('firstObject'));
          return;
        }

        get(_this2, 'store').findAll('civilian-preference').then(function (records) {
          return resolve(records.get('firstObject'));
        }).catch(function (reason) {
          return reject('Failed to get civilian preferences: ' + reason);
        });
      });
    },


    // Date should fall between 12 months ago to 6 months from now
    availabilityDateIsCurrent: function availabilityDateIsCurrent(empPrefs) {
      var _empPrefs$getProperti = empPrefs.getProperties('availability_date_year', 'availability_date_month'),
          availability_date_year = _empPrefs$getProperti.availability_date_year,
          availability_date_month = _empPrefs$getProperti.availability_date_month;

      var start = new Date();
      var end = new Date();

      start.setFullYear(start.getFullYear() - yearsBack);
      end.setMonth(end.getMonth() + monthsForward);

      var year = parseInt(availability_date_year, 10) || 0;
      var month = parseInt(availability_date_month, 10) || 0;

      if (year === 0 || month === 0) {
        (false && !(false) && Ember.assert('Year or month should never be 0', false));

        return false;
      }

      // Javascript zero-indexes months but the API does not.
      var available = new Date(year, month - 1);

      return available >= start && available <= end;
    }
  });
});