define('desktop-frontend/serializers/session', ['exports', 'rp-common/serializers/session'], function (exports, _session) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _session.default;
    }
  });
});