define('desktop-frontend/mixins/with-qlu-sidebar', ['exports', 'desktop-frontend/components/feeds-list/component'], function (exports, _component) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Mixin = Ember.Mixin;
  var service = Ember.inject.service;
  var get = Ember.get;
  exports.default = Mixin.create({
    router: service(),

    actions: {
      askQuestionOnHome: function askQuestionOnHome() {
        var queryParams = { openWizard: _component.WIZARD_QUESTION };
        get(this, 'router').transitionTo('home', { queryParams: queryParams });
      },
      shareLinkOnHome: function shareLinkOnHome() {
        var queryParams = { openWizard: _component.WIZARD_LINK };
        get(this, 'router').transitionTo('home', { queryParams: queryParams });
      },
      postUpdateOnHome: function postUpdateOnHome() {
        var queryParams = { openWizard: _component.WIZARD_UPDATE };
        get(this, 'router').transitionTo('home', { queryParams: queryParams });
      }
    }
  });
});