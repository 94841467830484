define('desktop-frontend/components/trackable-link-preview/component', ['exports', 'rp-common/components/link-preview/component'], function (exports, _component) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var get = Ember.get;
  var computed = Ember.computed;
  exports.default = Component.extend({
    tagName: null,
    linkData: null,
    listDiscussion: null,
    listDiscussionUrl: null,
    location: null,
    position: null,

    isVideo: computed('linkData.url', function () {
      var url = get(this, 'linkData.url');
      return url && (url.match(_component.YOUTUBE_REGEX) || url.match(_component.VIMEO_REGEX));
    })
  });
});