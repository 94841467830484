define('desktop-frontend/components/network-builder/google-mail/component', ['exports', 'desktop-frontend/components/network-builder/base-link/component'], function (exports, _component) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _component.default.extend({
    oAuthKey: 'google_oauth2',
    siteName: 'Gmail',
    logoSrc: '/assets/images/social/g/plus-logo.svg'
  });
});